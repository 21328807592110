import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../models/api-response.model';
import {  AppointmentInfo,MeetingInfo,GetMeetingInfo,NewMeetingInfo,SuggestNewMeetingInfo } from '../models/coach.model';
import { Coach } from '../models/user-profile-response.model';
import { HttpCachingService } from './http-caching.service';
import { orderBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CoachService {
  baseUrl = `${environment.api.remoteServiceBaseUrl}`;
  constructor(private httpClient: HttpClient, private httpCachingService: HttpCachingService) {
    
  }

  seachForCoaches(isInCache = false): Observable<Coach[]> {
    const endPoint = '/Coach/search';
    const url = this.getUrl(endPoint);

    if (isInCache) {
      this.httpCachingService.addCachingUrl(url);
    }

    return this.httpClient.get<Coach[]>(url).pipe(map((data: any) => (data as ApiResponse).result as Coach[]));
  }

  assignCoach(coachId: string, goalId: number): Observable<any> {
    const endPoint = '/Coach/assign';
    const url = this.getUrl(endPoint);

    return this.httpClient.post(url, {coachId, goalId}).pipe(map((data: any) => (data as ApiResponse).result as any));
  } 

  getUrl(endPoint: string): string {
    return `${this.baseUrl}/${endPoint}`;
  }

  rescheduleToken(token:string): Observable<any> {
    const endPoint = 'rescheduleToken';
    const url = this.getUrl(endPoint);

    return this.httpClient.post(url,{"token":token});
  }

  getCoach() {
    const url = `${environment.api.remoteServiceBaseUrl2}/User/all`;
    return this.httpClient.get(url);
  }

  getCoachNew(pageNo:any,pageSize:any,trueColors:any,grades:any,gradeTaughts:any,subjectTaughts:any,contentAreas:any,typeOfSchools:any,programExperiences:any,availabilities:any,state:any,supports:any,degree:any,orderBy:any,orderWith:any,name:any) {
    state = (state == 'undefined')?"":state;
    state = (state == 0)?null:state;
    degree = (degree==0)?null:degree;
    const form = {"pageNo": pageNo,
    "pageSize": pageSize,
    "trueColors":trueColors,
    "supports":supports,
    "grades":grades,
    "gradeTaughts":gradeTaughts,
    "contentAreas":contentAreas,
    "subjectTaughts":subjectTaughts,
    "typeOfSchools":typeOfSchools,
    "programExperiences":programExperiences,
    "availabilities":availabilities,
    "stateId":state,
    "highestDegreeEarnedId":degree,
    "order":parseInt(orderBy),
    "orderBy":orderWith,
    "name":name
  }
    const url = `${environment.api.remoteServiceBaseUrl2}/user/coaches`;
    return this.httpClient.post(url,form);
  }

  searchCoaches(e:any){
    const url = `${environment.api.remoteServiceBaseUrl2}/User/all?name=`+e;
    return this.httpClient.get(url);
  }

  postAppointment(body: AppointmentInfo) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment`;
    return this.httpClient.post(url, body);
  }

  sendMeetingRequest(body: MeetingInfo) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/RequestMeeting`;
    return this.httpClient.post(url, body);
  }

  sendNewMeetingRequest(body: NewMeetingInfo) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/RequestMeeting`;
    return this.httpClient.post(url, body);
  }

  suggestNewTime(body: SuggestNewMeetingInfo) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/SuggestNewTime`;
    return this.httpClient.post(url, body);
  }

  getMeetingRequest() {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/RequestMeetings`;
    return this.httpClient.get(url);
  }

  getMeetingsForNotification() {
    const url = `${environment.api.remoteServiceBaseUrl2}/appointment/MeetingsForNotification`;
    return this.httpClient.get(url);
  }

  getAllAppointment(){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/all`;
    return this.httpClient.get(url);
  }
  
  getFutureAppointment(){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/all?date=`+this.getCurrentDate();
    return this.httpClient.get(url);
  }

  deleteAppointment(id:any,reason:string){
    let data = {"reason":reason};
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/`+id;
    return this.httpClient.delete(url,{body:data});
  }

  getNotification(e:any){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/status/1`;
    return this.httpClient.get(url);
  }

  getIndicator(){
    const url = `${environment.api.remoteServiceBaseUrl}/Resource/by/indicator/{indicatorId}`;
    return this.httpClient.get(url);
  }

  getCurrentDate():string{
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }

  updateAppointmentStatus(appointmentid:number,reason:string, status:boolean){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/invite/`+appointmentid+"/"+status;
    return this.httpClient.put(url,{status: status,reason:reason});
  }

  updateMeetingStatus(appointmentid:number,reason:string, status:boolean,slotId:number){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/RequestMeetingInvite/`+ appointmentid + `/` + status+`?slotId=`+slotId;
    return this.httpClient.put(url,{reason:''});
  }

  getTodayAppointment(){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/today`;
    return this.httpClient.get(url);
  }

  getFullProfile(id:any){
    const url = `${environment.api.remoteServiceBaseUrl2}/user/fullprofile/`+id;
    return this.httpClient.get(url);
  }

  getAllUser(pageNo:any,pageSize:any,role:any,search:any){
    const url = `${environment.api.remoteServiceBaseUrl2}/user/coaches-teachers?pageNo=`+pageNo+"&pageSize="+pageSize+"&role="+role+"&search="+search;
    return this.httpClient.get(url);
  }

  getActiveUser(pageNo:any,pageSize:any) {
    const url = `${environment.api.remoteServiceBaseUrl2}/user/activeusers?pageNo=`+pageNo+"&pageSize="+pageSize;
    return this.httpClient.get(url);
  }

  getAllConnection(pageNo:any,pageSize:any,name:string) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/admin/all?pageNo=`+pageNo+"&pageSize="+pageSize+"&name="+name;
    return this.httpClient.get(url);
  }

  getAllMeetings(pageNo:any,pageSize:any,name:any) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/meetingrequests?pageNo=`+pageNo+"&pageSize="+pageSize+"&name="+name;
    return this.httpClient.get(url);
  }

  getAllTwillioMeetings(pageNo:any,pageSize:any,name:any) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/twiliomeetings?pageNo=`+pageNo+"&pageSize="+pageSize+"&name="+name;
    return this.httpClient.get(url);
  }
  
  pullRequest(){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/PullMeetingsFromTwilio`;
    return this.httpClient.get(url);
  }

  deleteRequest(id:string){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/`+id;
    return this.httpClient.delete(url);
  }

  deleteMeeting(id:string,reason:string){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/RequestMeeting/`+id;
    return this.httpClient.delete(url,{body:{reason:reason}});
  }
}
