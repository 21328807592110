import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { FileCabinetService } from 'src/app/core/services/file-cabinet.service';
import { PlansService } from 'src/app/dataServices/plans.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FileCabinetUploadModalComponent } from '../../components/file-cabinet-upload-modal/file-cabinet-upload-modal.component';
import { init } from 'src/app/core/facades/session.actions';
import * as alertifyjs from 'alertifyjs';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'image-drag-n-drop',
  templateUrl: './image-drag-n-drop.component.html',
  styleUrls: ['./image-drag-n-drop.component.scss']
})
export class ImageDragNDropComponent implements OnInit {

  // REACTIVE FORM-BUILDER PROPERTIES
  // fileDropForm: UntypedFormGroup;
  virtualPathForm: UntypedFormGroup;
  // END: REACTIVE FORM-BUILDER PROPERTIES

  //CONDITIONAL VARIABLES
  isFormValid: boolean = false;
  isFormSubmissionValid: boolean = false;
  isWebUrl: boolean = false;
  isDropDownVisible: boolean = true;
  isUploadFileDisplayed: boolean = false;
  areFiles: boolean = false;
  showFormError = false;
  showModal: boolean = false;
  isFileExistModal = false;


  // NORMAL VARIABLES
  uploadedFiles: any = [];
  uploadedFiles2: any = [];
  base64Images: any = [];
  fileName: string = "";
  justinReturnedAPI: any;
  planName: string = ""
  planName2: any = ""
  bsModalRef: BsModalRef | undefined;
  filenameToDisplay: string = "";
  // files: any = [];
  files: File[] = [];

  // new variables
  Productlist: any;
  productImage: any;
  EditProductCode = '';
  Result: any;

  progressvalue = 0;


  // CONSTRUCTOR: USES FORM-BUILDER FOR REACTIVE FORMS AND PlansService FOR DATA SERVICES
  constructor(private fb: UntypedFormBuilder,
    private _planService: PlansService,
    private _fileCabinetService: FileCabinetService,
    private modalService: BsModalService,
    private httpClient: HttpClient,
    private toastr: ToastrService) {

    // FORM BUILDER: MAIN FILE-DROP FORM
    this.virtualPathForm = this.fb.group({
      level: 0,
      name: ['', Validators.required]
    });

    //FORM BUILDER: VIRTUAL-PATH FORM
    // this.fileDropForm = this.fb.group(
    //   {
    //     base64EncodedData: null,
    //     tags: this.fb.array([this.createTag()], Validators.required),
    //     shareWithPublic: [false, [Validators.required]],
    //     isCurated: [false, [Validators.required]],
    //     webUrl: "",
    //     webUrlName: "",
    //     fileType: 0,
    //     virtualPath: [this.virtualPathForm]
    //   }
    // );
    // END: FORM BUILDER ITEMS

  }
  //END: CONSTRUCTOR

  // @Input and @Output  
  @Input() allPlans: any;

  ngOnChanges(): void {
    console.log("this.allPlans: image-drag-n-drop.component", this.allPlans);
  }

  ngOnInit(): void {

  }

  // this graps api call
  protected get baseUrl(): string {
    return `${environment.api.remoteServiceBaseUrl}`;
  }

  // EVENT FIRES WHEN USER UPLOADS A FILE
  formSubmission(data: any, event: any) {
    if (event.target.files.length > 0) {
      for (let i = 0; i < this.uploadedFiles.length; i++) {
        const element = this.uploadedFiles[i];
      }
    }
    else {
    }


    // const formValue = Object.assign({}, this.fileDropForm.value, {
    //   virtualPath: [this.virtualPathForm.value],
    //   base64EncodedData: this.base64Images,
    //   fileName: this.fileName,
    //   tags: ['Best']
    // });
  }

  public fileOver(event: any) {
  }

  public fileLeave(event: any) {
  }

  /* Adds new Tag/Keyword for user to enter in UI textbox...it is a FormBuilder: FormGroup class that consists
      of multiple tags/keywords being added dynamically via ReactiveForms
   */
  createTag(): UntypedFormGroup {
    return this.fb.group({
      name: [null, Validators.required]
    });
  }

  // When user Clicks on "Add Tag"
  addTag(theForm: any) {
    this.isFormValid = false;

    // Ensures the user typed something into the tag/keyword textbox...sets From VALID = true/false
    for (let i = 0; i < theForm.value.tags.length; i++) {
      if (theForm.value.tags[i] == "" || theForm.value.tags[i] == null) {
        this.isFormValid = false;
      }
      else {
        this.isFormValid = true;
      };
    }
    // this.tags.push(this.createTag());
  }

  // Gets all Tags/Keywords for selected Plan (history/math/science...etc)
  // get tags(): UntypedFormArray {
  //   return <UntypedFormArray>this.fileDropForm.get('tags');
  // }

  clearBox() {
    this.isDropDownVisible = true;
    this.planName = "";
  }

  openFileDialog(incomingFile: any): void {
    console.log("in coming manny filies", incomingFile);
    const initialState: any = {
      list: incomingFile
    };

    this.bsModalRef = this.modalService.show(FileCabinetUploadModalComponent, { class: 'modal-lg', initialState });
  }

  // generateBlob(files: any) {

  //   if (files.length > 0) {
  //     files.forEach((data: any) => {
  //       const uploadedFile: any = files;
  //       const reader = new FileReader();

  //       reader.readAsDataURL(data);
  //       reader.onload = (e: any) => {
  //         this.base64Images?.push(e.target.result.split(',')[1]);
  //       };
  //     });
  //     console.log("base64Image", this.base64Images);
  //   }
  //   else {

  //   }
  // }

  onDrag(event: any) {
    // this.generateBlob(event.addedFiles);
    this.files.push(...event.addedFiles);
    this.setButtonsVisibility(this.files);

    if (this.files.length === 0) {
    }
    else {
      this.areFiles = true;
    }
  }

  onRemove(incomingFile: any) {
    this.files.splice(this.files.indexOf(incomingFile), 1);
    this.setButtonsVisibility(this.files);
  }

  //using in TS onDrag()
  setButtonsVisibility(files: File[]) {
    if (this.files.length === 0) {
      this.areFiles = false;
    }
    else {
      this.areFiles = true;
    }
  }

  //using in HTML
  cancelFileUploadClick() {
    this.files = [];
    this.setButtonsVisibility(this.files);    
  }

}
