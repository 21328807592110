import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
  @Input() message: string | undefined;
  @Input() title: string | undefined;
  @Input() buttonText: string | undefined;
  @Input() filenameToDisplay: string = "Kenner was here...";

  constructor(public bsModalRef: BsModalRef, public options: ModalOptions) { }

  ngOnInit(): void { }

  onClose(): void {
    this.bsModalRef.hide();
  }
}
