<div class="container w-100">
    <div class="card w-100" style="padding:20px">
        <h2>Coaching Sessions</h2>
        <div style="text-align:right">
            <button class="btn btn-success" (click)="refreshlist()">Refresh</button>
            <button class="btn btn-success" (click)="exportjsontoexcel('TwilloMeetingLog',alltwilliomeetings)" style="margin-left:10px;"><i class="fa fa-download"></i>&nbsp; Export To Excel</button>
        </div>
        <div style="margin-bottom: 10px;display:flex;width:500px;justify-content: end;">
            <input type="text" placeholder="Enter Name" class="name form-control" />
            <button class="btn btn-primary" style="background-color: #aa97d8; border-color: #aa97d8; padding: 5px 20px;margin-left: 10px;" (click)="twilliosearch()">Search</button>
            <button class="btn btn-primary" style="background-color: #d89797; border-color: #d89797; padding: 5px 20px;margin-left: 10px;" (click)="twillioreset()">Reset</button>
        </div>
        <table class="table table-bordered table-striped" style="margin-top:10px;" id="excel-table">
            <thead>
                <tr>
                    <th>Coach</th>
                    <th>Teacher</th>
                    <th>Participant 1</th>
                    <th>Participant 2</th>
                    <th width="200" style="text-align: center;">Participant 1 Duration (HH:MM)</th>                    
                    <th width="200" style="text-align: center;">Participant 2 Duration (HH:MM)</th>
                    <th width="200" style="text-align: center;">Scheduled Session (HH:MM)</th>
                    <th width="200">Meeting Date Time</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of twilliomeetings; let i = index">
                    <td>{{ item.mentorName }}</td>
                    <td>{{ item.menteeName }}</td>
                    <td>{{ item.participant1 }}</td>
                    <td>{{ item.participant2 }}</td>
                    <td style="text-align: center;display:none;">{{ convertTimeToHourandSecond((item.mentorName == item.participant1)?item.participant1DurationSec:item.participant2DurationSec) }}</td>
                    <td style="text-align: center;">{{ convertTimeToHourandSecond(item.participant1DurationSec) }}</td>                    
                    <td style="text-align: center;">{{ convertTimeToHourandSecond(item.participant2DurationSec) }}</td>
                    <td style="text-align: center;display:none;">{{ convertTimeToHourandSecond((item.menteeName == item.participant2)?item.participant2DurationSec:item.participant1DurationSec) }}</td>
                    <td style="text-align: center;">{{ toHoursAndMinutes(item.meetingRequestDuration) }}</td>
                    <td>{{ getMMDDYYYY(converttimezone(item.meetingDateTime)) + " "+getTimeZonelabel() }}</td>                    
                </tr>
            </tbody>
        </table>
        <div class="links">
            <ul>
                <li *ngFor="let no of twilliomeetingLinks" (click)="loadTwillioMeeting(no)" class="{{ (currentTwillioMeeting==no)?'active':'' }}">
                    {{no}}
                </li>
            </ul>
        </div>
    </div>
</div>