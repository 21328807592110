<div class="sticky-holder">
    <div class="sticky-container">
        <div class="sticky first" *ngIf="userTasks.length > 0">
            <h3>Title of Check List</h3>
            <ul>
                <li *ngFor="let task of userTasks">
                    <label>
                        <input type="checkbox" name="" />
                        <i class="icon"></i>
                        <span>{{task.name}}</span>
                    </label>
                </li>
            </ul>
        </div>
    </div>
</div>