
<div>
 
  
  <script>
    var modelId = document.getElementById('modelId');
  
    modelId.addEventListener('show.bs.modal', function (event) {
        // Button that triggered the modal
        let button = event.relatedTarget;
        // Extract info from data-bs-* attributes
        let recipient = button.getAttribute('data-bs-whatever');
  
      // Use above variables to manipulate the DOM
    });
  </script>
  
</div>

<!-- 'ADD PLAN' FOLDER-ICON -->
<div class="add-plan-wrapper">
  <div title="Add A Plan" class="folder-icon-section my-collections-folder-icon" id="add-folder-icon">
    <span class="add-new-plan-span" (click)="addNewPlan()" class="icon icon-icon-folder-plus"></span>
  </div>
  <div *ngIf="isVisible; else displayMode" class="folder-icon-section">
    <form style="display: flex; flex-wrap: wrap" action="">
      <div class="add-plan-form-textbox">
        <input name="planName" class="form-control" type="text" [(ngModel)]="addPlanName" />
      </div>
      <div class="add-plan-form-submit-btn">
        <button class="btn btn-primary" (click)="addPlanClick(addPlanName)">submit</button>
      </div>
    </form>
  </div>
  <ng-template #displayMode>
    <div class="add-file-text">
      Add A Plan
    </div>
  </ng-template>
</div>
<!-- END: PLAN FOLDER-ICON -->

<!-- PLANS -->



<div class="col-12"  class="file-container">
  <div class="file-folder hl-goal-1 container">
    <div *ngFor="let plan of plans" class="col-12">
      <div class="tab" (click)="tabClicked(plan)">
        <!-- <div>{{ plan.virtualFolderPath.length | json }}</div> -->
        <span *ngIf="plan.virtualPath != ''; else elseBlock" class="title">{{ plan.virtualPath }} ({{plan.virtualFolderPath.length}})</span>
        <ng-template #elseBlock>
          <span class="title">No Name ({{plan.virtualFolderPath.length}})</span>
        </ng-template>
      </div>
      <div *ngIf="plan.isCollapsed" class="files" cdkDropList [cdkDropListData]="plan" (cdkDropListDropped)="drop($event, plan)">
        <div *ngFor="let document of plan.virtualFolderPath" class="file-holder d-flex align-items-center" cdkDrag [cdkDragData]="document">
          <div class="status-icon">
            <span class="icon-icon-readit-good">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
              <span class="path5"></span>
            </span>
          </div>
          <span class="status status-goal-1"></span>
          <span class="title">{{ document.fileName }} 
            <a (click)="removeFromPrivate(document, plan)" name="" id="" role="button">Delete</a> | 
            <!-- <a (click)="removeFromPrivate(document, plan)">
            delete
          | </a> -->
          <span><a (click)="updateFromPrivate(document, plan)">Update</a></span>
          </span>
          <div class="actions ml-auto">
            <span class="icon-icon-arrows-move"></span>
            <span class="icon-ellipsis-v"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: PLANS -->
