import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { appRoutesNames } from 'src/app/app.routes.names';
import { AuthService } from './auth.service';

/* eslint-disable no-multi-spaces */
@Injectable({
  providedIn: 'root'
})
/* eslint-enable no-multi-spaces */
export class AuthGuardService implements CanActivate, CanLoad {
  constructor(private router: Router, private authService: AuthService) {}

  canLoad(): boolean {
    return this.verifyAuth();
  }

  canActivate(): boolean {
    return this.verifyAuth();
  }

  private verifyAuth(): boolean {
    console.log(this.authService.loggedIn);
    if (this.authService.loggedIn) {
      return true;
    } else {
      this.router.navigate([appRoutesNames.SPLASH], { skipLocationChange: true });
      return false;
    }

    // throw new Error('Not working');
  }
}
