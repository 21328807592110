import { Injectable } from '@angular/core';
import { ActionType } from 'src/app/model/action-type';
import { Standard } from '../models/standard.model';
import { BaseApiSerializer } from './base-api-serializer';

@Injectable({
  providedIn: 'root'
})
export class ActionTypeApiSerializer extends BaseApiSerializer<ActionType[]> {
  constructor() {
    super();
  }

  public fromJson(object: any): ActionType[] {
    return object as ActionType[];
  }

  // Convertit l'objet de type T en objet json correspondant à ce que le serveur attend
  public toJson(object: ActionType[]): any {
    return object;
  }
}
