<div class="container w-100">
    <div class="card w-100" style="padding:20px">
        <h2>District</h2>
        <div class="schoolform" *ngIf="AddModal">
            <form [formGroup]="AddForm">
                <table style="margin:auto" [style.opacity]="opacity">
                    <tr>
                        <td width="200">Name</td>
                        <td>
                            <input type="text" formControlName="name" />
                        </td>
                    </tr>
                    <tr>
                        <td width="200">Is Active?</td>
                        <td width="300">
                            <select formControlName="active">
                                <option value="">-Select-</option>
                                <option value="true">Active</option>
                                <option value="false">InActive</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="display:flex;">
                            <input type="hidden" formControlName="id" />
                            <button style="background-color: #34A8DF; color: #fff; margin-bottom: 10px;padding: 10px;width: 100%;border-radius: 0px;border: 1px solid #ccc;" (click)="onSubmit()" *ngIf="!isedit">Add New</button>
                            <button style="background-color: #34A8DF; color: #fff; margin-bottom: 10px;padding: 10px;width: 100%;border-radius: 0px;border: 1px solid #ccc;" (click)="onSubmit()" *ngIf="isedit">Edit</button>
                            <button style="background-color: #6B89D8; color: #fff; margin-bottom: 10px;padding: 10px;width: 100%;border-radius: 0px;border: 1px solid #ccc;margin-left: 10px;" (click)="onCancel()">Cancel</button>
                        </td>
                    </tr>
                </table>
            </form>
        </div>
        <div style="text-align:right">
            <button class="btn btn-success" (click)="addNew()">Add New</button>
        </div>
        <table class="table table-bordered table-striped" style="margin-top:10px;" id="school-table">
            <thead>
                <tr>
                    <th>District</th>
                    <th width="100">Is Active?</th>
                    <th width="100">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let district of district.data;let i=index">
                    <td>{{ district.name }}</td>
                    <td style="text-transform: capitalize;">{{ district.isActive }}</td>
                    <td style="display:flex;">
                        <button class="btn-edit" (click)="onEdit(district.id,district.name,district.isActive)" style="width:30px;"><i class="fa fa-edit"></i></button>
                        <button class="btn-edit" (click)="onDelete(district.id)" style="width:30px;"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
            
    </div>
</div>