import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Goal } from '../../../model/goal';
import { goalActionTypes } from '../actions/goals.actions';

export interface GoalsState extends EntityState<Goal> {
    selectedGoalId: number | null;
    loading: boolean;
    error: string | null;
}

export const adapter: EntityAdapter<Goal> = createEntityAdapter<Goal>({
    selectId: (goal: Goal) => goal.goalId,
    sortComparer: false
});

export const initialGoalState: GoalsState = adapter.getInitialState({
    selectedGoalId: null,
    loading: false,
    error: null
});

export const goalsReducer = createReducer(
    initialGoalState,

    on(goalActionTypes.getUserGoals, (state) => ({
        ...state,
        loading: true
    })),

    on(goalActionTypes.getUserGoalsError, (state, { errorMsg }) => ({
        ...state,
        loading: false,
        error: errorMsg
    })),

    on(goalActionTypes.getUserGoalsSuccess, (state, { goals }) => {
        return adapter.setAll(goals, {
            ...state,
            loading: false
        })
    }),

    on(goalActionTypes.createGoal, (state, { goal }) => {
        return adapter.addOne(goal, {
            ...state,
            loading: false,
            selelectedGoalId: goal.goalId
        })
    }),

    on(goalActionTypes.createGoalError, (state, { errorMsg }) => {
        if (state.selectedGoalId !== null) {
            return adapter.removeOne(state.selectedGoalId, {
                ...state,
                loading: false,
                error: errorMsg,
                selelectedGoalId: null
            })
        } else {
            return {
                ...state,
                loading: false,
                error: errorMsg
            };
        }
    }),

    on(goalActionTypes.createGoalSuccess, (state) => {
        return {
            ...state,
            loading: false,
        }
    }),
);

export const selectId = (state: GoalsState) => state.selectedGoalId;
export const { selectAll, selectIds, selectEntities, selectTotal } = adapter.getSelectors();