import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { RouterActionTypes } from '../actions/route.actions';

@Injectable()
export class RouterEffects {
  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActionTypes.Back),
        tap(() => {
          this.location.back();
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private location: Location) {}
}
