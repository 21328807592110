import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Todo } from './note-models/todo.model';
@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotesComponent implements OnInit {
  noteArray = [{}];
  color = "#ffffcc";
  todos!: Todo[];
  inputTodo: string = "";
  public noteTest: string = "";
  stringArray: string[] = [];
  orderArray: string[] = [];


  constructor() { }



  ngOnInit(): void {
    this.todos = [

    ]
  }

  onAddNote() {
    this.noteArray.push({});
  }

  onRemoveNote(index: number) {
    this.noteArray.splice(index, 1);
    if (this.noteArray.length == 0)
      this.onAddNote();
  }

  toggleDone(id: number) {
    this.todos.map((v, i) => {
      if (i == id) v.completed = !v.completed;

      return v;
    })
  }

  deleteTodo(id: number) {
    this.todos = this.todos.filter((v, i) => i !== id);
  }

  addTodo() {
    this.todos.push({
      content: this.inputTodo,
      completed: false
    });

    this.inputTodo = "";
  }

  setNoteTest(view: string) {
    this.noteTest = view;

  }
}
