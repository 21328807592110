import { createAction, props } from '@ngrx/store';
import { loggedUser, User } from '../../model/user';
import { ActionType } from 'src/app/model/action-type';
import { ConfirmationType } from 'src/app/model/confirmation-type';

export const setUserRoles = createAction('[App] Set User Roles', props<{ roles: Array<string> }>());

export const setUserID = createAction('[App] Set User ID', props<{ userInfo: loggedUser }>());

export const saveUserSessionError = createAction('[App] Set User ID Error', props<{ errorMsg: string }>());

export const setUserActionTypes = createAction('[App] Set User Action Types', props<{ actionTypes: ActionType[] }>());

export const logout = createAction('[App] logout');

export const setUserConfirmationTypes = createAction(
  '[App] Set User Confirmation Types',
  props<{ confirmationTypes: ConfirmationType[] }>()
);

export const UserActionTypes = {
  logout,
  setUserRoles,
  setUserID,
  setUserActionTypes,
  setUserConfirmationTypes
};
