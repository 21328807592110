<!-- 
<div *ngFor="let cool of myPlans">
  {{cool | json}}
</div> -->

<div class="file-cabinet-hero">
  <!-- MENU SECTION: TOP OF SCREEN TABBED MENU -->
  <ul class="nav nav-tabs" id="filingCabinet" role="tablist">
    <li class="nav-item tab-1" role="presentation">
      <a class="nav-link active" id="collection-tab" data-bs-toggle="tab" data-bs-target="#collection" role="tab"
        aria-controls="collection" aria-selected="true">My<br />Collection</a>
    </li>
    <li class="nav-item tab-2" role="presentation">
      <a class="nav-link" id="goals-tab" data-bs-toggle="tab" data-bs-target="#goals" role="tab" aria-controls="goals"
        aria-selected="false">Smart<br />Goals</a>
    </li>
    <li class="nav-item tab-3" role="presentation">
      <a class="nav-link" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" role="tab" aria-controls="list"
        aria-selected="false">List<br />& Notes</a>
    </li>
    <li class="nav-item tab-4" role="presentation">
      <a class="nav-link" id="trophy-tab" data-bs-toggle="tab" data-bs-target="#trophy" role="tab"
        aria-controls="trophy" aria-selected="false">Trophy<br />Case</a>
    </li>
  </ul>
  <!-- END: MENU SECTION: TOP OF SCREEN TABBED MENU -->


  <!-- FULL VIEW OF PAGE: CONTAINS A LEFT AND RIGHT SIDE OF THE SCREEN -->
  <div class="tab-content" id="filingCabinetContent">
    <div class="tab-pane fade show active tab-1" id="collection" role="tabpanel" aria-labelledby="collection-tab">...
      <div cdkDropListGroup class="row collection-tab">
        <!-- LEFT SIDE OF PAGE -->
        <div class="col-6 left-right">

          <!-- <image-drag-n-drop> -->
          <div id="file-upload-section-wrapper" class="collection-section">
            <div id="file-upload-section-inner-wrapper">
              <div class="upload-a-file-header" style="padding-top: 15px;">
                Upload A File
              </div>
              <image-drag-n-drop [allPlans]="allPlans | async " style="padding: 20px 0px 30px;display: block;"></image-drag-n-drop>
            </div>
          </div>
          <!-- END: <image-drag-n-drop> -->


          <!-- MY COMPLETE COLLECTION -->
          <div class="collection-section">
            <div class="my-complete-collection-wrapper">
             <div class="my-complete-collection-header">               
                <div class="col-11 offset-1 files-shown">
                  <div class="left files-shown-section">
                    <span id="files-shown-header">My Complete Collection's</span><br>
                    <span id="files-shown-subtext">Folders</span>
                  </div>
                  
                  <div class="right files-shown-section">
                    <storage-capacity [theStorageData]="storageData"></storage-capacity>
                  </div>
                </div>
              </div>
              
              
              <!-- <doughnut-chart>'s -->
              <div class="doughnut-chart-section">
                <div class="doughnut-wrapper">
                  <div class="" *ngIf="storageData?.chartDisplayData[0]?.value != null">
                    <doughnut-chart [mainData]="storageData" [chartType]="0" [chartID]="'chartOne2'"></doughnut-chart>
                  </div>
                  <div class="">
                    <!-- 2nd Douughnut chart = <doughnut-chart [chartType]="1" [chartID]="'chartOne3'"></doughnut-chart> -->
                    <doughnut-chart [mainData]="storageData2" [chartType]="0" [chartID]="'chartOne3'"></doughnut-chart>
                  </div>
                </div>
              </div>
              <!-- END: <doughnut-chart>'s -->

              <!-- <sort-by> -->
              <div class="col-12 my-collection-sort-wrapper">
                <div class="sorting-section">
                  <sort-by  (Curated)="dataChangeItem($event)"></sort-by>
                </div>
              </div>
              <!-- END OF <sort-by> -->

              <!-- <list-item> -->
              <div style="padding:15px;" class="col-12">
                <list-item [plans]="privateData"></list-item>
              </div>
            </div>
            <!-- END OF <list-item> -->

          </div>
          <!-- END: MY COMPLETE COLLECTION -->
        
        </div>
        <!-- END LEFT SIDE -->

        <!-- RIGHT SIDE OF SCREEN -->
        <div class="col-6 left-right">
          <div class="collection-section collection-right-side">
            <div class="files-shown-title">Files Shown</div>
            <div class="files-shown-sub-text">On My Profile</div>

            <!-- <doughnut-chart> -->
            <div class="doughnut-chart-section">
              <div class="doughnut-wrapper">
                <div class="" *ngIf="storageData?.chartDisplayData[0]?.value != null">
                  <doughnut-chart [mainData]="storageData" [chartType]="0" [chartID]="'doughnut3'"></doughnut-chart>
                </div>
                <div class="">
                  <!-- 2nd Douughnut chart = <doughnut-chart [chartType]="1" [chartID]="'chartOne3'"></doughnut-chart> -->
                  <doughnut-chart [mainData]="storageData2" [chartType]="0" [chartID]="'doughnut4'"></doughnut-chart>
                </div>
              </div>
            </div>
              <!-- END: <doughnut-chart> -->

            <!-- <sort-by> -->
            <div class="col-12 my-collection-sort-wrapper">
              <div class="sorting-section">
                <sort-by (All)="dataChangeItem2($event)"></sort-by>
              </div>
            </div>
            <!-- END: <sort-by> -->
            
            <tiles [plans]="publicPlans"></tiles>

          </div>
        </div>
        <!-- END RIGHT SIDE OF SCREEN -->
      </div>
      
      
      <hr>
      
    </div>
    <div class="tab-pane fade tab-2" id="goals" role="tabpanel" aria-labelledby="goals-tab">...</div>
    <div class="tab-pane fade tab-3" id="list" role="tabpanel" aria-labelledby="list-tab">
      <notes></notes>
    </div>
    <div class="tab-pane fade tab-4" id="trophy" role="tabpanel" aria-labelledby="trophy-tab">...</div>
    <div class="tab-pane fade show active tab-5" id="user" role="tabpanel" aria-labelledby="user-tab">
      <ng-content></ng-content>
      <div class="card">
        <div class="card-body">
          <h2 style="color:#025b9d;font-size:1.5em;font-weight: bold;">Files</h2>
          <table datatable class="datatable table table-bordered table-striped" id="filedata">
            <thead>
              <tr>
                <td style="background-color: #0360A1;color:#fff;">File Name</td>
                <td style="background-color: #0360A1;color:#fff;">File Image</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of imagefiledata;let i = index" class="hide">
                <td>{{ item.fileName }}</td>
                <td><img src="{{ item.path }}" height="150" /></td>
              </tr>
            </tbody>
            <tfoot *ngIf="fileData.length==0">
              <tr>
                <td colspan="2" align="center">No Records</td>
              </tr>
            </tfoot>
          </table>
          <div class="d-flex justify-content-center" *ngIf="fileData.length>0">
            <button class="btn btn-primary" id="prev" style="margin-right: 10px;" (click)="prev()">Previous</button>
	          <button class="btn btn-primary" id="next" (click)="next()">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: FULL VIEW OF PAGE -->
</div>