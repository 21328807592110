import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-meeting-invite',
  templateUrl: './meeting-invite.component.html',
  styleUrls: ['./meeting-invite.component.scss']
})
export class MeetingInviteComponent implements OnInit {
  baseUrl = `${environment.api.remoteServiceBaseUrl}`;
  isReject = false;
  objhttp: any;
  token:string="";

  constructor(public http: HttpClient, private toastr: ToastrService,private route: ActivatedRoute) { 
    this.objhttp = http;
    this.route.queryParams.subscribe(params => {
      this.token = params['q'];
      if(params['reject']=='true'){
        this.isReject = true;
      }
    });
  }

  ngOnInit(): void {
    if(!this.isReject){
      this.exeRequest();
    }    
  }

  exeRequest(){
    if (window.location.pathname == "/meeting-invite") {
      let urlParams = new URLSearchParams(window.location.search);
      let q = urlParams.get('q');
      const url = this.baseUrl+`/Appointment/RequestMeetingInviteByMail`;
      
      $.ajax({
        url: url,
        type: 'POST',
        data: JSON.stringify({
          "Token": q
        }),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        async: false,
        success: function (msg) {
          var finalmsg = (msg.message!=null)?msg.message:"";
          //alert(finalmsg);
          $("#title").html(finalmsg);
          $("#title").show();
        }
      });
    } else {
      this.accept();
    }
  }

  send(){
    $("#send").prop("disabled",true);
    this.accept();
  }

  accept() {
    let urlParams = new URLSearchParams(window.location.search);
    let q = urlParams.get('q');
    const url = this.baseUrl+`/Appointment/InviteByMail`;
    
    $.ajax({
      url: url,
      type: 'POST',
      data: JSON.stringify({
        "Token": q
      }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      async: false,
      success: function (msg) {
        var finalmsg = (msg.message!=null)?msg.message:"";
        $("#title").html(finalmsg);
        this.toastr.success(finalmsg, undefined, {
          positionClass: 'toast-top-right',
          timeOut: 2000
        });
      }
    });

    return false;
  }

}
