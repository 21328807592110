<div class="collection-side">

    <div id="root">
        <header>
            <h2 id="title">Lists & Notes</h2>
            <h5 id="sub-title">Click the add button for new note</h5>
            <div>
                <input type="text" id="input" />
                <button id="add-button"  (click)="onAddNote()" class="icon" matTooltip="Add note">Add</button>
            </div>
        </header>
        <ul id="notes-board"></ul>
    </div>

    <!-- this is note switch -->
    <div [ngSwitch]="noteTest">

        <!-- default note -->
        <div *ngSwitchDefault="'default'">
        <mat-card class="example-card" *ngFor="let field of noteArray; let i = index">
            <mat-card-header>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>+</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="setNoteTest('bullet')">
                        <!-- <mat-icon>dialpad</mat-icon> -->
                        <span>Bullet</span>
                    </button>
                    <button mat-menu-item (click)="setNoteTest('todo')">
                        <!-- <mat-icon>voicemail</mat-icon> -->
                        <span>Square Checkbox</span>
                    </button>
                    <button mat-menu-item (click)="setNoteTest('order')">
                        <!-- <mat-icon>notifications_off</mat-icon> -->
                        <span>Number List</span>
                    </button>
                </mat-menu>
                <span class="example-spacer"></span>
                <span [(colorPicker)]="color" [style.background]='color' class="color-picker" [cpPosition]="'left'"></span>
                <mat-icon (click)="onRemoveNote(i)" class="icon" matTooltip="Remove note">
                    close
                </mat-icon>
            </mat-card-header>
            <mat-card-content>
                <textarea matInput placeholder="Take a note.." class="note-area" [style.background]='color'></textarea>
            </mat-card-content>
        </mat-card> 
        </div>
        
        <!-- bullet note -->
        <div *ngSwitchCase="'bullet'">
        <mat-card class="example-card" *ngFor="let field of noteArray; let i = index">
            <mat-card-header>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>+</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="setNoteTest('default')">
                        <!-- <mat-icon>dialpad</mat-icon> -->
                        <span>Note</span>
                    </button>
                    <button mat-menu-item (click)="setNoteTest('todo')">
                        <!-- <mat-icon>voicemail</mat-icon> -->
                        <span>Square Checkbox</span>
                    </button>
                    <button mat-menu-item (click)="setNoteTest('order')">
                        <!-- <mat-icon>notifications_off</mat-icon> -->
                        <span>Number List</span>
                    </button>
                </mat-menu>
                <span class="example-spacer"></span>
                <span [(colorPicker)]="color" [style.background]='color' class="color-picker" [cpPosition]="'left'"></span>
                <mat-icon (click)="onRemoveNote(i)" class="icon" matTooltip="Remove note">
                    close
                </mat-icon>
            </mat-card-header>
            <mat-card-content>
                <textarea class="note-area" [style.background]='color' appBulletTab [(stringArray)]="stringArray"></textarea>
            </mat-card-content>
        </mat-card> 
        </div>

        <!-- order note -->
        <div *ngSwitchCase="'order'">
        <mat-card class="example-card" *ngFor="let field of noteArray; let i = index">
            <mat-card-header>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>+</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="setNoteTest('bullet')">
                        <!-- <mat-icon>dialpad</mat-icon> -->
                        <span>Bullet</span>
                    </button>
                    <button mat-menu-item (click)="setNoteTest('todo')">
                        <!-- <mat-icon>voicemail</mat-icon> -->
                        <span>Square Checkbox</span>
                    </button>
                    <button mat-menu-item (click)="setNoteTest('default')">
                        <!-- <mat-icon>notifications_off</mat-icon> -->
                        <span>Note</span>
                    </button>
                </mat-menu>
                <span class="example-spacer"></span>
                <span [(colorPicker)]="color" [style.background]='color' class="color-picker" [cpPosition]="'left'"></span>
                <mat-icon (click)="onRemoveNote(i)" class="icon" matTooltip="Remove note">
                    close
                </mat-icon>
            </mat-card-header>
            <mat-card-content>
                <textarea class="note-area" [style.background]='color' appOrderListTab [(orderArray)]="orderArray"></textarea>
            </mat-card-content>
        </mat-card> 
        </div>
        
        <!-- checklist note -->
        <div *ngSwitchCase="'todo'">
        <mat-card class="example-card" *ngFor="let field of noteArray; let i = index">
            <mat-card-header>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>+</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="setNoteTest('bullet')">
                        <!-- <mat-icon>dialpad</mat-icon> -->
                        <span>Bullet</span>
                    </button>
                    <button mat-menu-item (click)="setNoteTest('default')">
                        <!-- <mat-icon>voicemail</mat-icon> -->
                        <span>Note</span>
                    </button>
                    <button mat-menu-item (click)="setNoteTest('order')">
                        <!-- <mat-icon>notifications_off</mat-icon> -->
                        <span>number List</span>
                    </button>
                </mat-menu>
                <span class="example-spacer"></span>
                <span [(colorPicker)]="color" [style.background]='color' class="color-picker" [cpPosition]="'left'"></span>
                <mat-icon (click)="onRemoveNote(i)" class="icon" matTooltip="Remove note">
                    close
                </mat-icon>
            </mat-card-header>
            <mat-card-content>
                <div class="note-area" [style.background]='color'>
                    <form (submit)="addTodo()">
                        <input type="text" name="inputTodo" placeholder="Enter todo..." class="todo-input" [(ngModel)]="inputTodo" />
                        <input type="submit" value="Add Todo" class="todo-submit" />
                    </form>
                    <div *ngFor="let todo of todos; let i = index;" class="todo {{ (todo.completed ? 'done' : '') }}">
                        <!-- <div class="id">{{ i }}</div> -->
                        <div class="content" (click)="toggleDone(i)">{{ todo.content }}</div>
                        <button class="delete" (click)="deleteTodo(i)">X</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>   
        </div>  
    </div> 
</div>




