import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Availabilities, ContactMethod, ContentArea, DegreeType, Grade, Gradeleveltaughts, Programsexperiences, Pronoun, ResearchTopic, School, Subjectstaughts, TimeZone, TypeOfSchool,HighestDegreeEarned } from '../models/user-profile-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserAttributeService {
  constructor(private httpClient: HttpClient) {}
  getContactMethods(): Observable<ContactMethod[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/contactmethods`;
    return this.httpClient.get<ContactMethod[]>(url);
  }

  getPronounTypes(): Observable<Pronoun[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/pronountypes`;
    return this.httpClient.get<Pronoun[]>(url);
  }
  getResearchTopics(): Observable<ResearchTopic[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/researchtopics`;
    return this.httpClient.get<ResearchTopic[]>(url);
  }
  getDegreeTypes(): Observable<DegreeType[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/degreetypes`;
    return this.httpClient.get<DegreeType[]>(url);
  }
  getContentAreas(): Observable<ContentArea[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/contentareas`;
    return this.httpClient.get<ContentArea[]>(url);
  }
  getAvailavilities(): Observable<Availabilities[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/availabilities`;
    return this.httpClient.get<Availabilities[]>(url);
  }
  getGrades(): Observable<Grade[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/grades`;
    return this.httpClient.get<Grade[]>(url);
  }
  getSubjectstaughts(): Observable<Subjectstaughts[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/subjectstaughts`;
    return this.httpClient.get<Subjectstaughts[]>(url);
  }
  getHighestDegree(): Observable<any[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/degreetypes`;
    return this.httpClient.get<any[]>(url);
  }
  
  getGradeleveltaughts(): Observable<Gradeleveltaughts[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/gradelevelstaughts`;
    return this.httpClient.get<Gradeleveltaughts[]>(url);
  }
  getTypeOfSchool(): Observable<TypeOfSchool[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/typeofschools`;
    return this.httpClient.get<TypeOfSchool[]>(url);
  }
  getProgramExperiences(): Observable<Programsexperiences[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/programsexperiences`;
    return this.httpClient.get<Programsexperiences[]>(url);
  }
  getState(){
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/states`;
    return this.httpClient.get(url);
  }
  getSpecialization(){    
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/specializations`;
    return this.httpClient.get(url);
  }
  getSchoolByState(state:number): Observable<School[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/schoolsbystate?stateCode=`+state;
    return this.httpClient.get<School[]>(url);
  }
  getTimezone(): Observable<TimeZone[]> {
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/timezones`;
    return this.httpClient.get<TimeZone[]>(url);
  }
  getSupport(){
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/supports`;
    return this.httpClient.get(url);
  }
}
