import { NgModule } from '@angular/core';
import { AuthGuardService } from './auth/auth-guard.service';
import { AssessmentService } from './services/assessment.service';
import { AuthService } from './auth/auth.service';
import { EffectsModule } from '@ngrx/effects';
import { SessionEffects } from './effects/session.effects';
import { BadgeApiService } from './services/badge.service';

@NgModule({
  declarations: [],
  imports: [
    EffectsModule.forFeature([SessionEffects])
  ],
  providers: [AuthGuardService, AssessmentService, AuthService, BadgeApiService],
  
})
export class CoreModule {}
