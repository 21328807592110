import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Framework } from 'src/app/model/framework';
import { AssessmentActionTypes } from '../actions/assessment.actions';

export interface AssessmentState extends EntityState<Framework> {
    selectedFramework: Framework | null;
    loading: boolean;
    error: string | null;
}

export const adapter: EntityAdapter<Framework> = createEntityAdapter<Framework>({
    selectId: (framework: Framework) => framework.name,
    sortComparer: false
});

export const initialAssessmentState: AssessmentState = adapter.getInitialState({
    selectedFramework: null,
    loading: false,
    error: null
});

export const assessmentReducer = createReducer(
    initialAssessmentState,

    on(AssessmentActionTypes.getAssessments, (state,) => ({
        ...state,
        loading: true
    })),


    on(AssessmentActionTypes.getAssessmentsError, (state, { errorMsg }) => ({
        ...state,
        loading: false,
        error: errorMsg
    })),

    on(AssessmentActionTypes.getAssessmentsSuccess, (state, { frameworks }) => {
        return adapter.addMany(frameworks, {
            ...state,
            loading: false
        })
    }),

    on(AssessmentActionTypes.setSelectedFramework, (state, { selectedFramework }) => ({
        ...state,
        loading: false,
        selectedFramework
    })),
);

export const selectId = (state: AssessmentState) => state.selectedFramework;
export const { selectAll, selectIds, selectEntities, selectTotal } = adapter.getSelectors();