import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { loggedUser } from 'src/app/model/user';
import { getUser } from 'src/app/store/selectors/user.selector';
import * as SessionActions from './session.actions';

@Injectable({
  providedIn: 'root',
})
export class SessionFacade {

  constructor(private store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init(): void {
    this.store.dispatch(SessionActions.init());
  }
  clear(): void {
    this.store.dispatch(SessionActions.clear());
  }
}
