<div class="files" cdkDropList (cdkDropListDropped)="drop($event)">
  <div *ngFor="let file of currentPlan.files" style="background-color:#bbbbbb;" class="file-holder d-flex align-items-center" cdkDrag>
    <div class="status-icon">
      <span class="icon-icon-readit-good">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
        <span class="path5"></span>
      </span>
    </div>
    <span class="status status-goal-1"></span>
    <span class="title">{{ file.title }}</span>
    <div class="actions ml-auto">
      <span class="icon-icon-arrows-move"></span>
      <span class="icon-ellipsis-v"></span>
    </div>
  </div>
</div>