<div id="folder-storage" class="">   
    <div class="folder-section-2">
        <img src="../../../../assets/collection-section-images/yellow-purple-folder.png" alt="" style="width: 100%;">
    </div>
    <div id="folder-storage-wrapper" class="folder-section-2">
        <div>
            <div class="storage-percentage">
                {{percentage | percent}}
            </div>
            <div class="storage-data-section">
                Used of <span>{{maxGBValue}} </span>GB
            </div>
        </div>
    </div>
</div>