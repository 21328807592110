import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../core/services/school.service';
import { UntypedFormBuilder } from '@angular/forms';
import { AddSchool } from '../model/school';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx'

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['../allmeetings/allmeetings.component.scss']
})
export class SchoolComponent implements OnInit {

  State: Array<any> = [];
  Dist: Array<any> = [];
  TypeOfSchool:Array<any>=[];
  Schools: any = [];
  AllSchools: any = [];

  pgMeeting=1;
  perpage=10;

  // Generate Links
  schoolLinks: number[] = [];
  currentSchool: number = 1;

  SchoolForm = this.fb.group({
    id: 0,
    stateId:"",
    districtId:"",
    schoolName:"",
    city:"",
    zipcode:"",
    schoolId:"",
    typeOfSchoolId:"",
    frl:"",
    ell:"",
    sped:""
  });

  SchoolModal = false;

  opacity = 1;


  constructor(private ss: SchoolService,private fb: UntypedFormBuilder,private toastr: ToastrService) {
    this.ss.getState().subscribe((result: any) => {
      this.State = result;
      console.log(this.Schools,"All State");
    });
    this.ss.getDist().subscribe((result: any) => {
      this.Dist = result;
      console.log(this.Schools,"All Dist");
    });
    this.ss.getTypeOfSchool().subscribe((result: any) => {
      this.TypeOfSchool = result;
      console.log(this.TypeOfSchool,"All Type Of School");
    });
    this.getSchool();
   }

  ngOnInit(): void {
    
  }

  onEdit(id:number,stateId:string,districtId:string,schoolName:string,city:string,zipcode:string,schoolId:string,typeOfSchoolId:string,frl:string,ell:string,sped:string){
    this.SchoolModal = true;
    this.SchoolForm.patchValue({
      id: id,
      stateId:stateId,
      districtId:districtId,
      schoolName:schoolName,
      city:city,
      zipcode:zipcode,
      schoolId:schoolId,
      typeOfSchoolId:typeOfSchoolId,
      frl:frl,
      ell:ell,
      sped:sped
    });
  }

  addNew(){
    this.SchoolForm.patchValue({
      id: "",
      stateId:"",
      districtId:"",
      schoolName:"",
      city:"",
      zipcode:"",
      schoolId:"",
      typeOfSchoolId:"",
      frl:"",
      ell:"",
      sped:""
    });
    this.SchoolModal = true;
  }

  getSchool(){
    this.ss.getSchool(1,10).subscribe((result: any) => {
      this.Schools = result;

      this.schoolLinks = [];
      for (let i = 1; i <= Math.ceil(result.totalRecords/this.perpage); i++) {
        this.schoolLinks.push(i);
      }
    });

    this.ss.getSchool(1,10).subscribe((result: any) => {
      this.AllSchools = result;
    });
  }

  onSubmit(){
    let validate = true;
    this.opacity=0.5;

    let id = 0;
    
    if(this.SchoolForm.get('stateId')?.value==""){
      this.toastr.error(`State is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      validate = false;
    }

    if(this.SchoolForm.get('districtId')?.value==""){
      this.toastr.error(`District is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      validate = false;
    }

    if(this.SchoolForm.get('schoolName')?.value==""){
      this.toastr.error(`School Name is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      validate = false;
    }

    if(this.SchoolForm.get('city')?.value==""){
      this.toastr.error(`City is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      validate = false;
    }

    if(this.SchoolForm.get('zipcode')?.value==""){
      this.toastr.error(`Zipcode is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      validate = false;
    }

    if(this.SchoolForm.get('typeOfSchoolId')?.value==""){
      this.toastr.error(`Type Of School is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      validate = false;
    }

    if(this.SchoolForm.get('frl')?.value==""){
      this.toastr.error(`FRL is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      validate = false;
    }

    if(this.SchoolForm.get('ell')?.value==""){
      this.toastr.error(`ELL is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      validate = false;
    }

    if(this.SchoolForm.get('sped')?.value==""){
      this.toastr.error(`SPED is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      validate = false;
    }

    let recId = this.SchoolForm.get('id')?.value;

    if(validate){
      const schoolDetails: AddSchool = {
        id: (recId!="")?recId:0,
        stateId: (this.SchoolForm.get('stateId')?.value!="")?this.SchoolForm.get('stateId')?.value:0,
        districtId: (this.SchoolForm.get('districtId')?.value!="")?this.SchoolForm.get('districtId')?.value:0,
        schoolName: this.SchoolForm.get('schoolName')?.value,
        city: this.SchoolForm.get('city')?.value,
        zipcode: this.SchoolForm.get('zipcode')?.value,
        schoolId: (this.SchoolForm.get('schoolId')?.value!="")?this.SchoolForm.get('schoolId')?.value:0,
        typeOfSchoolId: (this.SchoolForm.get('typeOfSchoolId')?.value!="")?this.SchoolForm.get('typeOfSchoolId')?.value:0,
        frl: this.SchoolForm.get('frl')?.value,
        ell: this.SchoolForm.get('ell')?.value,
        sped: this.SchoolForm.get('sped')?.value
      }

      if(recId==0){
        this.ss.addSchool(schoolDetails).subscribe((res)=>{
          this.getSchool();
          setTimeout(()=>{
            window.location.reload();
          }, 1000);
          this.toastr.success(`School added successfully`, undefined, {
            positionClass: 'toast-top-right',
            timeOut: 2000
          });
          this.SchoolModal = false;
        },
        (error) => {
          this.opacity=1;
          this.toastr.error(`School not added`, undefined, {
            positionClass: 'toast-top-right',
            timeOut: 5000
          });
        });
      }else{
        this.ss.editSchool(schoolDetails).subscribe((res:any)=>{
          this.getSchool();
          setTimeout(()=>{
            window.location.reload();
          }, 1000);
          this.toastr.success(`School details updated successfully`, undefined, {
            positionClass: 'toast-top-right',
            timeOut: 2000
          });
          this.SchoolModal = false;
        },
        (error) => {
          this.opacity=1;
          this.toastr.error("School not updated", undefined, {
            positionClass: 'toast-top-right',
            timeOut: 5000
          });
        });
      }

      

      console.log(schoolDetails,"School Details");

      
    }else{
      this.opacity=1;
    }
  }

  loadSchool(current:number){
    this.currentSchool = current;
    this.ss.getSchool(current,this.perpage).subscribe((result: any) => {
      this.Schools = result;
    });
  }

  onDelete(id:number){
    this.ss.deleteSchool(id).subscribe((res:any)=>{
      this.getSchool();
      setTimeout(()=>{
        window.location.reload();
      }, 1000);
      this.toastr.success(`School deleted successfully`, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 2000
      });
      this.SchoolModal = false;
    },
    (error) => {
      this.opacity=1;
      this.toastr.error("School not deleted", undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
    });
  }

  onReset(){
    this.SchoolForm.patchValue({
      id: 0,
      stateId:"",
      districtId:"",
      schoolName:"",
      city:"",
      zipcode:"",
      schoolId:""
    });
    this.SchoolModal = false;
  }

  exportexcel(filename:string,id:string): void {
    /* table id is passed over here */   
    let element = document.getElementById(id); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, filename+".xlsx");
  }

  exportjsontoexcel(filename:string,data:any){
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, filename+".xlsx", { compression: true });
  }
}
