import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FrameWorkType } from 'src/app/model/framework-type';
import { StandardType } from 'src/app/model/standard-type';
import { Standard } from '../models/standard.model';
import { FrameworkTypeApiService } from './framework-type.service';
import { StandardsApiService } from './standards.service';

@Injectable({
    providedIn: 'root'
})
export class AssessmentService {
    constructor(private standardsService: StandardsApiService, private frameworkTypeApiService: FrameworkTypeApiService) { }

    public getFrameworks(): Observable<FrameWorkType[]> {
        return this.frameworkTypeApiService.getAll();
    }

    public getAssessment(path: string): Observable<Standard[]> {
        return this.standardsService.getWithPath(path);
    }
}