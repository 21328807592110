import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PlansService } from 'src/app/dataServices/plans.service';
import * as _ from 'lodash';

@Component({
  selector: 'sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss']
})
export class SortByComponent implements OnInit {

  // testing
  @Input() name = '';
  @Output() Curated = new EventEmitter<any>();
  @Output() All = new EventEmitter<any>();
  checking = false;




  // my-filling variables
  @Input() plans: any;
  @Input() curated: any;
  @Input() lastest: any;

  plans2: any = []
  curated2: any = [];
  lasted2: any = [];
  sortFilterBy2: any = [];
  dataForfiling: any = [];
  sort1: any;
  resource: any;
  sortButton: any



  // CHART DATA - POSSIBLE INFO NEEDED FROM DATABASE
  allColors: any = [];
  // sortButton: any = {
  //   chartDisplayType: 0,
  //   id: "",
  //   data: [
  //     {
  //       title: "All",
  //       value: "95",
  //       color: "black",
  //       color2: "blue",
  //       displayTitle: "",
  //       css: "normal-button",
  //       background: "linear-gradient(to right,#c19007,#005da0, #6b1da5)",
  //       isActive: true,
  //       click: 'All',
  //       filterType: 'genre'
  //     },
  //     {
  //       title: "Latest",
  //       value: "4",
  //       color: "#999999",
  //       background: "#999999",
  //       displayTitle: "",
  //       css: "normal-button",
  //       isActive: false,
  //       click: 'Latest',
  //       filterType: 'genre'
  //     },
  //     {
  //       title: "Curated",
  //       value: '8',
  //       color: "#c19007",
  //       background: "#c19007",
  //       displayTitle: "",
  //       css: "gold-dot",
  //       isActive: false,
  //       click: 'Curated',
  //       filterType: 'genre'
  //     },
  //     {
  //       title: "Interests",
  //       value: '6',
  //       color: "#005da0",
  //       background: "#005da0",
  //       displayTitle: "",
  //       css: "blue-dot",
  //       isActive: false,
  //       click: 'Interests',
  //       filterType: 'genre'
  //     },
  //     {
  //       title: "Posted",
  //       value: "25",
  //       selectedColor: "#ffffff",
  //       background: "#6b1da5",
  //       displayTitle: "",
  //       css: "lightPurple-dot",
  //       isActive: false,
  //       click: 'Posted',
  //       filterType: 'genre'
  //     },
  //     {
  //       title: "All2",
  //       value: "95",
  //       color: "#444444",
  //       background: "linear-gradient(to right,#c19007,#005da0, #6b1da5)",
  //       displayTitle: "",
  //       css: "normal-button",
  //       isActive: true,
  //       click: 'All2',
  //       filterType: 'resource'
  //     },
  //     {
  //       title: "Videos",
  //       value: "25",
  //       color: "#2eabe0",
  //       background: "#2eabe0",
  //       displayTitle: "",
  //       css: "lightBlue-dot",
  //       isActive: false,
  //       click: 'Videos',
  //       filterType: 'resource'
  //     },
  //     {
  //       title: "Documents",
  //       value: "25",
  //       color: "#855cfa",
  //       background: "#855cfa",
  //       displayTitle: "",
  //       css: "purple-dot",
  //       isActive: false,
  //       click: 'Documents',
  //       filterType: 'resource'
  //     },
  //     {
  //       title: "Writtings",
  //       value: "25",
  //       color: "#ff3479",
  //       background: "#ff3479",
  //       displayTitle: "",
  //       css: "red-dot",
  //       isActive: false,
  //       click: 'Writtings',
  //       filterType: 'resource'
  //     },
  //     {
  //       title: "Templates",
  //       value: "25",
  //       color: "#a4f95a",
  //       background: "#a4f95a",
  //       displayTitle: "",
  //       css: "green-dot",
  //       isActive: false,
  //       click: 'Templates',
  //       filterType: 'resource'
  //     }
  //   ]
  // }

  allButtons: any = [
    { Name: 'All', click: 'All' },
    { Name: 'Latest', click: 'Lastest' },
    { Name: 'Curated', click: 'Curated' },
    { Name: 'Posted', click: 'Posted' }
  ]






  // sortButton2: any = []
  // sortFilterBy: any = []

  // sortButtonAll: any = []
  // sortFilterByAll: any = []

  constructor(private _planService: PlansService) { }

  ngOnInit(): void {


    this.sortButton = this._planService.getButtons();

    this.sort1 = _.take(this.sortButton.data, 5)
    this.resource = _.takeRight(this.sortButton.data, 5)

    this._planService.getAllPlans().subscribe((data: any) => {
      this.sortFilterBy2 = _.filter(data, function (o) { return o.isCurated })
    });

    this.sortButton.data.forEach((currentData: any, index: number) => {
      this.allColors.push(currentData.color);
    });

    // this._planService.getAllPlans().subscribe((data: any) => { 
    //   this.sortButton2 = data
    //   this.sortFilterBy = _.filter(this.sortButton2, function(o) {return o.isCurated})

    //     console.log("sort filter by", this.sortFilterBy)
    // });    

    // this._planService.getAllPlans().subscribe((data: any) => { 
    //   this.sortButtonAll = data
    //   this.sortFilterByAll = _.filter(this.sortButtonAll, function(o) {return o.rowId})

    //     console.log("sort filter by all", this.sortFilterByAll)
    // });    

  }

  buttonClick(buttonClickEvent: any) {
    console.log("testaroo");
    var testaroo = buttonClickEvent.path[1];
  }


  switchClick(method: any) {
    this.Curated.emit(method)

    console.log("did it work 33", method)
  }

  pushingData(dataMyfiling: any) {
    this.Curated.emit(dataMyfiling)
    this.All.emit(dataMyfiling)
    this.dataForfiling = dataMyfiling
    console.log("this data for filing", this.dataForfiling)

  }

  getButtons2() {
    // console.log('this.filterButtonsData', this.sortButton);
    return this.sortButton.data;
  }

}
