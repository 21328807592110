import { createAction, props } from '@ngrx/store';
import { Framework } from 'src/app/model/framework';

export const noAction = createAction('[Assessments] No Action');
export const getAssessments = createAction('[Assessments] Get System Assessments');
export const getAssessmentsError = createAction('[Assessments] Get System Assessments Error', props<{ errorMsg: string }>());
export const getAssessmentsSuccess = createAction('[Assessments] Get System Assessments Success', props<{ frameworks: Framework[] }>());
export const setSelectedFramework = createAction('Assessments] Set Selected Framework', props<{ selectedFramework: Framework }>());

export const AssessmentActionTypes = {
    noAction,
    getAssessments,
    getAssessmentsError,
    getAssessmentsSuccess,
    setSelectedFramework
};