import { CdkDragDrop, CdkDragEnter, CdkDragSortEvent, copyArrayItem, moveItemInArray, transferArrayItem, } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, OnInit, Input, Output, ViewChild, NgZone } from '@angular/core';
import { publishLast, map, pairwise, filter, throttleTime } from 'rxjs/operators';
import { PlansService } from 'src/app/dataServices/plans.service';
import * as _ from 'lodash';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { Subject } from 'rxjs';


@Component({
  selector: 'tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss']
})
export class TilesComponent implements OnInit, AfterViewInit {
  changes = new Subject<void>();

  @ViewChild('scroller')
  scroller!: CdkVirtualScrollViewport;

  @Input() plans: any;

  menuItems = [
    {
      "icon": '<i class="fa-solid fa-pencil"></i>',
      "name": "Edit Details",
      "function": ""
    },
    {
      "icon": '<i class="fa-solid fa-sitemap"></i>',
      "name": "Recommended",
      "function": ""
    },
    {
      "icon": '<i class="fa-solid fa-thumbs-up"></i>',
      "name": "Like",
      "function": ""
    },
    {
      "icon": '<i class="fa-solid fa-share-nodes"></i>',
      "name": "Shared",
      "function": ""
    },
    {
      "icon": '<i class="fa-solid fa-download" aria-hidden="true"></i>',
      "name": "Download",
      "function": ""
    }, {
      "icon": '<i class="fa-solid fa-trash-can"></i>',
      "name": "Delete",
      "function": "removeFromPublic(plan)"
    }
  ];

  tiles: any = [
    { name: "Box0", bgColor: "red", order: 0, isMenuVisible: false, showMenu: false, image: "https://media.istockphoto.com/photos/back-view-of-elementary-students-raising-their-arms-on-a-class-picture-id1000887536?k=20&m=1000887536&s=612x612&w=0&h=79B-yK_-GFRauGUW-Hc4LbxHT0KOzxK5PU2fEA5JT0I=" },
    { name: "Box1", bgColor: "orange", order: 1, isMenuVisible: false, showMenu: false, image: "https://media.istockphoto.com/photos/children-with-face-mask-back-at-school-after-covid19-quarantine-and-picture-id1250037717?k=20&m=1250037717&s=612x612&w=0&h=Ao870hk-56aIxmlb6iVJKQzjHNmRjS7snx2w4_rKUyw=" },
    { name: "Box2", bgColor: "yellow", order: 2, isMenuVisible: false, showMenu: false, image: "https://thumbs.dreamstime.com/b/online-education-learning-work-home-man-hand-writing-notebook-using-laptop-computer-adult-male-student-study-course-177445400.jpg" },
    { name: "Box3", bgColor: "green", order: 3, isMenuVisible: false, showMenu: false, image: "https://media.gettyimages.com/photos/covid19-a-teacher-teaches-mathematics-picture-id1225062688?s=612x612" },
    { name: "Box4", bgColor: "blue", order: 4, isMenuVisible: false, showMenu: false, image: "https://st.depositphotos.com/1144687/4045/i/600/depositphotos_40454533-stock-photo-education-concept.jpg" },
    { name: "Box5", bgColor: "indigo", order: 5, isMenuVisible: false, showMenu: false, image: "https://g.foolcdn.com/image/?url=https%3A%2F%2Fg.foolcdn.com%2Feditorial%2Fimages%2F559462%2Fgettyimages-1059510610.jpg&w=700&op=resize" },
    { name: "Box6", bgColor: "purple", order: 5, isMenuVisible: false, showMenu: false, image: "https://g.foolcdn.com/image/?url=https%3A%2F%2Fg.foolcdn.com%2Feditorial%2Fimages%2F559462%2Fgettyimages-1059510610.jpg&w=700&op=resize" }
  ];

  firstPageLabel = $localize`First page`;
  itemsPerPageLabel = $localize`Items per page:`;
  lastPageLabel = $localize`Last page`;

  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';


  tileActive: string = '';

  // PROPERTIES
  rightSideTiles: any = [];
  originalTiles: any = [];
  isFront: boolean = true;
  is3DotMenuDisplayed: boolean = false;
  showMenuIcon: boolean = false;
  isPublic: boolean = true;
  showThreeDotMenu = true;
  testDotter: boolean = false;

  constructor(private _planService: PlansService, private ngZone: NgZone) {
  }

  ngAfterViewInit(): void { }

  ngOnInit(): void { }

  // dta = data
  drop(event: CdkDragDrop<string[]>, dta: any) {
    var incomingDocument: any = event.item.data;

    var match: boolean = false;

    // IF: DRAGGING from RIGHT to RIGHT 
    if (event.previousContainer.id === event.container.id) {
      event.container.data.forEach(element => {
        if (event.item.dropContainer.data[event.previousIndex] == element) {
          match = true;
        }
      })
      if (!match) {
        copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      }
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      incomingDocument.isPublic = true
      var theMatch: boolean = false;
      for (let index = 0; index < dta.length; index++) {
        const element = dta[index]; // element = json object being passed in
        if (element == event.item.data) {
          theMatch = true;
        }
      }
      if (theMatch == false) {
        dta.splice(event.currentIndex, 0, event.item.data);
      }
    }
  }

  flipCard(box: any) {
    // box.isPublic = !box.isPublic
    box.isActive = !box.isActive;
  }

  removeFromPublic(plan: any) {
    console.log("removeFromPublic(plan)", plan);
    var index = this.plans.indexOf(plan);

    // copys original, overwrites it, and re-saves it in the same this.plans variable/properties
    this.plans = _.cloneDeep(this.plans).slice(index, this.plans.length - 1);
  }

  // Shows the Blue transparent menu for the the document-cards
  showMenu(cardIndex: number) {
    this.tiles[cardIndex].isMenuVisible = !this.tiles[cardIndex].isMenuVisible;
  }

  // When hovering INTO a tile
  menuHoverEnter(cardId: number) {
    this.is3DotMenuDisplayed = true;
    this.showMenuIcon = true;
    this.tiles[cardId].showMenu = true;
  }

  // When hovering OUT of a card
  menuHoverExit(cardId: number) {
    this.showMenuIcon = false;
    this.tiles[cardId].showMenu = false;
    this.tiles[cardId].isMenuVisible = false;
  }

  deletePublicDocument(e: any, plan: any) {
    console.log("deletePublicDocument(e, plan) : plan", plan);
    plan.isPublic = !plan.isPublic;
    console.log("deletePublicDocument is public", plan.isPublic)
  }

  tileHover(id: string) {
    alert(id);
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`Page ${page + 1} of ${amountPages}`;
  }

}