import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpCachingService } from './http-caching.service';
import { AddSchool } from 'src/app/model/school';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  baseUrl = `${environment.api.remoteServiceBaseUrl}`;
  constructor(private httpClient: HttpClient, private httpCachingService: HttpCachingService) {}

  getSchool(start:number,perpage:number) {
    const url = `${environment.api.remoteServiceBaseUrl2}/school/all?pageNo=`+start+`&pageSize=`+perpage;
    return this.httpClient.get(url);
  }

  addSchool(body: AddSchool){
    const url = `${environment.api.remoteServiceBaseUrl2}/school`;
    return this.httpClient.post(url, body);
  }
  
  editSchool(body: AddSchool){
    const url = `${environment.api.remoteServiceBaseUrl2}/school`;
    return this.httpClient.post(url, body);
  }

  deleteSchool(id:number){
    const url = `${environment.api.remoteServiceBaseUrl2}/school/`+id;
    return this.httpClient.delete(url);
  }

  getState(){
    const url = `${environment.api.remoteServiceBaseUrl2}/UserAttribute/states`;
    return this.httpClient.get(url);
  }

  getDist() {
    const url = `${environment.api.remoteServiceBaseUrl2}/UserAttribute/districts`;
    return this.httpClient.get(url);
  }

  getTypeOfSchool(){
    const url = `${environment.api.remoteServiceBaseUrl}/UserAttribute/typeofschools`;
    return this.httpClient.get(url);
  }
}
