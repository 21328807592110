<div class="container" [ngClass]="{ 'is-logged-in': authService.loggedIn }" *ngIf="this.authService.loggedIn else loginscreen">
  <app-navbar [loggedIn]="authService.loggedIn" (loginClicked)="login()" (logoutClicked)="logout()"></app-navbar>
  <app-sidebar *ngIf="authService.loggedIn" [username]="accountName"></app-sidebar>
  <div class="app-content" *ngIf="authService.loggedIn else loginscreen">
    <router-outlet></router-outlet>
    <div aria-live="polite" toastContainer></div>
  </div>
</div>
<ng-template #loginscreen>
  <app-appointment-invite *ngIf="isappointmentpage"></app-appointment-invite>
  <app-login-component *ngIf="isLogin && !isappointmentpage"></app-login-component>
  <app-reset-password *ngIf="isResetPass"></app-reset-password>
  <app-forgot-password *ngIf="isForgotPass"></app-forgot-password>
  <app-add-coach *ngIf="isaddcoach"></app-add-coach>
  <app-reschedulemeeting *ngIf="isrescheduled"></app-reschedulemeeting>
</ng-template>