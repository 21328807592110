import { Directive, HostListener, ElementRef, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appOrderListTab]'
})
export class OrderListTabDirective implements OnInit {
  @Input() orderArray: string[] = [];
  @Output() orderArrayChange = new EventEmitter();
  @HostListener("keydown.enter", ['$event']) onEnter(event: KeyboardEvent) {
    this.rawValue = this.rawValue += '\n ' + (this.countNumbers++) + '. ';
    event.preventDefault();
  }
  @HostListener("change", ['$event']) change(event: any) {
    this.orderArrayChange.emit(this.rawValue.split("\n "));
  }

  countNumbers: number = 1;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {

    let temp: string = '';
    this.orderArray.forEach(item => {
      if (temp)
        temp += "\r\n";
      temp += '. ' + item;
    });

    this.rawValue = temp;
  }

  get rawValue(): string {
    return this.elementRef.nativeElement.value;
  }
  set rawValue(value: string) {
    this.elementRef.nativeElement.value = value;
  }

}
