import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation, ViewChildren, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem, } from '@angular/cdk/drag-drop';
import { PlansService } from 'src/app/dataServices/plans.service';
import * as _ from 'lodash';



@Component({
  selector: 'app-my-filing',
  templateUrl: './my-filing.component.html',
  styleUrls: ['./my-filing.component.scss']
})
export class MyFilingComponent implements OnInit {
  name = 'Takin Duro';
  userName = 'takin';
  grade = '4th Grade Math';
  schoolName = 'North Ridge Valley High School';
  following = '25K';
  followers = '6K';
  maxFiles = 0;
  addPlanName = "";
  imagefiledata: any = {};
  newPlan: any = {
    title: "",
    value: 0,
    color: "",
    displayTitle: ""
  };

  // the plans
  plans: any = []
  myPlans: any = []
  publicPlans: any = []
  plansAndCurated: any = []
  fileData: any = []
  testPlans2: any = []
  latestData: any = []
  testingFilter: any;
  isActive: boolean = false;
  privateData: any = [];
  cool3: any = [];
  allPlans: any;
  precentageFinal: any;
  pieData: any = null;
  lastData: any;
  // kenny: any;

  perpage:number = 2;
	start:number = 0;

  // CHART DATA - POSSIBLE INFO NEEDED FROM DATABASE

  storageData: any = {
    maxNumber: 0,
    chartDisplayType: 0,
    id: "",
    chartDisplayData: [
      {
        title: "Curated",
        value: null,
        color: "#c19007",
        displayTitle: ""
      },
      {
        title: "Interests",
        value: 4.5,
        color: "#005da0",
        displayTitle: ""
      },
      {
        title: "Posted",
        value: 2.1,
        color: "#6b1da5",
        displayTitle: ""
      }
    ]
  }
  storageData2: any = {
    maxNumber: 0,
    chartDisplayType: 0,
    id: "",
    chartDisplayData: [
      {
        title: "Vidoes",
        value: 1.4,
        color: "#2eabe0",
        displayTitle: ""
      },
      {
        title: "Documents",
        value: 4.5,
        color: "#855cfa",
        displayTitle: ""
      },
      {
        title: "Writings",
        value: 2.1,
        color: "#ff3479",
        displayTitle: ""
      },
      {
        title: "Templates",
        value: 2.1,
        color: "#22d100",
        displayTitle: ""
      }
    ]
  }
  isVisible: boolean = false;
  kd: any;
  //  END OF: CHART DATA
  @ViewChild('fileInput') fileInput: any;

  protected get baseUrl(): string {
    return `${environment.api.remoteServiceBaseUrl}`;
  }

  constructor(private httpClient: HttpClient, private _planService: PlansService) { }

  ngOnChanges(changes: SimpleChanges) {

  }
  showhidebtn():void{
    if(this.start == 0){
      $("#prev").prop("disabled",true);
      $("#next").prop("disabled",false);
    }else{
      $("#prev").prop("disabled",false);
    }
    if(this.start == $("tbody tr").length-this.perpage){
      $("#next").prop("disabled",true);
      $("#prev").prop("disabled",false);
    }else{
      $("#next").prop("disabled",false);
    }
  }
  ngOnInit(): void {
    // piechat filter
    this.newData();
    this._planService.getFiles().subscribe((data: any) => {
      this.imagefiledata = data;
      console.log(this.imagefiledata,"image");
    });

    this.allPlans = this._planService.getAllPlans();

    // filters for private and public files
    this._planService.getAllPlans().subscribe((data: any) => {
      this.fileData = data;
      this.init();
      console.log("this is all documents", this.fileData)

      // private filters
      this.myPlans = _.chain(this.fileData.files)
        .groupBy("virtualPath")
        .map((value, key) => ({ virtualPath: key, virtualFolderPath: value }))
        .value()
      this.privateData = this.myPlans

      var curated = _.filter(this.fileData.files, function (o) { return o.isCurated })
      this.plansAndCurated = _.chain(curated)
        .groupBy("virtualPath")
        .map((value, key) => ({ virtualPath: key, virtualFolderPath: value }))
        .value()

      var lastData = this.fileData.files.slice(1).slice(-5)
      this.latestData = _.chain(lastData)
        .groupBy("virtualPath")
        .map((value, key) => ({ virtualPath: key, virtualFolderPath: value }))
        .value()

      // public filters

      // this.publicPlans = _.filter(this.fileData.files, function (o) { return o.isPublic })
      // console.log("public files", this.publicPlans)

      this.publicPlans = this.fileData.files
    });

  }

  subClick(word: any) {
    this.newPlan.name = word;
    this.storageData.chartDisplayData.push(this.newPlan);
  }

  handleFileUpload(): void {

  }

  openFileInput(): void {
    this.fileInput.nativeElement.click();
  }
  addTab() {
    this.isVisible = !this.isVisible

  }

  newData(): any {
    // return 2
    this._planService.getAllPlans().subscribe((data: any) => {
      var testPlans2 = data
      console.log("the length of curated", testPlans2);

      var publicPlans2 = _.filter(testPlans2.files, function (o) { return o.isCurated })
      var plansAndCurated2 = _.chain(publicPlans2)
        .groupBy("virtualPath")
        .map((value, key) => ({ virtualPath: key, virtualFolderPath: value }))
        .value()

      var kenny: number = plansAndCurated2.length
      this.pieData = kenny
    });

    setTimeout(() => {

      this.storageData.chartDisplayData[0].value = this.pieData
    }, 4000)

  }

  dataChangeItem2(tileData: any) {
    // if(cool){
    // cool=this.myPlans;

    // // console.log("this this this is the night",cool)    
    //   }
    if (tileData === 'Curated')
      tileData = this.myPlans

    if (tileData === 'All')
      tileData = this.publicPlans

    if (tileData === 'Latest')
      tileData = this.latestData

    if (tileData === 'Videos')
      tileData = this.latestData

    this.publicPlans = tileData


  }
  dataChangeItem(itemData: any) {
    // if(cool){
    // cool=this.myPlans;

    // // console.log("this this this is the night",cool)    
    //   }
    if (itemData === 'Curated') {
      console.log("twoCool3", itemData)
      itemData = this.plansAndCurated
    }


    if (itemData === 'All') {
      itemData = this.myPlans
    }


    if (itemData === 'Latest') {
      itemData = this.latestData
    }


    if (itemData === 'Videos') {
      console.log("twoCool2", itemData)
      itemData = this.latestData

    }


    if (itemData === this.plansAndCurated + this.latestData) {
      console.log("twoCool", itemData)
      itemData = this.myPlans
    }

    // if (cool === 'Curated')
    //   cool = this.myPlans

    this.privateData = itemData
    console.log("checking cool", itemData)
    console.log("checking cool two", this.privateData)

  }

  init(){
    // Init
    $("#filedata tbody tr").addClass("hide");
    for(var i=0; i<this.perpage;i++){
      $("#filedata tbody tr").eq(i).removeClass("hide");
    }
    this.showhidebtn();
  }

  prev(){
    $("#filedata tbody tr").addClass("hide");
			if(this.start>0){
				this.start -=this.perpage;
			}else{
				this.start = 0;
			}
			this.showhidebtn();
			
			for(var i=this.start;i<(this.start+this.perpage);i++){
				$("#filedata tbody tr").eq(i).removeClass("hide");
			}	
  }

  next(){
    $("#filedata tbody tr").addClass("hide");
			if(this.start<$("#filedata tbody tr").length-this.perpage){
				this.start +=this.perpage;
			}else{
				this.start = $("#filedata tbody tr").length-this.perpage;
			}
			this.showhidebtn();
			for(var i=this.start;i<=(this.start+this.perpage);i++){
				$("#filedata tbody tr").eq(i).removeClass("hide");
			}
  }

}