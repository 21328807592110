import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FrameWorkType } from 'src/app/model/framework-type';
import { FrameworkTypeApiSerializer } from '../serializers/framework-type-api.serializer';
import { BaseApiService } from './base-service.service';
import { HttpCachingService } from './http-caching.service';

@Injectable({
    providedIn: 'root'
})
export class FrameworkTypeApiService extends BaseApiService<FrameWorkType> {
    constructor(httpClient: HttpClient, apiSerializer: FrameworkTypeApiSerializer, httpCachingService: HttpCachingService) {
        super(httpClient, apiSerializer, httpCachingService, 'standards/frameworktypes');
    }
}