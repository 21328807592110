import { Injectable } from '@angular/core';
import { ActionType } from 'src/app/model/action-type';
import { ConfirmationType } from 'src/app/model/confirmation-type';
import { Standard } from '../models/standard.model';
import { BaseApiSerializer } from './base-api-serializer';

@Injectable({
  providedIn: 'root'
})
export class ConformationTypeApiSerializer extends BaseApiSerializer<ConfirmationType[]> {
  constructor() {
    super();
  }

  public fromJson(object: any): ConfirmationType[] {
    return object as ConfirmationType[];
  }

  // Convertit l'objet de type T en objet json correspondant à ce que le serveur attend
  public toJson(object: ConfirmationType[]): any {
    return object;
  }
}
