import { Injectable } from '@angular/core';
import { Standard } from '../models/standard.model';
import { BaseApiSerializer } from './base-api-serializer';

@Injectable({
    providedIn: 'root'
})
export class StandardApiSerializer extends BaseApiSerializer<Standard[]> {
    constructor() {
        super();
    }

    public fromJson(object: any): Standard[] {
        return object as Standard[];
    }

    // Convertit l'objet de type T en objet json correspondant à ce que le serveur attend
    public toJson(object: Standard[]): any {
        return object;
    }
}