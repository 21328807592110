import { createAction, props } from '@ngrx/store';

export const Back = createAction('[Router] Back');

export const Forward = createAction('[Router] Forward');

export const RouterActionTypes = {
  Back,
  Forward
};
