<div class="container" style="margin-top:100px;">
    <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-xl-10">
            <div class="row">
                <div class="col-xl-6 loginform">
                    <div align="center">
                        <img src="../../assets/logo.png" style="width:200px;height:200px;" />
                        
                        <p style="margin-top:50px;">We are Ribbit Learning</p>
                        <h2 style="margin-top:20px;">Forgot Password</h2>
                    </div>
                    <div class="form">
                        <form (ngSubmit)="onSubmit()">
                            <input id="email"  type="email" class="textbox" maxlength="100"  placeholder="Email Address" formControlName="email" />
                            
                            <p style="margin-top:20px;">
                                <button class="loginbtn" (click)="onSubmit()">Send Email</button>
                            </p>
                        </form>
                        <p style="margin-top:20px;">
                            Already registered user? <a href="/">Login</a>
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 sidebar">
                    <img src="../../assets/sidebar.png" />
                </div>
            </div>
        </div>
        
    </div>
</div>
