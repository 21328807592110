import { MsalGuard } from '@azure/msal-angular';
import { appRoutesNames } from './app.routes.names';
import { AppointmentInviteComponent } from './appointment-invite/appointment-invite.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { MeetingInviteComponent } from './meeting-invite/meeting-invite.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AddCoachComponent } from './add-coach/add-coach.component';
import { RegisteredUserComponent } from './registered-user/registered-user.component';
import { AllconnectionComponent } from './allconnection/allconnection.component';
import { AllmeetingsComponent } from './allmeetings/allmeetings.component';
import { TwilliomeetingComponent } from './twilliomeeting/twilliomeeting.component';
import { SchoolComponent } from './school/school.component';
import { ChatdemoComponent } from './chatdemo/chatdemo.component';
import { DistrictComponent } from './district/district.component';
import { ReschedulemeetingComponent } from './reschedulemeeting/reschedulemeeting.component';

type PathMatch = "full" | "prefix" | undefined;

export const appRoutes = [
  {
    path: appRoutesNames.HOME,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: appRoutesNames.TEAM,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./team/team.module').then((m) => m.TeamModule)
  },
  {
    path: appRoutesNames.USERPROFILE,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./user-profile/user-profile.module').then((m) => m.UserProfileModule)
  },
  {
    path: appRoutesNames.PLN,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./pln/pln.module').then((m) => m.PlnModule)
  },
  {
    path: appRoutesNames.FILING,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./filing/filing.module').then((m) => m.FilingModule)
  },
  {
    path: appRoutesNames.COACHES,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./coaches/coaches.module').then((m) => m.CoachesModule)
  },
  {
    path: appRoutesNames.REPORTS,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportssModule)
  },
  {
    path: appRoutesNames.SUCCESS,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./success/success.module').then((m) => m.SuccessModule)
  },
  {
    path: appRoutesNames.ERROR,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule)
  },
  {
    path: '',
    pathMatch: 'full' as PathMatch,
    //canLoad: [MsalGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: appRoutesNames.APPOINTMENTINVITE,
    component: AppointmentInviteComponent
  },
  {
    path: appRoutesNames.MEETINGINVITE,
    component: MeetingInviteComponent
  },
  {
    path: appRoutesNames.LOGIN,
    component: LoginComponentComponent
  },
  {
    path: appRoutesNames.RESETPASSWORD,
    component: ResetPasswordComponent
  },
  {
    path: appRoutesNames.FORGOTPASSWORD,
    component: ForgotPasswordComponent
  },
  {
    path: appRoutesNames.ADDCOACH,
    component: AddCoachComponent
  },
  {
    path: appRoutesNames.REGISTEREDUSER,
    component: RegisteredUserComponent
  },
  {
    path: appRoutesNames.ALLCONNECTION,
    component: AllconnectionComponent
  },
  {
    path: appRoutesNames.ALLMEETINGS,
    component: AllmeetingsComponent
  },
  {
    path: appRoutesNames.TWILLIOMEETINGS,
    component: TwilliomeetingComponent
  },
  {
    path: appRoutesNames.SCHOOLS,
    component: SchoolComponent
  },
  {
    path: appRoutesNames.DIST,
    component: DistrictComponent
  },
  {
    path: appRoutesNames.CHAT,
    component: ChatdemoComponent
  },
  {
    path: appRoutesNames.CHAT2,
    pathMatch: 'full' as PathMatch,
    component: ChatdemoComponent
  },
  {
    path: appRoutesNames.CHATDETAILS,
    component: ChatdemoComponent
  },
  {
    path: appRoutesNames.RESCHEDULEMEETING,
    component: ReschedulemeetingComponent
  },
  {
    path: appRoutesNames.SUGGESTNEWTIME,
    component: ReschedulemeetingComponent
  }
];
