import { Inject, Injectable } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { loggedUser } from 'src/app/model/user';
import { UserActionTypes } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/reducers';
import { environment } from '../../../environments/environment';
import { SessionFacade } from '../facades/session.facade';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private store: Store<AppState>,
    private session: SessionFacade,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {
    if(localStorage.getItem('only_token')!=null){
      this.loggedIn = true;
    }
  }

  updateLoggedInStatus() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: any) => {
        console.log(result,"Hllo")
        this.checkAndSetActiveAccount(result);
      });
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.loginWithPopup();
    } else {
      this.loginWithRedirect();
    }
  }

  getActiveAccount(): AccountInfo | null {
    return this.authService.instance.getAllAccounts()[0];
  }

  private checkAndSetActiveAccount(result: any) {

    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;

    if (this.loggedIn) {
      const userData = this.getActiveAccount();
      console.log(userData,"userData");

      if (userData) {
        const savedData = {
          id: userData.localAccountId,
          email: userData.username,
          name: userData.name || ''
        };
        this.store.dispatch(UserActionTypes.setUserID({ userInfo: savedData }));
      }
    } else {
      this.logout();
    }
  }

  private loginWithPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest).subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    } else {
      this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    }
  }

  private loginWithRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        redirectStartPage: `${environment.redirectUri}/dashboard`
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    localStorage.removeItem("only_token");
    this.loggedIn = false;
    this.router.navigate(['/']);
    this.session.clear();
    this.authService.logoutRedirect({
      postLogoutRedirectUri: `${environment.redirectUri}`
    });
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
