<!-- If needed......use og-rainbow-cdkDrag.txt as example on Manny's Desktop folder, paste below -->
<!-- <cdk-virtual-scroll-viewport></cdk-virtual-scroll-viewport> -->
<!-- TILES -->
<div class="row m-row">
    <div class="m-row-wrapper">
        <div offset-1 cdkDropList [cdkDropListData]="plans" (cdkDropListDropped)="drop($event, plans)"
            class="public-area">

            <!-- PLACEHOLDER tile -->
            <div class="tile placeholder-tile col-5 offset-1">
                <div class="dotted-background">
                    <div class="instructions-tile">
                        <div class="gray-text">
                            <div class="gray-icon">
                                <!-- ICON FOR DRAG-DROP -->
                                <i class="fa-solid fa-file-arrow-up"></i>
                            </div>
                            <div class="grey text">
                                <h2>Drag</h2>
                                <h4>and</h4>
                                <h2>Drop</h2>
                                <p>to reorganize or remove from public view</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TESTER TILE -->
            <div *ngFor="let plan of plans; let i = index" id="{{'tile' + i}}"
                class="tile placeholder-tile-tester col-5 offset-1">
                <div (click)="flipCard(plan)" class="dotter">
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                </div>
                <div class="tester-tile-wrapper" *ngIf="plan.isActive; else backOfTile">
                    <div class="tile-content">
                        Front Tile
                    </div>
                </div>

                <ng-template #backOfTile>
                    <div class="tester-tile-wrapper" (mouseleave)="flipCard(plan)">
                        <div class="tile-content back-tile-content">
                            <div class="back-tile-menu">
                                <div class="menu-item" *ngFor="let item of menuItems">
                                    <div class="menu-item-icon" [innerHtml]="item.icon"></div>
                                    <div class="menu-item-title" (click)="deletePublicDocument($event, plan)">
                                        {{item.name}}</div>
                                    <!-- <div class="menu-item-icon">IC</div>
                                    <div class="menu-item-title">Edit</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>

            <mat-paginator [length]="100"   [pageSize]="6" [pageSizeOptions]="[6, 10, 20]" aria-label="Select page">
        </mat-paginator>
            

            <!-- LOOP thru this.plans -->
            <!-- <div class="tile col-5 offset-1" *ngFor="let plan of plans" cdkDrag [cdkDragData]="plan">
                <div (click)="flipCard(plan)" *ngIf="plan.isActive; else backOfCard" class="front-tile">
                    <div class="tile-front-section tile-front-image">
                        <img src="../../../../assets/img/tiles/tile-front-img-1.jpg" alt="">
                    </div>
                    <div class="tile-front-section tile-front-title">
                        Name of Document that is being Shown Here Three lines
                    </div>
                    <div class="tile-front-section tile-front-user-info">
                        <app-user-info></app-user-info>
                    </div>
                </div>

                <ng-template #backOfCard>
                    <div style="border:red 2px solid;" class="tile-back">
                        <div>
                            <span (click)="flipCard(plan)" style="padding: 20px; border-radius: 50px;"
                                class="three-dot-menu">
                                <i class="fas fa-ellipsis-v icon icon-dots-three-vertical"></i>
                            </span>
                            <ul>
                                <li class="tile-menu-screen-text-wrapper tile-menu-screen-child">
                                    <i class="fa-solid fa-pencil"></i>
                                    <a class="back-of-card-item-a">Edits Details</a>
                                </li>
                                <li class="back-of-card-item">
                                    <i class="fa-solid fa-sitemap"></i>
                                    <a class="back-of-card-item-a">Recommend</a>
                                </li>
                                <li class="back-of-card-item">
                                    <i class="fa-solid fa-thumbs-up"></i>
                                    <a class="back-of-card-item-a">Liked</a>
                                </li>
                                <li class="back-of-card-item">
                                    <i class="fa-solid fa-share-nodes"></i>
                                    <a class="back-of-card-item-a">Share</a>
                                </li>
                                <li class="back-of-card-item" (click)="removeFromPublic(plan)">
                                    <i class="fa-solid fa-trash-can"></i>
                                    <a class="back-of-card-item-a">Delete</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </ng-template>

            </div> -->
        </div>
        <mat-paginator [length]="6" [pageSizeOptions]="[6, 10, 20]" aria-label="Select page">
        </mat-paginator>
    </div>
</div>
<!-- END: Tiles -->