export const appRoutesNames = {
  SPLASH: 'landing',
  HOME: 'dashboard',
  TEAM: 'workroom',
  PLN: 'the-lounge',
  FILING: 'my-cabinet',
  COACHES: 'coaches',
  REPORTS: 'reports',
  USERPROFILE: 'user-profile',
  VIDEODEMO: 'video-demo',
  SUCCESS: 'success',
  ERROR: 'error',
  APPOINTMENTINVITE: 'appointment-invite',
  MEETINGINVITE: 'meeting-invite',
  LOGIN: 'login',
  RESETPASSWORD :'resetpassword',
  FORGOTPASSWORD:'forgotpassword',
  ADDCOACH:'adduser',
  REGISTEREDUSER:'registered-user',
  ALLCONNECTION:'all-connection',
  ALLMEETINGS:'all-meetings',
  TWILLIOMEETINGS:'twillio-meetings',
  SCHOOLS:'schools',
  CHAT:'chat',
  CHAT2:'chat/',
  DIST:'dist',
  CHATDETAILS:'chatdetails/:id',
  RESCHEDULEMEETING:'reschedule-meeting',
  SUGGESTNEWTIME:'suggest-new-time',
};
