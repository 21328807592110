<div class="container appointmentpage">
    <div class="logo">
        <img src="/assets/logo.png" />
    </div>
    <div style="text-align: center;">
        <h2 style="font-size:30px;color:#1b2051;margin-top:20px;display:none" id="title"></h2>
        <div *ngIf="isReject">
            <h2>Can you please submit the reason for reject this request?</h2>
            <div class="form-group">
                <input type="hidden" id="appointmentid" />
                <textarea id="reason" class="form-control" placeholder="Reason for change"></textarea>
            </div>
            <div class="d-flex justify-content-end">
                <button type="button" id="send" class="btn btn-close1" style="color:#fff;" (click)="send()">Send</button>
            </div>
        </div>
    </div>
</div>