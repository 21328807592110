<mat-card class="demo-inline-calendar-card" style="margin:auto;" 
  *ngIf="!availabilityIntervals.includes('Weekends'); else noWeekends">
  <mat-calendar [(selected)]="selected" [minDate]="minDate" [maxDate]="maxDate" [dateFilter]="isWeekend" style="margin:auto;"></mat-calendar>
</mat-card><br>
<ng-template #noWeekends>
  <mat-card class="demo-inline-calendar-card">
    <mat-calendar [(selected)]="selected" [minDate]="minDate" [maxDate]="maxDate" style="margin:auto;"></mat-calendar>
  </mat-card>
</ng-template><br>
<p style="color:red;">To help expedite scheduling, you can select up to four dates and times. Your coach will receive these options, accept one or recommend alternate options.</p>
<div *ngIf="selected" class="additional-text">
  <div>
    <select [(ngModel)]="selectedHour" class="timedrop">
      <option *ngFor="let hour of hours" [value]="hour">{{ hour | leadingZeros }}</option>
    </select>
    :
    <select [(ngModel)]="selectedMinute" class="timedrop">
      <option *ngFor="let minute of minutes" [value]="minute">{{ minute | leadingZeros}}</option>
    </select>
    <select class="timedrop" [(ngModel)]="selectedPeriod">
      <option value="AM">AM</option>
      <option value="PM">PM</option>
    </select>
    <button class="time-slot-button" (click)="onTimeSelected()">Select Time</button>
  </div>
</div>
<!-- <div class="additional-text">
  Please note: you are not scheduling directly on your coach's calendar. You will receive an email when your coach accepts or declines your invitation.
</div> -->
<div *ngIf="selectedlength" align="center">
  <select [(ngModel)]="selectedMeetingLength" (change)="onMeetingLengthChange()" class="time-slot-button">
    <option value="0" selected disabled hidden>Select Meeting Length</option>
    <option value="15">15 mins</option>
    <option value="30">30 mins</option>
    <option value="45">45 mins</option>
  </select>

  <div style="margin-left: 20px; margin-right: 20px; margin-top: 10px;" *ngIf="isslotselected">
    <button class="time-slot-button" [attr.data-btnid]="newTimeSlots[0]" (click)="selectTimeSlot(newTimeSlots[0])"
      [ngClass]="{ 'selected-time-slot': newTimeSlots[0] === selectedTimeSlot }" style="display:block;width:100%;margin-left:0px;">{{ newTimeSlots[0] }}</button><br>
  </div>
  <br>
  

  <p *ngIf="selectedTimeSlot !== '' " style="font-weight: bold; margin-left: 60px;display: none;">Selected Time: {{ selectedTimeSlot
    }} ({{
    getUserTimezone() }})</p>
  <p *ngIf="selectedTimeSlot !== '' " style="font-weight: bold; margin-left: 60px;display: none;">Selected Date: {{
    getFormatedDate(selected) }} ({{
    getUserTimezone() }})</p>
</div>
<div *ngIf="timeslotarr.length>0" id="timeslots" style="margin-bottom: 40px;">
  <p style="font-weight: bold;text-decoration: underline;margin:10px 0px;">You have selected ({{ timeslotarr.length +'/'+ 4  }})</p>
  <div class="row">
    <div *ngFor="let items of timeslotuserdisplay;let  i = index">
      <div class="d-flex align-items-center mb-2">
        <div class="col-md-10">
          <button class="time-slot-button text-center">
            {{ getFormatedDate(items.date) }}
            {{ items.timeslot }}
            ({{ getUserTimezone() }})
          </button>
        </div>
        <div class="col-md-2">
          <div class="icon">
            <button (click)="deletetimeslot(i)">
              <i class="fa fa-close" style="font-size:20px;"></i>
            </button>
          </div>
        </div>
      </div>
    </div>  
  </div>
</div>