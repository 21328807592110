// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  redirectUri: 'https://coach.ribbitlearning.com',
  //redirectUri: 'http://localhost:6420',
  //redirectUri: 'https://testcoaches.azurewebsites.net',
  //redirectUri: 'http://localhost:6420',

  // e50d91c3-2b7a-4305-ba40-77f870a2debfgit
  // bb9196ad-5bf6-4f66-a661-cc8cc464ffad
  authClientId: 'e50d91c3-2b7a-4305-ba40-77f870a2debf',
  api: {
    cacheAge: 3600,
    // remoteServiceBaseUrl: 'https://localhost:5001',
    //remoteServiceBaseUrl: 'https://ribbit-test-api.azurewebsites.net',
    //remoteServiceBaseUrl2: 'https://ribbit-test-api.azurewebsites.net'
    remoteServiceBaseUrl: 'https://apiribbitlearningv2.azurewebsites.net',
    remoteServiceBaseUrl2: 'https://apiribbitlearningv2.azurewebsites.net'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
