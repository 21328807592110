import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ActionType } from 'src/app/model/action-type';
import { ConfirmationType } from 'src/app/model/confirmation-type';

import { loggedUser, User } from '../../model/user';
import { UserActionTypes } from '../actions/user.action';

export interface UserState extends EntityState<loggedUser> {
  currentRoles: Array<string> | null;
  user: loggedUser | null;
  actionTypes: Array<ActionType> | null;
  confirmationTypes: Array<ConfirmationType> | null;
  message?: string | null;
}

export const adapter: EntityAdapter<loggedUser> = createEntityAdapter<loggedUser>({
  selectId: (user: loggedUser) => user.id,
  sortComparer: false
});

export const initialUserState: UserState = adapter.getInitialState({
  currentRoles: null,
  user: null,
  actionTypes: null,
  confirmationTypes: null
});

export const userReducer = createReducer(
  initialUserState,

  on(UserActionTypes.setUserID, (state, { userInfo }) => {
    return adapter.upsertOne(userInfo, {
      ...state,
      user: userInfo
    });
  }),

  on(UserActionTypes.setUserActionTypes, (state, { actionTypes }) => {
    return {
      ...state,
      actionTypes: actionTypes
    };
  }),

  on(UserActionTypes.setUserConfirmationTypes, (state, { confirmationTypes }) => {
    return {
      ...state,
      confirmationTypes: confirmationTypes
    };
  }),

  on(UserActionTypes.setUserRoles, (state, { roles }) => {
    return {
      ...state,
      currentRoles: roles
    };
  })
);

export const { selectAll, selectIds, selectEntities, selectTotal } = adapter.getSelectors();
