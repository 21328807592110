import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { ActionType } from 'src/app/model/action-type';
import { ApiResponse } from '../models/api-response.model';
import { HttpCachingService } from './http-caching.service';
import { environment } from '../../../environments/environment';
import { Badges } from '../models/badges.model';

@Injectable({
  providedIn: 'root'
})
export class BadgeApiService {
  baseUrl = `${environment.api.remoteServiceBaseUrl}`;

  constructor(private httpClient: HttpClient, private httpCachingService: HttpCachingService) {}

  getRibbon(isInCache = false): Observable<Badges> {
    const endPoint = '/ActionType';
    const url = this.getUrl(endPoint);

    if (isInCache) {
      this.httpCachingService.addCachingUrl(url);
    }

    return this.httpClient.get<Badges>(url).pipe(map((data: any) => (data as ApiResponse).result as Badges));
  }

  getUrl(endPoint: string): string {
    return `${this.baseUrl}/${endPoint}`;
  }
}

