import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { PlansService } from 'src/app/dataServices/plans.service';
import { Item } from '../../../shared/models/item';
import { environment } from 'src/environments/environment';
import { MyFilingComponent } from '../../../shared/my-filing/my-filing.component';
import { forEach } from 'lodash';
import * as _ from 'lodash';


@Component({
  selector: 'list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
  constructor(private _planService: PlansService, private httpClient: HttpClient) {
    this.allDropListsIds = [];
    this.itemDrop = new EventEmitter();
  }

  @Input() item!: Item;
  @Input() parentItem!: Item;
  @Input() public set connectedDropListsIds(ids: string[]) { this.allDropListsIds = ids; };
  @Input() plans: any;
  @Input() plan: any;
  @Input() myPlans: any;
  @Input() justinData: any;

  @Output() itemDrop: EventEmitter<CdkDragDrop<Item>>;

  addPlanName = ''
  isVisible: boolean = false
  allPlans: any = []
  tempAllPlans: any = []



  protected get baseUrl(): string {
    return `${environment.api.remoteServiceBaseUrl}`;
  }

  public get connectedDropListsIds(): string[] {
    return this.allDropListsIds.filter((id) => id !== this.item.uId);
  }
  public allDropListsIds: string[];

  public get dragDisabled(): boolean {
    return !this.parentItem;
  }

  public get parentItemId(): string {
    return this.dragDisabled ? '' : this.parentItem.uId;
  }

  // ngOnChanges(): void {
  //   this.allPlans = this._planService.getAllPlans();
  // }


  ngOnInit(): void {

    // this._planService.filterAllPlans().subscribe((data:any) => { });

  }

  public onDragDrop(event: CdkDragDrop<Item, Item>): void {
    this.itemDrop.emit(event);
  }

  tabClicked(plan: any) {
    plan.isCollapsed = !plan.isCollapsed;
  }

  setCSS(plan: any) {
    if (plan.isCollapsed) {
      return { display: 'none' };
    } else {
      return { display: 'block' };
    }
  }

  getBackgroundColorCSS(plan: any) {
    return { 'background-color': plan.backgroundColor };
  }

  addNewPlan() {
    this.isVisible = !this.isVisible;
  }

  addPlanClick(word: any) {
    console.log("the word", word)
    this._planService.addPlan(word);
    this.isVisible = !this.isVisible;
  }

  drop(event: CdkDragDrop<string[]>, plan: any) {
    var allDocuments: any = []
    // this._planService.getAllPlans().subscribe((data: any) => {
    //   // console.log("dData", data)
    //   data.forEach((dta: any) => {
    //     // console.log("dta", dta)
    //     this.tempAllPlans.push(dta)
    //   });
    //   console.log("this.tempAllPlans2", this.tempAllPlans)
    // });
    console.log("this.tempAllPlans dude", this.tempAllPlans)
    // console.log("all plans", allDocuments)
    // console.log("dropped Plan", plan)
    // console.log("dropped plan: event", event)
    var hotdog: any;

    this._planService.testOut(plan)
    var match: boolean = false;

    //dragging from RIGHT to LEFT
    if (event.previousContainer.id == 'cdk-drop-list-0') {

      var dta: any = event.container.data;

      dta.virtualFolderPath.forEach((tester: any) => {
        if (event.item.data.virtualPath == tester.virtualFolderPath) {
          match = true;
        }
      })

      if (!match) {
        dta.virtualFolderPath.push(event.item.data);
      }

    } // END OF: Dragging from RIGHT to LEFT

    //dragging from LEFT to LEFT
    else {

      var theMatch: boolean = false;
      var kData: any = {};

      console.log("kData2", kData)

      // kData = event.previousContainer.data.virtualFolderPath

      console.log("kData", kData)

      // Testing if dragging FROM the left side....basically dragging within the plan, not moving from Math to Science.....
      if (event.previousContainer.id != 'cdk-drop-list-0' && event.previousContainer != event.container) {
        let eventContainerData: any = event.container.data;
        eventContainerData.virtualFolderPath.forEach(function (tst: any) {

          if (tst == event.item.data) {
            console.log("tst", tst);
            theMatch = true;
          }
        });
        if (theMatch) {
          
        }
        else {
          // this is where you should look at
          var tstVar: any = event.previousContainer.data;

          //plan.documents.push(event.item.data)
          plan.virtualFolderPath.splice(event.previousIndex, 0, event.item.data)
          tstVar.virtualFolderPath.splice(event.previousIndex, 1)
          // console.log("updated tstVar", tstVar.virtualFolderPath)


          transferArrayItem(event.container.data, plan.virtualFolderPath, event.previousIndex, event.currentIndex);
        }
        if (!theMatch) {
          moveItemInArray(plan.virtualFolderPath, event.previousIndex, event.currentIndex)
          console.log("Kenny whoooooooo 2")
        }
      }
      if (event.container === event.previousContainer) {
        moveItemInArray(plan.virtualFolderPath, event.previousIndex, event.currentIndex);
      }
    }
  }

  // This function deletes a document PERMANENTLY from the database/server. Wiggle-room to alter/fix functionality.
  removeFromPrivate(document: any, plan: any) {
    // alert('hey we are here')
    const documentToDelete: any = { rowId: document.rowId }
    this._planService.deleteFile(documentToDelete)
    console.log("rowToDelete", documentToDelete);

    this.httpClient.get(`${this.baseUrl}/FileCabinet/user/directory/active`).subscribe((data) => {
      this.allPlans = data;

    });

    var index: any
    index = plan.virtualFolderPath.indexOf(document)
    plan.virtualFolderPath.splice(index, 1)
  }

  getFiles() {
    alert("File not found")
  }

  updateFromPrivate(document: any, plan: any) {
    this._planService.testOut(plan)
  }

  model() {

  }

}