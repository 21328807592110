import { createAction, props } from '@ngrx/store';
import { Goal } from 'src/app/model/goal';

export const getUserGoals = createAction('[Goals] User Goals Requested');
export const getUserGoalsError = createAction('[Goals] User Goals Requested Error', props<{ errorMsg: string }>());
export const getUserGoalsSuccess = createAction('[Goals] User Goals Requested Success', props<{ goals: Array<Goal> }>());
export const createGoal = createAction('[Goals] Add Goal', props<{ goal: Goal }>());
export const createGoalSuccess = createAction('[Goal] Add Goal Success');
export const createGoalError = createAction('[Goal] Add Goal Error', props<{ errorMsg: string }>());

export const goalActionTypes = {
    getUserGoals,
    getUserGoalsError,
    getUserGoalsSuccess,
    createGoal,
    createGoalError,
    createGoalSuccess
};