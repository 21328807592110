<div id="button-wrapper"  *ngIf="buttonText != null; else elseBlock">
    <div [ngStyle]="{'background-color':backgroundButtonColor, 'color':getFontColor()}" (click)="changeCssClicked(null)" class="sort-by-button">
        <div class="title">
            {{ buttonText }}<br>
        </div>
    </div>
</div>

<ng-template #elseBlock>
    <div [ngStyle]="{'background':backgroundButtonColor, 'color':getFontColor()}" class="sort-by-button" (click)="changeCssClicked(sortButtonData.click)">
        <div class="dot">
            <div class="{{ sortButtonData.css }} dot"></div>
        </div>
        <div class="title">
            {{ sortButtonData.title }}
        </div>
        
        <div class="count">
            {{ buttonCount.length }}
        </div>
    </div>
    <!-- <div [ngStyle]="{'background-color':backgroundButtonColor, 'color':getFontColor()}" class="sort-by-button" (click)="filterBy(plansData)">AAAA</div> -->
</ng-template>

