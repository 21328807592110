import { Component, OnInit } from '@angular/core';
import { CoachService } from '../core/services/coach.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx'
import 'moment-timezone';

@Component({
  selector: 'app-allconnection',
  templateUrl: './allconnection.component.html',
  styleUrls: ['./allconnection.component.scss']
})
export class AllconnectionComponent implements OnInit {
  loading = true;
  pgConnection=1;
  perpage=10;
  connection: Array<any> = [];
  allconnection: Array<any> = [];
  name:string= "";

  // Generate Links
  connectionLinks: number[] = [];
  currentConnection: number = 1;

  constructor(private cs: CoachService) { }

  ngOnInit(): void {
    this.cs.getAllConnection(this.pgConnection,this.perpage,this.name).subscribe((result: any) => {
      this.connection = result.data;
      console.log(this.connection,"All Connection");
      for (let i = 1; i <= Math.ceil(result.totalRecords/this.perpage); i++) {
        this.connectionLinks.push(i);
      }
      setTimeout(()=> { 
        this.pgfix(1);
       }, 1);
    });

    this.cs.getAllConnection(this.pgConnection,20000,this.name).subscribe((result: any) => {
      this.allconnection = result.data;
    });
  }

  getMMDDYYYY(date: any) {
    let str = date.replace("T", " ");
    const dateTime = new Date(str);
    dateTime.setSeconds(0);
    const updatedDateTimeString = dateTime.toISOString().slice(0, 16).replace('T', ' ');
    return updatedDateTimeString;
  }

  loadConnection(current:number){
    this.pgfix(current);
    this.currentConnection = current;
    this.cs.getAllConnection(current,this.perpage,this.name).subscribe((result: any) => {
      this.connection = result.data;
    }); 
  }

  pgfix(current:number){
    $(".links li").each(function(index){
      if(index>current+2 || index<current-3){
        $(".links li").eq(index).attr("style","display:none");        
      }else{
        $(".links li").eq(index).removeAttr("style");
      }
    });
  }

  converttimezone(givenTime:any){
    const localTime = moment.utc(givenTime).local();
    return localTime.format('YYYY-MM-DD HH:mm');
  }

  getTimeZonelabel(){    
    var currentTimezone = moment.tz(moment.tz.guess()).format('z');
    return "("+currentTimezone+")";
  }

  exportexcel(filename:string,id:string): void {
    /* table id is passed over here */   
    let element = document.getElementById(id); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, filename+".xlsx");
  }

  exportjsontoexcel(filename:string,data:any){
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, filename+".xlsx", { compression: true });
  }

  search(){
    let name = "";
    if($(".name").val()!=""){
      this.name = $(".name").val()+"";
    }
    if(this.name!=""){
      this.ngOnInit();
    }
  }

  reset(){
    this.name = "";
    this.ngOnInit();
    $(".name").val("");
  }
}