import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup,FormBuilder } from '@angular/forms';
import { LoginService } from '../../app/login-component/login.service';
import { forgotpassword } from '../core/models/login';
import { Router } from '@angular/router';
import { appRoutesNames } from './../app.routes.names';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  constructor(private ls: LoginService,private router: Router,private fb: FormBuilder) { }

  forgotForm = this.fb.group({
    email: ['']
  })

  ngOnInit(): void {
  }

  onSubmit() {
    let email = $("#email").val();
    const forgotpassword: forgotpassword = {
      email: ""+email
    }
    
    this.ls.sendLink(forgotpassword).subscribe((result: any) => {
      console.log(result,"====");
      alert("Password reset link has been sent on your email address");
      $("#email").val("");
      setTimeout(() => {
        window.location.href="/";
      }, 2000);  //2s
      
    });
    
    return false;
  }

}
