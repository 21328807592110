import { createAction } from '@ngrx/store';

export const openLeftSideNav = createAction('[Layout] Open LeftSideNav');

export const closeLeftSideNav = createAction('[Layout] Close LeftSideNav');

export const LayoutActionTypes = {
  openLeftSideNav,
  closeLeftSideNav
};
