import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { ActionTypeApiService } from 'src/app/core/services/action-type.service';
import { ConfirmationTypeApiService } from 'src/app/core/services/confirmation-type.service';
import { ActionType } from 'src/app/model/action-type';
import { UserActionTypes } from '../actions/user.action';

@Injectable()
export class UserEffects {
  loadActionTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.setUserID),
      switchMap(() => {
        return combineLatest([this.actionTypeService.getWithPath(''), this.confirmationTypeService.getWithPath('')]);
      }),
      switchMap((value) => {
        console.log('data from service', value);
        const [actionTypeData, confirmationTypeData] = value;
        return [
          UserActionTypes.setUserActionTypes({ actionTypes: actionTypeData }),
          UserActionTypes.setUserConfirmationTypes({ confirmationTypes: confirmationTypeData })
        ];
      })
    );
  });
  constructor(
    private actions$: Actions,
    private actionTypeService: ActionTypeApiService,
    private confirmationTypeService: ConfirmationTypeApiService
  ) {}
}
