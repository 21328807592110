<div class="container chatcontainer">
    <div class="row" *ngIf="preview">
        <div class="col-md-3" style="height:460px;padding-right:10px;overflow: auto;">
            <ul class="links">
                <li *ngFor="let user of this.users;let i=index"><button (click)="initchat(user.requestUser.userNumber,i)" class="{{(this.activeUser == i)?'active':''}}"><img src="{{ user.requestUser.profilePictures[0].imageUrl }}" style="height:50px;width:50px;overflow: hidden;margin-right: 10px;border-radius: 50%;" /> <span class="username">{{ user.requestUser.firstName + " " + user.requestUser.lastName }}<span class="count" *ngIf="countmessages[i]>0">{{ (countmessages[i]>0)?countmessages[i]:"" }}</span></span></button></li>
            </ul>
        </div>
        <div class="col-md-9" *ngIf="chaton">
            <div id="chatarea">
                <div id="chatbox" style="height: 400px; overflow-y: scroll;">
                    <div id="loadingbox" *ngIf="isload">
                        <img src="../../assets/loader2.gif" />
                    </div>
                    <!-- Messages will be appended here -->
                    <div *ngFor="let m of message">
                        <div class="message {{ (this.currentusername==m.receiverNumber)?'left':'right'  }}"><a href="{{m.filePath}}" *ngIf="m.filePath!=null && m.filePath!=''" height="100"><i class="fa fa-paperclip"></i> {{ m.fileName }}</a><div class="text" *ngIf="m.message!=null && m.message!=''" style="margin-top:5px;" [innerHTML]="replacestring(m.message)"></div><div class="datetime">{{ getMMDDYYYY(m.sentOn) }}</div></div>
                    </div>
                </div>
                <div class="d-flex">
                    <input type="text" id="message" (keyup.enter)="sendmessage(receiverusername)">
                    <button (click)="fileInput1.click()" style="margin-right:10px;" title="Send File"><i class="fa fa-paperclip"></i></button>
                    <input type="file" style="display: none;" #fileInput1 (change)="handleFile($event,0,receiverusername)" />
                    <button style="margin-right:10px;display: flex;align-items: center;" title="Instant Meeting" (click)="InstantRequestMeeting()"><i class="fa fa-bolt" style="font-size:20px;"></i> Instant Meeting</button>
                    <button (click)="sendmessage(receiverusername)" title="Send Message" id="sendbtn">Send</button>
                </div>
            </div>
        </div>
        <div class="col-md-9" *ngIf="!chaton">
            <h2 class="previewmessage">{{ messagetext }}</h2>
        </div>
    </div>
    <div class="row" *ngIf="messagetext.length>0 && !preview">
        <div class="col-md-12">
            <h2 class="previewmessage">{{ messagetext }}</h2>
        </div>
    </div>
</div>
