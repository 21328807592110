import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'storage-capacity',
  templateUrl: './storage-capacity.component.html',
  styleUrls: ['./storage-capacity.component.scss']
})
export class StorageCapacityComponent implements OnInit {

  @Input() theStorageData: any;

  maxGBValue: any = 10;
  usedGBValue: any = 0;
  percentage: any;
  chartData: any = [];
  constructor() {
    this.chartData.forEach((storageItem: any, index: any) => {
      this.maxGBValue += storageItem.value;
    });
  }

  ngOnInit(): void {
    this.theStorageData.chartDisplayData.forEach((chartItem: any, index: number) => {
      this.chartData.push(chartItem);
      this.usedGBValue = this.usedGBValue + chartItem.value;
    });
    this.percentage = this.usedGBValue / this.maxGBValue
  }
}
