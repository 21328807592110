
<!-- DROP ZONE -->
<ngx-dropzone class="the-dropzone-wrapper" (change)="onDrag($event)">
  <div class="dropzone-label-preview">
    <div class="label">
      <ngx-dropzone-label *ngIf="!areFiles" class="dropzone-label-wrapper">
        <div class="label-wrapper">
          <!-- LEFT LABEL -->
          <div id="label-left" class="label-section">
            <i class="fa fa-upload file-upload-icon" aria-hidden="true"></i>
          </div>
          <!-- END OF: LEFT LABLE -->

          <!-- RIGHT LABEL -->
          <div id="label-right" class="label-section row">
            <div id="label-right-top col-4 label-section-col">
              <span class="label-bold">Drag</span> and <span class="label-bold">Drop</span>
            </div>
            <div id="label-right-bottom col-8 label-section-col">
              images, vidoes, or any file
            </div>            
          </div>
          <!-- END OF: RIGHT LABEL -->

          
        </div>
        </ngx-dropzone-label>  
    </div>
    <div class="preview">

    </div>
  </div>
	
	<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
    <ngx-dropzone-label>
      <div>
        {{ f.name }} ({{ f.type }})
      </div>
    </ngx-dropzone-label>
	</ngx-dropzone-preview>
</ngx-dropzone>

<!-- Submit + Cancel BUTTONS -->
<div class="drag-drop-form-buttons-wrapper" *ngIf="areFiles ; else newCode">
  <div class="drag-drop-form-buttton">
    <button class="btn submit-button" (click)="openFileDialog(files)">Submit</button>
  </div>
  <div class="drag-drop-form-buttton">
    <button class="btn cancel-button" (click)="cancelFileUploadClick()">Cancel</button>      
  </div>
</div>
<ng-template #newCode>
  <div class="cancel-submit-buttons"></div>
</ng-template>

<!-- Modal -->
<div *ngIf="showModal">
  <app-file-cabinet-upload-modal [rootFolders]="allPlans.RootFolders| async"  [filenameToDisplay]="filenameToDisplay"></app-file-cabinet-upload-modal>
</div>
<div *ngIf="isFileExistModal" class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <app-modal></app-modal>
</div>

