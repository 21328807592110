import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Layout } from '../../model/layout';
import { LayoutActionTypes } from '../actions/layout.action';

export interface LayoutState extends EntityState<Layout> {
  leftSidebarOpened: boolean;
}

export const adapter: EntityAdapter<Layout> = createEntityAdapter<Layout>({
  sortComparer: false
});

export const initialLayoutState: LayoutState = adapter.getInitialState({
  leftSidebarOpened: true
});

export const layoutReducer = createReducer(
  initialLayoutState,

  on(LayoutActionTypes.openLeftSideNav, (state) => {
    return {
      ...state,
      leftSidebarOpened: true
    };
  }),

  on(LayoutActionTypes.closeLeftSideNav, (state) => {
    return {
      ...state,
      leftSidebarOpened: false
    };
  })
);

export const { selectAll, selectIds, selectEntities, selectTotal } = adapter.getSelectors();
