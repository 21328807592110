import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LoginService } from '../../app/login-component/login.service';
import { login } from '../core/models/login';
import { Router } from '@angular/router';
import { appRoutesNames } from './../app.routes.names';
import { AuthService } from './../../app/core/auth/auth.service';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.scss']
})
export class LoginComponentComponent implements OnInit {

  searchData: string = '';
  opacity:number=1;

  public loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private ls: LoginService,private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    
  }

  onSubmit() {
    this.opacity = 0.5;
    let email = $("#email").val();
    let password = $("#password").val();

    if(email=="" || password==""){
      alert("Email/Password Required");
      return false;
    }
    const login: login = {
      email: ""+email,
      password: ""+password
    }
    
    this.ls.checklogin(login).subscribe((result: any) => {
      
      localStorage.setItem("access_token",result.data.token_type+" "+result.data.access_token);
      localStorage.setItem("expires",result.data.expires);
      localStorage.setItem("only_token",result.data.access_token);
      localStorage.setItem("token_type",result.data.token_type);
      localStorage.setItem("isadmin","false");
      this.authService.loggedIn = true;
      this.router.navigate([appRoutesNames.HOME]);
      this.opacity = 1;
    }, error => {
      this.opacity = 1;
      alert("Sorry! Username/Password is Wrong");
      return ;      
    });
    
    return false;
  }

  login(){

  }

}
