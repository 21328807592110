import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SortButttonItem } from 'src/app/shared/models/sort-button-item';
import { PlansService } from 'src/app/dataServices/plans.service';
import * as _ from 'lodash';

@Component({
  selector: 'sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.scss']
})
export class SortButtonComponent implements OnInit {



  // test: any =  new SortButtonItem("kenny");
  constructor(private _planService: PlansService) { }

  // @Input VARIABLES
  @Input() sortButtonData: any;
  @Input() buttonText: any;
  @Input() curated3: any;

  // @Output VARIABLES
  @Output() clickFilter = new EventEmitter<any>();

  // variables
  sortFilterBy: any = []
  sortLength: any = []
  dta: any = []
  curatedLength: any = []


  // class properties
  defaultBbackgroundClickColor = "#cccdd0"
  backgroundButtonColor: any = "#eeeff1";
  nulledButtonBackgroundColor: string = "#000000";
  fontColor: any = "#ffffff";
  isActive: boolean = false;
  data: SortButttonItem = {
    title: "kenny",
    value: "",
    color: "",
    displayTitle: "",
    css: "",
    isActive: false
  }
  allData: any = [];
  buttonCount: any = [];




  ngOnInit(): void {

    this.dta = this.sortButtonData.title;

    this._planService.getAllPlans().subscribe((data: any) => {
      this.sortLength = data

      this.curatedLength = _.filter(this.sortLength.files, function (o) { return o.isCurated })

      if (this.dta === 'All') {
        this.buttonCount = this.sortLength
      }
      if (this.dta === 'All2') {
        this.buttonCount = this.sortLength
      }

      if (this.dta === 'Curated') {
        this.buttonCount = this.curatedLength
      }

    });


    // console.log("this.allData", this.allData)
    // var dta: any = [];
    // // this._planService.getAllPlans().subscribe((data: any) => {
    // //   // console.log("data1", data);
    // //   this.sortFilterBy = _.filter(data, function (o) { return o.isCurated })
    // //   dta.push(data);
    // // });
    // console.log("dta", dta);
    // this.allData.push(dta);
    // console.log("this.allData", this.allData[0]);
    // (this.allData).forEach((el: any) => {
    //   el[0].forEach((e: any) => {
    //     console.log("e", e);
    //   });
    //   // console.log("el", el);
    // });
    // console.log("this.sortFilterBy", this.sortFilterBy);
  }

  //[ngClass] function for Button
  changeCssClicked(theData: any) {
    // IF the ALL / LATEST Button is Clicked
    this.clickFilter.emit(theData)
    console.log("this is clicked", theData)
    theData = this.sortFilterBy
    // console.log('css data',theData)
    if (theData == null) {
      theData.isActive = !theData.isActive;
      this.backgroundButtonColor = "#00ff00";
    }
    else { // ELSE: If the Button clicked contains information
      theData.isActive = !theData.isActive;
      this.isActive = theData.isActive;
      if (theData.isActive) {
        this.backgroundButtonColor = this.sortButtonData.background;
      }
      else {
        this.backgroundButtonColor = "#eeeff1";
      }
    }
  }

  getFontColor() {
    if (this.isActive) {
      return this.fontColor;
    }
    return "#000000";
  }

  emptyButtonClicked() {
    this.isActive = this.switchActiveStatus();
    if (this.isActive == false) {
      this.backgroundButtonColor = "#00ff00";
    }
    else {
      this.backgroundButtonColor = "#cccdd1"
    }
  }

  // check if isActive
  switchActiveStatus() {
    this.isActive = !this.isActive;
    return this.isActive;
  }



}
