import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlansService } from 'src/app/dataServices/plans.service';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.model';
import { FileCabinet } from '../models/file-cabinet.model';


@Injectable({
  providedIn: 'root'
})
export class FileCabinetService {

  protected get baseUrl(): string {
    return `${environment.api.remoteServiceBaseUrl}`;
  }

  constructor(private httpClient: HttpClient, private _planService: PlansService) {
    console.log("plan service", _planService.getAllPlans());
  }

  // uploadNewFile(incomingData: FileCabinet): FileCabinet {
  uploadNewFile(incomingData: FileCabinet): Observable<any> {
    console.warn("addFileAPI_URL", this.baseUrl + "/FileCabinet/file/new");
    console.warn("FOR ABOVE: Data being sent to API = ", incomingData)
    return this.httpClient.post(`${this.baseUrl}/FileCabinet/file/new`, incomingData, { responseType: 'text' });
  }
}
