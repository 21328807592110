<div class="loader" *ngIf="loading">Loading...</div>
<div class="user-form-container" *ngIf="!loading" style="min-width: 540px;">
    <div class="cols" style="margin:auto;">
        <div class="card w-100" [style.opacity]="opacity">
            <div class="content">
                <p class="form-heading">Registration</p>
                <form [formGroup]="personalInfoForm">
                    <div class="d-flex justify-content-between">
                        <div class="ff-outer-container cols" tabindex="0">
                            <div class="ff-container">
                                <label>Email</label>
                                <input type="email" formControlName="email">
                            </div>
                        </div>
                        <div class="ff-outer-container cols" tabindex="0">
                            <div class="ff-container">
                                <label>Password</label>
                                <input type="password" formControlName="password">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="ff-outer-container cols" tabindex="0">
                            <div class="ff-container">
                                <label>First Name</label>
                                <input type="text" formControlName="firstName">
                            </div>
                        </div>
                        <div class="ff-outer-container cols" tabindex="0">
                            <div class="ff-container">
                                <label>Last Name</label>
                                <input type="text" formControlName="lastName">
                            </div>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>User Role</label>
                            <select class="form-select" aria-label="User Role"
                                formControlName="userrole">
                                <option selected [ngValue]="null" disabled hidden>Please select your User Role</option>
                                <option *ngFor="let role of userRole" [ngValue]="role.id">{{role.role}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>State</label>
                            <select class="form-select" aria-label="State"
                                formControlName="State" (change)="onStateChange()">
                                <option selected [ngValue]="null" disabled hidden>Please select your state</option>
                                <option *ngFor="let state of allState" [ngValue]="state.id">{{state.stateName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>School</label>
                            <select class="form-select" aria-label="School"
                                formControlName="School">
                                <option selected [ngValue]="null" disabled hidden>Please select your school</option>
                                <option *ngFor="let school of School" [ngValue]="school.id">{{school.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>Years Experience</label>
                            <input type="text" formControlName="yearsExperience">
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>Years In Education</label>
                            <input type="text" formControlName="yearsineducation">
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>Grade<br>
                                <p style="font-size: 15px;">(<b>Ctrl+click (PC)</b> or <b>Cmd+click (Mac) </b> to select
                                    multiple options)</p>
                            </label>
                            <select class="form-select" multiple aria-label="Interest multi selection"
                                formControlName="grades">
                                <option *ngFor="let grade of grades" [ngValue]="grade">{{grade.description}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>Availability <br>
                                <p style="font-size: 15px;">(<b>Ctrl+click (PC)</b> or <b>Cmd+click (Mac) </b> to select
                                    multiple options)</p>
                            </label>
                            <select class="form-select" multiple aria-label="Interest multi selection"
                                formControlName="availabilities">
                                <option selected [ngValue]="null" disabled hidden>When would you be available?</option>
                                <option *ngFor="let availability of availibilities" [ngValue]="availability">
                                    {{availability.availabilityTime}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0" style="display: none;">
                        <div class="ff-container">
                            <label>Subject Thoughts<br>
                                <p style="font-size: 15px;">(<b>Ctrl+click (PC)</b> or <b>Cmd+click (Mac) </b> to select
                                    multiple options)</p>
                            </label>
                            <select class="form-select" multiple aria-label="Interest multi selection"
                                formControlName="SubjectsTaught">
                                <option selected [ngValue]="null" disabled hidden>Select Your Subjects Taughts?</option>
                                <option *ngFor="let subject of Subjectstaughts" [ngValue]="subject">
                                    {{subject.subjectsTaughts}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>GradeLevel Taughts<br>
                                <p style="font-size: 15px;">(<b>Ctrl+click (PC)</b> or <b>Cmd+click (Mac) </b> to select
                                    multiple options)</p>
                            </label>
                            <select class="form-select" multiple aria-label="Interest multi selection"
                                formControlName="GradeLevelsTaught">
                                <option selected [ngValue]="null" disabled hidden>Select Your GradeLevel Taughts?</option>
                                <option *ngFor="let grade of Gradeleveltaughts" [ngValue]="grade">
                                    {{grade.gradeLevelsTaughts}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>Type Of School <br>
                                <p style="font-size: 15px;">(<b>Ctrl+click (PC)</b> or <b>Cmd+click (Mac) </b> to select
                                    multiple options)</p>
                            </label>
                            <select class="form-select" multiple aria-label="Interest multi selection"
                                formControlName="TypeOfSchoolIds">
                                <option selected [ngValue]="null" disabled hidden>Select Type of School?</option>
                                <option *ngFor="let objschool of TypeOfSchool" [ngValue]="objschool.typeOfSchoolId">
                                    {{objschool.type}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0">
                        <div class="ff-container">
                            <label>Specialization<br>
                                <p style="font-size: 15px;">(<b>Ctrl+click (PC)</b> or <b>Cmd+click (Mac) </b> to select
                                    multiple options)</p>
                            </label>
                            <select class="form-select" multiple aria-label="Interest multi selection"
                                formControlName="ProgramExperienceIds">
                                <option selected [ngValue]="null" disabled hidden>When would you be available?</option>
                                <option *ngFor="let program of Programsexperiences" [ngValue]="program">
                                    {{program.experience}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ff-outer-container" tabindex="0" style="display:none;">
                        <div class="ff-container">
                            <label>Supports<br>
                                <p style="font-size: 15px;">(<b>Ctrl+click (PC)</b> or <b>Cmd+click (Mac) </b> to select
                                    multiple options)</p>
                            </label>
                            <select class="form-select" multiple aria-label="Interest multi selection"
                                formControlName="supports">
                                <option selected [ngValue]="null" disabled hidden>When would you be available?</option>
                                <option *ngFor="let objsupports of supports" [ngValue]="objsupports.supportId">
                                    {{objsupports.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <button type="button" (click)="SaveProfile()" style="margin-top: 10px;">Save Profile</button>
                </form>
            </div>
        </div>
    </div>
</div>