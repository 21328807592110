<nav style="flex-wrap: wrap;">
  <div class="banner" *ngIf="NotificationCount>0">
    <p *ngFor="let item of NotificationData; let i = index">{{ (item.appointment.inviteAccepted)?("You have an appointment with " + ((item.appointment.mentorId != userId)?item.appointment.mentorName:item.appointment.menteeName) + " at " + converttimezone(item.dateTime) + " " +getTimeZonelabel()):"" }}</p>
  </div>
  <div style="width:100%;display:flex;align-items: center;">
    <h2><a class="navbar-brand" [routerLink]="['/home']">Thriiiv</a></h2>
    <h3 *ngIf="loggedIn">Hello {{ (userProfile!=null && userProfile.firstName != null) ? (userProfile.firstName + " " + userProfile.lastName) : "" }}!</h3>
    <div class="right-nav">
      <div class="d-flex" *ngIf="loggedIn && userProfile.userRoles[0].roleId==3" style="margin-top: -10px;background-color: #fff;padding: 5px!important;border-radius: 30px;margin-right: 30px;">
        <i class="fa fa-clock" style="font-size:26px;color:#556abc;margin-right:10px;"></i>
        <h4 style="margin-bottom: 0px;margin-right:10px;">Available Coaching Minutes: {{ userProfile.availableMinutes+"" }}</h4>
      </div>
      <h4 *ngIf="loggedIn">It's {{ today }}</h4>    
      <div class="notifications-bell" *ngIf="loggedIn">
        <a href="#" (click)="showNotification()">
          <i class="icon-bell"></i> <i class="icon-bell outer"></i>
          <span class="notification-holder">
            <span class="notification-count">{{NotificationCount}}</span>
          </span>
        </a>
        <div class="notification" *ngIf="notificationshow && NotificationCount>0">        
          <div class="notification-content">
            <div class="close-btn" (click)="showNotification()"><i class="fa fa-close"></i></div>
            <p *ngFor="let item of NotificationData; let i = index">{{ (item.appointment.inviteAccepted)?("You have an appointment with " + ((item.appointment.mentorId != userId)?item.appointment.mentorName:item.appointment.menteeName) + " at " + converttimezone(item.dateTime) + " " +getTimeZonelabel()):"" }}
              
            </p>
          </div>
        </div>
      </div>
      <div class="login-logout">
        <a *ngIf="!loggedIn" class="dropdown-item" (click)="onLogin()" href="javascript: void(0);">Login / Sign Up</a>
        <a *ngIf="!loggedIn" class="dropdown-item" href="/login" style="margin-top:10px;">Login With Custom</a>
        <div class="btn-group" dropdown *ngIf="loggedIn">
          <a href dropdownToggle (click)="false"><img src="{{ profileImages?profileImages[5].imageUrl:'../../assets/images.jpeg'}}" height="48" width="48"></a>
          
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
            <li><a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/user-profile']">Profile</a></li>
            <li><a class="dropdown-item" href="javascript: void(0);" (click)="logout()">Log Out</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
