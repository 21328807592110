import { Injectable } from '@angular/core';
import { FrameWorkType } from 'src/app/model/framework-type';
import { BaseApiSerializer } from './base-api-serializer';

@Injectable({
    providedIn: 'root'
})
export class FrameworkTypeApiSerializer extends BaseApiSerializer<FrameWorkType> {
    constructor() {
        super();
    }

    public fromJson(object: any): FrameWorkType {
        return {
            id: object.id,
            name: object.name,
            description: object.description,
            isPimary: object.isPimary,
            createdDate: object.createdDate,
            updatedDate: object.updatedDate,
            path: object.path,
            districtFrameworkTypes: object.districtFrameworkTypes
        } as FrameWorkType;
    }

    public toJson(object: FrameWorkType): any {
        return {
            id: object.id,
            name: object.name,
            description: object.description,
            isPimary: object.isPimary,
            createdDate: object.createdDate,
            updatedDate: object.updatedDate,
            path: object.path,
            districtFrameworkTypes: object.districtFrameworkTypes
        };
    }
}