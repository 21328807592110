import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GoalRequest } from 'src/app/model/user-task';
import { ImageInfo, MainInfo, MultiInfo, SingleInfo, ProfileDetails, AddnewUser, UpdateProfile } from '../models/user-profile-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(private httpClient: HttpClient) { }

  getUserProfile() {
    const url = `${environment.api.remoteServiceBaseUrl2}/User/profile`;
    console.log('uas4', url)
    return this.httpClient.get(url);
  }
  getUserInfo() {
    const url = `${environment.api.remoteServiceBaseUrl}/User/information`;
    return this.httpClient.get(url);
  }
  getUserRating() {
    const url = `${environment.api.remoteServiceBaseUrl}/User/indicator/rating/all`;
    return this.httpClient.get(url);
  }
  postMainInfo(body: MainInfo) {
    const url = `${environment.api.remoteServiceBaseUrl}/User/update/profile/main`;
    return this.httpClient.post(url, body);
  }

  postGrades(body: MultiInfo) {
    const url = `${environment.api.remoteServiceBaseUrl}/User/update/profile/grades`;
    return this.httpClient.post(url, body);
  }

  postPronoun(body: SingleInfo) {
    const url = `${environment.api.remoteServiceBaseUrl}/User/update/profile/pronoun`;
    return this.httpClient.post(url, body);
  }

  postResearchTopics(body: MultiInfo) {
    const url = `${environment.api.remoteServiceBaseUrl}/User/update/profile/researchtopics`;
    return this.httpClient.post(url, body);
  }

  postContactMethods(body: MultiInfo) {
    const url = `${environment.api.remoteServiceBaseUrl}/User/update/profile/contactmethods`;
    return this.httpClient.post(url, body);
  }

  postContentAreas(body: MultiInfo) {
    const url = `${environment.api.remoteServiceBaseUrl}/User/update/profile/contentareas`;
    return this.httpClient.post(url, body);
  }

  postImage(body: ImageInfo) {
    const url = `${environment.api.remoteServiceBaseUrl}/User/update/profile/image`;
    return this.httpClient.post(url, body, { responseType: 'text' });
  }

  createGoal(body: GoalRequest) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Goal`;
    return this.httpClient.post(url, body);
  }

  updateProfile(body: ProfileDetails) {
    const url = `${environment.api.remoteServiceBaseUrl2}/User/update/profile`;
    return this.httpClient.post(url, body);
  }

  updateUserProfile(body: UpdateProfile) {
    const url = `${environment.api.remoteServiceBaseUrl2}/User/update/coach-teacher`;
    return this.httpClient.post(url, body);
  }

  addNewUser(body: AddnewUser) {
    const url = `${environment.api.remoteServiceBaseUrl2}/register`;
    return this.httpClient.post(url, body);
  }
}
