import { Component, Input, Output, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables)

@Component({
  selector: 'doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class FilesShownComponent implements OnInit {

  // INPUT VARIABLES
  @Input() chartType: number = 1;
  @Input() chartID: any;
  @Input() mainData: any;
  @Input() lengthData: any;
  // END: INPUT VARIABLE

  // CHART DATA - POSSIBLE INFO NEEDED FROM DATABASE
  chartDisplayOne: any = {
    chartDisplayType: 0,
    id: "",
    chartDisplayData: [
      {
        title: "Curated",
        value: 86,
        color: "#c19007",
        displayTitle: "beans"
      },
      {
        title: "Interests",
        value: 100,
        color: "#005da0",
        displayTitle: "beans"
      },
      {
        title: "Posted",
        value: 69,
        color: "#6b1da5",
        displayTitle: "beans"
      }
    ]
  }
  //  END: CHART DATA

  // VARIABLE DECLARATIONS
  chartDataLabels: any = [];
  chartBackgroundColors: any = [];
  chartDataValues: any = [];
  numberOfFiles: number = 0;
  // END OF VARIABLE DECLARATIONS

  // constructor
  constructor() { }

  ngAfterViewInit() {
    var ctx: any = document.getElementById(this.chartID);
    const myChart = new Chart(ctx,
      {
        type: 'doughnut',
        data: {
          labels: this.chartDataLabels,
          datasets: [
            {
              backgroundColor: this.chartBackgroundColors,
              data: this.chartDataValues,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this.mainData.chartDisplayData.forEach((chartItem: any, index: number) => {
      this.numberOfFiles = this.numberOfFiles + chartItem.value;
    });


    if (this.chartType == 0) {
      // ADD LABELS, BACKGROUND-COLORS AND VALUES TO THE CHART - FOREACH LOOP
      this.chartDisplayOne.id = this.chartID;
      this.mainData.chartDisplayData.forEach((currentDisplayItem: any, index: any) => {
        currentDisplayItem.displayTitle = currentDisplayItem.title + " - " + currentDisplayItem.value + "%";
        this.chartDataLabels.push(currentDisplayItem.title); // ADD LABEL
        this.chartBackgroundColors.push(currentDisplayItem.color); // ADD BACKGROUND-COLOR
        this.chartDataValues.push(currentDisplayItem.value); // ADD DATA VALUES TO CHART

      });
      // END: ADD LABELS, BACKGROUND-COLORS AND VALUES TO CHART
    }
    else {
    }
  } // END: ngOnInit() FUNCTION

}
