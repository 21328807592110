import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export interface CacheEntry {
  url: string;
  response: HttpResponse<any>;
  expirationTime: number;
}

@Injectable({
  providedIn: 'root'
})
export class HttpCachingService {
  private cacheEntries = new Map<string, CacheEntry>();
  private cachingUrls: string[] = [];

  constructor() { }

  //#region CACHING URLS
  public existsCachingUrl(url: string): boolean {
    return this.cachingUrls.indexOf(url) > -1;
  }

  public addCachingUrl(url: string): void {
    if (!this.existsCachingUrl(url)) {
      this.cachingUrls.push(url);
    }
  }
  //#endregion

  //#region CACHE ENTRIES
  private hasCacheEntryExpired(cacheEntry: CacheEntry): boolean {
    return Date.now() > cacheEntry.expirationTime;
  }

  public deleteExpiredCacheEntries(): void {
    this.cacheEntries.forEach((cacheEntry: CacheEntry) => {
      if (this.hasCacheEntryExpired(cacheEntry)) {
        this.cacheEntries.delete(cacheEntry.url);
      }
    });
  }

  public deleteCacheEntries(): void {
    this.cacheEntries.clear();
  }

  public getCacheEntry(urlWithParams: string): HttpResponse<any> | null {
    const cacheEntry = this.cacheEntries.get(urlWithParams);

    return cacheEntry ? (this.hasCacheEntryExpired(cacheEntry) ? null : cacheEntry.response) : null;
  }

  public setCacheEntry(urlWithParams: string, response: HttpResponse<any>): void {
    const cacheEntry: CacheEntry = {
      url: urlWithParams,
      response,
      expirationTime: Date.now() + environment.api.cacheAge
    };
    this.cacheEntries.set(urlWithParams, cacheEntry);
  }
  //#endregion
}
