import { Component, Input, OnInit } from '@angular/core';
import { CalendarCreator } from './service/calendar-creator.service';
import { Day } from './models/day.model';
import { Event, EventType } from './models/event.model';
import { CoachService } from '../core/services/coach.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  events: Array<Event> = [];
  monthDays: Array<Day> = [];

  meetingRequest: any[] = [];
  isMentor: boolean = false;

  monthNumber = 0;
  year = 0;

  weekDaysName: Array<string> = [];
  dataEvent: any[] = [];

  constructor(public calendarCreator: CalendarCreator,private cs: CoachService) {}

  ngOnInit(): void {
    const date = new Date();
    this.year = date.getFullYear();
    this.monthNumber = date.getMonth();

    this.cs.getMeetingRequest().subscribe((all: any) => {
      this.meetingRequest=all;

      this.meetingRequest.forEach((item)=>{
        if(item.inviteAccepted == true){
          const today = new Date(item.dateTime);
          const yyyy = today.getFullYear();
          let mm = today.getMonth();
          let dd = today.getDate();
          let obj;

          if(localStorage.getItem("isMentor")=="true"){
            obj = { name: item.appointment.menteeName, type: EventType.GOAL, date: new Date(yyyy, mm, dd) };
          }else{
            obj = { name: item.appointment.mentorName, type: EventType.GOAL, date: new Date(yyyy, mm, dd) };
          }
          this.events.push(obj);
        }
        
      });

      this.dataEvent = this.events;
      this.setMonthDays(this.calendarCreator.getMonth(date.getMonth(), date.getFullYear(), this.events));

      this.weekDaysName.push('Mo');
      this.weekDaysName.push('Tu');
      this.weekDaysName.push('We');
      this.weekDaysName.push('Th');
      this.weekDaysName.push('Fr');
      this.weekDaysName.push('Sa');
      this.weekDaysName.push('Su');
      
    });
  }

  onNextMonth(): void {
    this.monthNumber++;

    if (this.monthNumber > 11) {
      this.monthNumber = 0;
      this.year++;
    }

    this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber, this.year, this.events));
  }

  onPreviousMonth(): void {
    this.monthNumber--;

    if (this.monthNumber < 0) {
      this.monthNumber = 11;
      this.year--;
    }

    this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber, this.year, this.events));
  }

  private setMonthDays(days: Day[]): void {
    this.monthDays = days;
    this.monthNumber = this.monthDays[0].monthIndex;
    this.year = this.monthDays[0].year;
  }
}
