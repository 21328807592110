<div class="container w-100">
    <div class="card w-100" style="padding:20px">
        <h2>Schools</h2>
        <div class="schoolform" *ngIf="SchoolModal">
            <form [formGroup]="SchoolForm">
                <table style="margin:auto" [style.opacity]="opacity">
                    <tr>
                        <td width="200">Select State</td>
                        <td>
                            <select formControlName="stateId">
                                <option value="">-Select-</option>
                                <option *ngFor="let state of State" [ngValue]="state.id">{{state.stateName}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td width="200">Select District</td>
                        <td>
                            <select formControlName="districtId">
                                <option value="">-Select-</option>
                                <option *ngFor="let dist of Dist" [ngValue]="dist.id">{{dist.name}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td width="200">School Name</td>
                        <td>
                            <input type="text" formControlName="schoolName" />
                        </td>
                    </tr>                   
                    <tr>
                        <td width="200">City</td>
                        <td>
                            <input type="text" formControlName="city" />
                        </td>
                    </tr>
                    <tr>
                        <td width="200">Zipcode</td>
                        <td>
                            <input type="text" formControlName="zipcode" />
                        </td>
                    </tr>
                    <tr>
                        <td>Type Of School</td>
                        <td>
                            <select formControlName="typeOfSchoolId">
                                <option value="">-Select-</option>
                                <option *ngFor="let objschool of TypeOfSchool" [ngValue]="objschool.typeOfSchoolId">
                                    {{objschool.type}}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>FRL</td>
                        <td>
                            <input type="text" formControlName="frl" />
                        </td>
                    </tr>
                    <tr>
                        <td>ELL</td>
                        <td>
                            <input type="text" formControlName="ell" />
                        </td>
                    </tr>
                    <tr>
                        <td>SPED</td>
                        <td>
                            <input type="text" formControlName="sped" />
                        </td>
                    </tr>
                    <tr style="display: none;">
                        <td width="200">School Id</td>
                        <td>
                            <input type="text" formControlName="schoolId" />
                        </td>
                    </tr>
                    <tr>
                        <td width="200"></td>
                        <td style="display:flex;">
                            <input type="hidden" formControlName="id" />
                            <input type="button" (click)="onSubmit()" value="Submit" style="background-color: #34A8DF; color: #fff; margin-bottom: 10px;" />
                            <input type="button" (click)="onReset()" value="Cancel" style="background-color: #6B89D8; color: #fff; margin-bottom: 10px; margin-left: 10px;" />
                        </td>
                    </tr>
                </table>
            </form>
        </div>
        <div style="text-align:right">
            <button class="btn btn-success" (click)="addNew()">Add New</button>
            <button class="btn btn-success" (click)="exportjsontoexcel('AllSchools',AllSchools)" style="margin-left:10px;background-color: #198754;width:auto;"><i class="fa fa-download"></i>&nbsp; Export To Excel</button>
        </div>
        <table class="table table-bordered table-striped" style="margin-top:10px;" id="school-table">
            <thead>
                <tr>
                    <th>School Name</th>
                    <th>City</th>
                    <th>Zip Code</th>
                    <th width="100">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let school of Schools.data;let i=index">
                    <td>{{ school.schoolName }}</td>
                    <td>{{ school.city }}</td>
                    <td>{{ school.zipcode }}</td>
                    <td style="display:flex;">
                        <button class="btn-edit" (click)="onEdit(school.id,school.stateId,school.districtId,school.schoolName,school.city,school.zipcode,school.id,school.typeOfSchoolId,school.frl,school.ell,school.sped)" style="width:30px;"><i class="fa fa-edit"></i></button>
                        <button class="btn-edit" (click)="onDelete(school.id)" style="width:30px;"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="links">
            <ul *ngIf="schoolLinks.length>1">
                <li *ngFor="let no of schoolLinks" (click)="loadSchool(no)" class="{{ (currentSchool==no)?'active':'' }}">
                    {{no}}
                </li>
            </ul>
        </div>
    </div>
</div>