<div class="container w-100">
    <div class="card w-100" style="padding:20px">
        <h2>All Meeting Requests</h2>        
        <div style="text-align: right;padding-bottom: 10px;">
            <button class="btn btn-success" (click)="exportjsontoexcel('AllMeetingRequestReport',allmeetings)" style="margin-left:10px;background-color: #198754;width:auto;"><i class="fa fa-download"></i>&nbsp; Export To Excel</button>
        </div>
        <div style="margin-bottom: 10px;display:flex;width:500px;justify-content: end;">
            <input type="text" placeholder="Enter Coach/Teacher Name" class="name form-control" />
            <button class="btn btn-primary" style="background-color: #aa97d8; border-color: #aa97d8; padding: 5px 20px;margin-left: 10px;" (click)="search()">Search</button>
            <button class="btn btn-primary" style="background-color: #d89797; border-color: #d89797; padding: 5px 20px;margin-left: 10px;" (click)="reset()">Reset</button>
        </div>
        <table class="table table-bordered table-striped" id="meeting-table">
            <thead>
                <tr>
                    <th>Coach</th>
                    <th>Teacher</th>
                    <th>Status</th>
                    <th>Meeting Time</th>
                    <th>Created On</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of meetings; let i = index">
                    <td>{{ item.mentor }} <i class="fa fa-copy" style="display:none;"></i></td>
                    <td>{{ item.mentee }} <i class="fa fa-copy" style="display:none;"></i></td>
                    <td>{{ item.inviteStatus }}</td>
                    <td>{{ getMMDDYYYY(converttimezone(item.meetingTime)) +" "+ getTimeZonelabel() }}</td>
                    <td>{{ getMMDDYYYY(converttimezone(item.createdOn)) +" "+ getTimeZonelabel()  }}</td>
                </tr>
            </tbody>
        </table>
        <div class="links">
            <ul>
                <li *ngFor="let no of meetingLinks" (click)="loadMeeting(no)" class="{{ (currentMeeting==no)?'active':'' }}">
                    {{no}}
                </li>
            </ul>
        </div>
    </div>
</div>