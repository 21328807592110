import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})

export class DateTimePickerComponent implements OnInit {
  timeSlots: string[] = [];
  selectedMeetingLength: number = 0;
  selectedTimeSlot: string = '';
  userTimezone: string = '';
  previousSelected = null;
  // selected: Date = new Date();  displays all the time
  selected!: Date | null; // only display when selected
  minDate: Date = new Date();
  maxDate: Date = new Date();
  @Output() selectedTimeSlotChange = new EventEmitter<any>();
  @Output() selected2 = new EventEmitter<string>();
  @Input() availabilityIntervals: string = '';
  @Input() coachSelected: string = '';
  // @Input() selected!: Date | null; // only display when selected
  @Output() timeSelected = new EventEmitter<string>();

  hours: number[] = Array.from({ length: 12 }, (_, i) => i+1);
  minutes: number[] = Array.from({ length: 60 }, (_, i) => i);
  selectedHour: number = 1;
  selectedMinute: number = 0;
  selectedPeriod: string = 'AM';
  selectedlength!: boolean
  newtime: string = '';
  newtime2: any;
  newTimeSlots: string[] = [];
  selectedTime: string = '';
  timeslotarr: any = [];
  timeslotuserdisplay: any = [];
  isError1: boolean = false;
  isslotselected: boolean = false;

  @Output() selectedMeetingLengthChange = new EventEmitter<number>();


  constructor() {
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDate.setDate(this.maxDate.getDate() + 365);
  }

  ngOnInit(): void {
    console.log('ON INIT', this.availabilityIntervals)
    this.userTimezone = this.getUserTimezone();
    this.updateTimeSlots();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coachSelected) {
      this.timeSlots = []; // Reset the timeSlots array
      this.timeslotarr = [];
      this.selectedTimeSlot = '';
      this.selectedMeetingLength = 0;
    }
  }

  dateFilter = (date: Date): boolean => {
    const today = new Date();
    return date >= this.minDate && date <= this.maxDate && date >= today;
  }

  updateTimeSlots() {
    console.log('AVAILABILITY SLOTS', this.availabilityIntervals);
    this.timeSlots = []; // Reset the timeSlots array
    const slotDuration = Number(this.selectedMeetingLength);
    const now = new Date();

    const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 7, 0, 0);
    startTime.setMinutes(Math.ceil(startTime.getMinutes() / slotDuration) * slotDuration);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);

    const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21, 0, 0); // Set end time to 9PM

    let excludeStartTime, excludeEndTime;
    if (!this.availabilityIntervals.includes('Before 8am')) {
      //excludeStartTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 7, 0, 0);
      //excludeEndTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0, 0);
    }

    let excludeStartTime2, excludeEndTime2;
    if (!this.availabilityIntervals.includes('8am-3pm')) {
      //excludeStartTime2 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0, 0);
      //excludeEndTime2 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0, 0);
    }


    let excludeStartTime3, excludeEndTime3;
    if (!this.availabilityIntervals.includes('After 3pm')) {
      //excludeStartTime3 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0, 0);
      //excludeEndTime3 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21, 0, 0);
    }

    while (startTime.getTime() <= endTime.getTime()) {
      const currentTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());

      /*
      if (
        !(excludeStartTime && excludeEndTime && currentTime >= excludeStartTime && currentTime <= excludeEndTime) &&
        !(excludeStartTime2 && excludeEndTime2 && currentTime >= excludeStartTime2 && currentTime <= excludeEndTime2) &&
        !(excludeStartTime3 && excludeEndTime3 && currentTime >= excludeStartTime3 && currentTime <= excludeEndTime3)
      ) {
        const slotStartTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        startTime.setMinutes(startTime.getMinutes() + slotDuration);
        const slotEndTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const timeSlot = slotStartTime + ' - ' + slotEndTime;
        this.timeSlots.push(timeSlot);
      }
      */
      if (
        !(excludeStartTime && excludeEndTime) &&
        !(excludeStartTime2 && excludeEndTime2) &&
        !(excludeStartTime3 && excludeEndTime3)
      ) {
        const slotStartTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        startTime.setMinutes(startTime.getMinutes() + slotDuration);
        const slotEndTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const timeSlot = slotStartTime + ' - ' + slotEndTime;
        this.timeSlots.push(timeSlot);
      }

      startTime.setMinutes(startTime.getMinutes() + slotDuration);
    }
  }

  isWeekend(date: Date): boolean {
    const dayOfWeek = date.getDay();
    return dayOfWeek !== 6 && dayOfWeek !== 0; // Only allow Saturday and Sunday as selectable dates
  }

  selectTimeSlot(slot: string) {
    this.selectedTimeSlot = slot;
    console.log(slot,'Slot');
    // const msg = this.selectedTimeSlot + "##" + this.selectedMeetingLength + "##" + this.convertTimeRangeTo24HourFormat(this.selectedTimeSlot) + "##" + this.getFormatedDate(this.selected);
    // console.log("Selected time slot:", msg);
    // this.selectedTimeSlotChange.emit(msg);
    // this.selected2.emit(this.selected + "");
    
    const msg = this.selectedTimeSlot;
    console.log("Selected time slot:", this.selectedTimeSlot);
    const meetinglength = this.selectedMeetingLength;
    let firstpart = slot.split("-")[0];
    let secondpart = slot.split("-")[1];
    const timeslot = this.convertTimeRangeTo24HourFormat(this.selectedTimeSlot);
    const timeslotUser = slot;
    const date = this.selected;
    
    if(this.timeslotarr.length>=4){
      this.isError1 = true;
      return ;
    }

    $("button[data-btnid='"+slot+"']").hide();
    let data = {"timeslot":timeslot,"date":this.getFormatedDate(date),"duration":this.selectedMeetingLength};
    console.log(data);
    this.timeslotarr.push(data);

    this.timeslotuserdisplay.push({"timeslot":timeslotUser,"date":this.getFormatedDate(date)})
    const params = {"msg":msg,"meetinglength":meetinglength,"timeslot":timeslot,"date":date,"totalslots":this.timeslotarr,"duration":this.selectTimeSlot};

    this.selectedTimeSlotChange.emit(params);
    this.selected2.emit(date+"");

    this.isslotselected = false;
    this.selectedlength = false;
    this.selectedMeetingLength = 0;
    this.selectedMinute = 0;
    this.selectedHour = 0;
    this.selectedPeriod = 'AM';
    this.selected = null;
  }

  getFormatedDate(date: any) {
    return moment(date).format('YYYY/MM/DD, ')
  }

  getUserTimezone() {
    const timezoneAbbreviation = moment.tz.guess(true);
    return moment.tz(timezoneAbbreviation).format('z');
  }

  convertTimeRangeTo24HourFormat(timeRange: any) {
    const startTime = timeRange.split(' - ')[0];
    return this.convertTo24HourFormat(startTime);
  }

  convertTo24HourFormat(time: any) {
    return moment(time, 'hh:mm A').format('HH:mm');
  }

  onTimeSelected(): void {
    this.selectedTime = `${this.selectedHour.toString().padStart(2, '0')}:${this.selectedMinute.toString().padStart(2, '0')} ${this.selectedPeriod}`;
    this.newTimeSlots = [this.selectedTime];
    console.log(this.newTimeSlots, 'New time slots');
    this.timeSelected.emit(this.selectedTime);
    this.selectedlength = !this.selectedlength;
  }

  onMeetingLengthChange(): void {
    if (this.selectedMeetingLength !== 0 && this.selectedTime !== '') {
      const slotDuration = Number(this.selectedMeetingLength);
      const startTime = moment(this.selectedTime, 'hh:mm A');
      const endTime = moment('11:59 PM', 'hh:mm A');

      this.isslotselected = true;
      this.newTimeSlots = [];
      

      while (startTime.isBefore(endTime)) {
        const slotEndTime = moment(startTime).add(slotDuration, 'minutes');
        const timeSlot = `${startTime.format('h:mm A')} - ${slotEndTime.format('h:mm A')}`;
        this.newTimeSlots.push(timeSlot);
        startTime.add(slotDuration, 'minutes');
      }

      console.log(this.newTimeSlots, 'New time slots until 9:00 PM');
      this.selectedMeetingLengthChange.emit(this.selectedMeetingLength);
    }
  }

  deletetimeslot(index:number){
    var slot = this.timeslotuserdisplay[index].timeslot;
    console.log(slot);
    $("button[data-btnid='"+slot+"']").show();
    console.log(index,"index");
    this.timeslotarr.splice(index, 1);
    this.timeslotuserdisplay.splice(index, 1);
    if(this.timeslotarr.length<4){
      this.isError1=false;
    }
  }
}
