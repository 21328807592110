import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpCachingService } from './services/http-caching.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  baseUrl = `${environment.api.remoteServiceBaseUrl}`;

  constructor(private httpClient: HttpClient, private httpCachingService: HttpCachingService) { }

  getMessage(userid:number) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Message?receiverNumber=`+userid;
    return this.httpClient.get(url);
  }

  sendMessage(receiverId:number,message:string,base64EncodedData:string,fileName:string){
    const url = `${environment.api.remoteServiceBaseUrl2}/Message`;
    return this.httpClient.post(url,{receiverNumber:receiverId,message:message,base64EncodedData:base64EncodedData,fileName:fileName});
  }

  unreadMessage() {
    const url = `${environment.api.remoteServiceBaseUrl2}/message/markasread`;
    return this.httpClient.get(url);
  }

  unreadMessage2(senderNumber:string,receiverNumber:string) {    
    const url = `${environment.api.remoteServiceBaseUrl2}/message/markasread?senderNumber=`+senderNumber+`&receiverNumber=`+receiverNumber;
    return this.httpClient.put(url,{senderNumber:senderNumber,receiverNumber:receiverNumber});
  }

  getUnreadMessageCount(userid:string){
    const url = `${environment.api.remoteServiceBaseUrl2}/message/unreadcounts/`+userid;
    return this.httpClient.get(url);
  }

  instantRequestMeeting(data:object){
    const url = `${environment.api.remoteServiceBaseUrl2}/appointment/InstantRequestMeeting/`;
    return this.httpClient.post(url,data);
  }
}
