import { Directive, ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import * as fromLayout from '../store/selectors/layout.selector';

@Directive({ selector: '[appSidebarWatch]' })
export class SidebarWatchDirective implements OnInit {
  constructor(private el: ElementRef, private store: Store<AppState>) {}

  /*
  Doing the checks on ngOnInit makes sure the DOM is fully loaded and the
  elements are available to be selected
  */
  ngOnInit(): void {
    /*
    Watch for the left sidebar state
    */
    this.store.select(fromLayout.getLeftSidenavState).subscribe((state) => {
      if (this.el.nativeElement.className.includes('sidebar')) {
        if (state) {
          this.el.nativeElement.classList.add('open-sidenav');
        } else {
          // removing class
          console.log('remove');
          this.el.nativeElement.classList.remove('open-sidenav');
        }
      }
    });
  }
}
