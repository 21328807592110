import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AddSchool } from '../model/school';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx'
import { DistrictService } from '../core/services/district.service';

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['../allmeetings/allmeetings.component.scss']
})
export class DistrictComponent implements OnInit {

  AddForm = this.fb.group({
    id:"",
    name:"",
    active:true
  });
  district:any = [];
  AddModal = false;
  discrtictdata:any = {};

  opacity = 1;
  isedit = false;

  constructor(private ss: DistrictService,private fb: UntypedFormBuilder,private toastr: ToastrService) {
    this.init()
  }

  init(){
    this.ss.getDistrict(1,10).subscribe((result: any) => {
      this.district = result;
    });
  }

  ngOnInit(): void {
  }

  addNew(){
    this.resetForm();
    this.AddModal = true;
  }

  onDelete(id:number){
    if(confirm("Are you sure you want to delete?")){
      this.ss.deleteDistrict(id).subscribe((result: any) => {
        this.toastr.success(result.message, undefined, {
          positionClass: 'toast-top-right',
          timeOut: 2000
        });
        this.init();
      });
    }
  }

  resetForm(){
    this.AddForm.patchValue({
      id:"",
      name:"",
      active:""
    });
    this.isedit = false;
  }

  onCancel(){
    this.AddModal = false;
  }

  onEdit(id:number,name:string,active:string){
    this.AddForm.patchValue({
      id:id,
      name:name,
      active:active
    });
    this.isedit = true;
    this.AddModal = true;
  }

  onSubmit(){
    let validate = true;
    this.opacity=0.5;

    let id = 0;
    
    if(this.AddForm.get('name')?.value==""){
      this.toastr.error(`Name is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      this.opacity=1; 
      validate = false;
    }

    if(this.AddForm.get('active')?.value==""){
      this.toastr.error(`isActive is required `, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      this.opacity=1; 
      validate = false;
    }

    if(validate){

      if(this.isedit){
        this.discrtictdata = {
          id:this.AddForm.get("id")?.value,
          name:this.AddForm.get("name")?.value,
          isActive:this.AddForm.get("active")?.value
        }
        this.ss.editDistrict(this.discrtictdata).subscribe((res:any)=>{
          this.toastr.success(res.message, undefined, {
            positionClass: 'toast-top-right',
            timeOut: 2000
          });
          this.opacity = 1;
          this.init();
          this.resetForm();
        }); 
      }else{
        this.discrtictdata = {
          name:this.AddForm.get("name")?.value,
          isActive:this.AddForm.get("active")?.value
        }
        this.ss.addDistrict(this.discrtictdata).subscribe((res:any)=>{
          this.toastr.success(res.message, undefined, {
            positionClass: 'toast-top-right',
            timeOut: 2000
          });
          this.opacity = 1;
          this.init();
          this.resetForm();
        });    
      }
    }
  }
}
