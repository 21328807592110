import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PlansService } from 'src/app/dataServices/plans.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import * as alertifyjs from 'alertifyjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FileCabinetService } from 'src/app/core/services/file-cabinet.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Event } from '@angular/router';
import { SortByComponent } from '../../my-filing/sort-by/sort-by.component'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-file-cabinet-upload-modal',
  templateUrl: './file-cabinet-upload-modal.component.html',
  styleUrls: ['./file-cabinet-upload-modal.component.scss']
})
export class FileCabinetUploadModalComponent implements OnInit {

  @Input() filename: any = "";
  @Input() filenameToDisplay: any;
  @Input() rootFolders: any;
  @Input() sortButton: any;

  // REACTIVE FORM-BUILDER PROPERTIES base 64
  fileDropForm: UntypedFormGroup;
  virtualPathForm: UntypedFormGroup;
  // END: REACTIVE FORM-BUILDER PROPERTIES

  dataForfilter: any = [];
  selectButton: any = [];
  dataType: any = [];
  dataTypeFilter: any = [];
  keywordsAssociated: any = [];
  tempKeywordsAssociated: any = [];
  list: any = File;
  clickedKeywords: any = [];
  tempFiles: any = [];
  tempKeywords: any = [];
  activeCss: boolean = false;
  keyClassName: any;
  file1:string = "";

  // base 64 variables
  //base64Images: any = [];
  base64Images: any[] = [];
  fileName: string = "";
  justinReturnedAPI: any;
  showFormError = false;
  isFormSubmissionValid: boolean = false;
  uploadedFiles: any = [];


  // new var
  Productlist: any;
  productImage: any;
  EditProductCode = '';
  Result: any;
  file!: File; // Variable to store file
  progressvalue = 0;
  // files: any;

  //can not add sortby data
  constructor(private _fileCabinetService: FileCabinetService, private httpClient: HttpClient, private _planService: PlansService, private modalService: NgbModal, private fb: UntypedFormBuilder,private toastr: ToastrService) {

    // FORM BUILDER: MAIN FILE-DROP FORM 64
    this.virtualPathForm = this.fb.group({
      level: 0,
      name: ['', Validators.required]
    });

    // FORM BUILDER: VIRTUAL-PATH FORM base64
    this.fileDropForm = this.fb.group(
      {
        // base64EncodedData: "",
        tags: this.fb.array([this.createTag()], Validators.required),
        shareWithPublic: [false, [Validators.required]],
        isCurated: [false, [Validators.required]],
        webUrl: "",
        webUrlName: "",
        fileType: 0,
        virtualPath: "",
        // virtualFolerPath: [this.virtualPathForm]
      }

      //       {
      //   base64EncodedData: "",
      //   ro
      //   fileName: "",
      //   tags: [ "" ],
      //   shareWithPublic: false,
      //   isCurated: true,
      //   webUrl: "",
      //   webUrlName: "",
      //   mineType: "",
      //   virtualPath: "",
      //   virtualPathFolderPath: [
      //     {
      //       level: 0,
      //       name: ""
      //     }
      //   ]
      // }

    );
    // END: FORM BUILDER ITEMS    
  }

  ngOnChanges(): void {
    this.Getallproducts();
    console.log("this.rootFolders: file-cabinet-upload.component", this.rootFolders); // NOT WORKING
  }

  // ngOnInit(): void {
  //   this.list.forEach((file: File) => {
  //     this.tempFiles.push(file);
  //   });
  //   console.log("this temp files", this.tempFiles);
  //   console.log("rootFolders", this.rootFolders); // NOT WORKING...being called from image-drag-n-drop.component.HTML
  //   console.log("fileNameToDisplay", this.filenameToDisplay); // NOT WORKING...being called from image-drag-n-drop.component.HTML
  //   this._planService.getAllPlans().subscribe((data: any) => {
  //     this.dataForfilter = data

  //     this.selectButton = _.chain(this.dataForfilter.files)
  //       .groupBy("virtualPath")
  //       .map((value, key) => ({ virtualPath: key, virtualFolderPath: value }))
  //       .value()
  //     console.log(" default button", this.selectButton)
  //   });



  //   this.dataType = this._planService.getButtons();
  //   this.dataTypeFilter = _.take(this.dataType, 5);
  //   this.keywordsAssociated = _.takeRight(this.dataTypeFilter, 3);
  //   this.keywordsAssociated.forEach((temp: any) => {
  //     this.tempKeywordsAssociated.push(temp);
  //   });

  //   console.log("this.filenameToDisplay", this.filenameToDisplay)


  // }

  ngOnInit(): void {
    console.log("this.list", this.list);
    this.list.forEach((file: File) => {
      this.tempFiles.push(file);
    });
    console.log("this.tempFiles", this.tempFiles);

    var typeButton = this.sortButton
    console.log("typeButton", typeButton)

    this._planService.getAllPlans().subscribe((data: any) => {
      this.dataForfilter = data;

      this.selectButton = _.chain(this.dataForfilter.files).groupBy("virtualPath").map((value, key) => (
        { virtualPath: key, virtualFolderPath: value }
      )).value();
      console.log(" default button", this.selectButton);
    });


    this.dataType = this._planService.getButtons();
    var genreButtons = _.takeWhile(this.dataType.data, { 'filterType': 'genre' });
    this.keywordsAssociated = _.takeRight(genreButtons, (genreButtons.length - 2));

    this.keywordsAssociated.forEach((temp: any) => {
      this.tempKeywords.push(temp);
    });

    //   console.log("this.filenameToDisplay", this.filenameToDisplay);

    console.log("this.filenameToDisplay", this.filenameToDisplay);
    this.Getallproducts();
  }

  // this graps api call
  protected get baseUrl(): string {
    return `${environment.api.remoteServiceBaseUrl}`;
  }

  isAddToProfile: boolean = false;

  cancelPost() {
    
  }

  Getallproducts() {
    this._planService.getAllPlans().subscribe((result: any) => {
      this.Productlist = result;
    });
  }
  updateInfo(events: any) {
    var button = $(this);
    button.prop("disabled",true);
    this.generateBlob(events.addedFiles)
    
    this.fileName = $("#filename").val()+"";
    //let img = this.base64Images[0];
    console.log("Image ",this.fileName);
    // base64

    //    let formdata = new FormData();
    // formdata.append("file", this.file, this.EditProductCode)
    // console.log("form data", formdata)
    setTimeout(()=>{
      let formValue = Object.assign({}, this.fileDropForm.value, {
        virtualPath: [this.virtualPathForm.value],
        base64EncodedData: this.base64Images[0].base64,
        fileExtension: this.base64Images[0].name.substr(this.base64Images[0].name.lastIndexOf('.') + 1),
        fileName: this.fileName,
        tags: [null]
        // tags: ['Best']
      });

      this._fileCabinetService.uploadNewFile(formValue).subscribe((result: any) => {        
        var apiresult = JSON.parse(result);
        if(apiresult.success){
          this.toastr.success(apiresult.message, undefined, {
            positionClass: 'toast-top-right',
            timeOut: 2000,
          });
          setTimeout(function(){
            window.location.reload();
          },300);
          button.prop("disabled",false);
        }else{
          this.toastr.success(apiresult.message, undefined, {
            positionClass: 'toast-top-right',
            timeOut: 2000
          });
          setTimeout(function(){
            window.location.reload();
          },300);
          button.prop("disabled",false);
        }
      });
      /*
      this._fileCabinetService.uploadNewFile(formValue).pipe(map(events => {
        switch (events.type) {
          case HttpEventType.UploadProgress:
            this.progressvalue = Math.round(events.loaded / events.total! * 100);
            break;
          case HttpEventType.Response:
            this.Getallproducts();
            alertifyjs.success("Upload completed");
            setTimeout(() => {
              this.progressvalue = 0;
            }, 2500);
            break;

        }
        console.log("this is progress bar", this.progressvalue);
      }),
        catchError((error: HttpErrorResponse) => {
          alertifyjs.error('Failed to upload')
          return of("failed");
        })

      ).subscribe(result => {
        console.log(result,"result123");
        //window.location.reload();
        this.Getallproducts();
        alertifyjs.success("Upload completed");
      });
      */
      console.log("the last formValue", formValue);
      // alert("Update Info Button was pressed.....MODAL WILL SELF DESTRUCT in 5, 4, 3, 2, 1....not working yet, spaceball here...")

      var newPlans = this._planService.addDocumentsToPlan(formValue.virtualPath[0].name, this.uploadedFiles);
      console.log("new Plans !!!", newPlans)
    },1000);
  }

  addKeyword(e: any) {
    const testKeyword: any = {
      "title": "Highest Priority",
      "value": "this.sortFilterBy2",
      "color": "#c19007",
      "displayTitle": "",
      "css": "gold-dot",
      "isActive": false,
      "click": "Curated",
      "filterType": "genre"
    }
    this.tempKeywords.push(testKeyword);
    console.log("addKeyword(event)", e);
    console.log("addedKeyword to this.tempKeywords", this.tempKeywords);
    // alert('Add Keyword function works...');
  }

  deleteKeyword(e: any) {
    console.log("deleteKeyword(e): e = ", e);
    // alert("Deleting a 'keyword' function works....");
  }

  toggleDirectory(e: any) {
    console.log("toggleDirectory(event)", e)
    // alert("toggleDirectory() works....switch background-color in CSS")
  }

  switchAddToProfile(e: any) {
    this.isAddToProfile = !this.isAddToProfile;
    console.log("switchAddToProfile(event)", e)
    // alert("Is switchded on/off..." + this.isAddToProfile)
  }

  addTagInputKeyup(e: any) {
    console.log("addTagInputKeyup", e)
    if (e.key === "Escape") {
      e.target.value = ""
      e.target.focus()
      // alert("you pressed ESC....clearing out text box and resetted focus() to same textbox")
    }
    if (e.key == "Enter") {
      // alert("you pressed Enter....add to temporary 'keywords-Array'")
    }
  }

  deleteUploadedFile(e: any, listItem: any) {
    console.log("list", this.list);
    console.log("listItem", listItem);
    const fileToDelete = this.list.indexOf(listItem);
    if (fileToDelete > -1) { // only splice array when item is found
      this.list.splice(fileToDelete, 1); // 2nd parameter means remove one item only
    }
  }

  fileClicked() {
    console.log("fileClicked()");
  }

  keywordClicked2(e: any, keyword: any) {
    console.log("e : All", e);
    console.log("e : looking for class names", e.target.className);
    console.log("e.srcElement.attributes.classList", e.srcElement.attributes.classList)
    console.log("keyword", keyword);

    const keywordClassName: any = e.target.className.split(" ");
    console.log("keywordClassName :BEFORE", keywordClassName);
    const index = keywordClassName.indexOf("active"); // Gets <div CLASS="">, where value = active
    keywordClassName.splice(index, 1);
    console.log("keywordClassName :AFTER", keywordClassName);
    this.activeCss = false;
    console.log("this.activeCss", this.activeCss);
  }
  keywordClicked(keyword: any) {
    console.log("keyword", keyword);
    keyword.isActive = !keyword.isActive;
    console.log(keyword.title + " : isActive = " + keyword.isActive);
    if (keyword.isActive) {
      this.clickedKeywords.push(keyword);
    }
    else {
      const keywordToRemove = this.clickedKeywords.indexOf(keyword);
      this.clickedKeywords.splice(keywordToRemove, 1);
    }

    console.log("selected Keywords", this.clickedKeywords);
  }

  isActiveStyle(listItem: any) {
    // console.log("listItem : isActiveStyle(listItem)", listItem);
    if (listItem.isActive) {
      return { 'background-color': 'red' };
    }
    else {
      return { 'background-color': 'blue' };
    }
  }

  getActiveClass(isActive: boolean, keyword: any) {
    // console.log("keyword5", keyword);

    return "active";
    // if (isActive) {
    //   isActive = false
    // }
    // else{
    //   isActive = true;
    // }
  }

  // base 64
  createTag(): UntypedFormGroup {
    return this.fb.group({
      name: [null, Validators.required]
    });
  }

  // base 64
  generateBlob(files: any) {
    files = this.list
    console.log("files this", files)
    if (files) {
      files.forEach((data: any) => {
        const uploadedFile: any = files;
        const reader = new FileReader();

        reader.readAsDataURL(data);
        reader.onload = (e: any) => {
          this.base64Images.push({"base64":e.target.result.split(',')[1],"name":data.name});
        };
      });
      console.log("base64Image", this.base64Images);
    }
    else {

    }
  }

  // Gets all Tags/Keywords for selected Plan (history/math/science...etc)
  get tags(): FormArray {
    return <FormArray>this.fileDropForm.get('tags');
  }  
}
