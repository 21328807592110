import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Goal } from '../../../model/goal';
import { Standard } from 'src/app/core/models/standard.model';
import { Framework } from 'src/app/model/framework';
import { UserRating } from 'src/app/model/user-rating';
import { UserAssessmentActionTypes } from '../actions/user-assessment.actions';
import { SubIndicator } from 'src/app/core/models/sub-indicator.model';
import { LevelTwoStandard } from 'src/app/core/models/leveltwo-standard.model';

export interface UserAssessmentState extends EntityState<Goal> {
  selectedFramework: Framework | null;
  selectedStandard: Standard | null;
  firstStandardPriority: Standard | null;
  secondStandardPriority: Standard | null;
  thirdStandardPriority: Standard | null;
  firstSubIndicatorPriority: LevelTwoStandard | null;
  secondSubIndicatorPriority: LevelTwoStandard | null;
  thirdSubIndicatorPriority: LevelTwoStandard | null;
  userRatings: Array<UserRating> | null;
  overallStandardScore: number | null;
  totalStandardScore: number | null;
  loading: boolean;
  error: string | null;
  currenQuestion: number | null;
  ratingScale: number;
}

export const adapter: EntityAdapter<Goal> = createEntityAdapter<Goal>({
  sortComparer: false
});

export const initialGoalUserState: UserAssessmentState = adapter.getInitialState({
  selectedStandard: null,
  selectedFramework: null,
  firstStandardPriority: null,
  secondStandardPriority: null,
  thirdStandardPriority: null,
  firstSubIndicatorPriority: null,
  secondSubIndicatorPriority: null,
  thirdSubIndicatorPriority: null,
  userRatings: null,
  overallStandardScore: null,
  totalStandardScore: null,
  currenQuestion: null,
  loading: false,
  ratingScale: 5,
  error: null
});

export const userAssessmentReducer = createReducer(
  initialGoalUserState,

  on(UserAssessmentActionTypes.setSelectedFramework, (state, { selectedFramework }) => ({
    ...state,
    selectedFramework
  })),
  on(UserAssessmentActionTypes.resetUserScore, (state) => ({
    ...state,
    userRatings: null,
    totalStandardScore: null,
    overallStandardScore: null
  })),

  on(UserAssessmentActionTypes.setAreaOfFocus, (state, { areaOfFocus }) => ({
    ...state,
    firstStandardPriority: areaOfFocus[0],
    secondStandardPriority: areaOfFocus[1],
    thirdStandardPriority: areaOfFocus[2]
  })),

  on(UserAssessmentActionTypes.setAreaOfImprovements, (state, { areaOfImprovement }) => ({
    ...state,
    firstSubIndicatorPriority: areaOfImprovement[0],
    secondSubIndicatorPriority: areaOfImprovement[1],
    thirdSubIndicatorPriority: areaOfImprovement[2]
  })),

  on(UserAssessmentActionTypes.setRating, (state, { score }) => {
    let userRatings = Object.assign([], state.userRatings === null ? [] : state.userRatings);
    userRatings.push(score);

    return {
      ...state,
      userRatings,
      totalStandardScore: state.ratingScale * userRatings.length,
      overallStandardScore: userRatings.reduce((currentSum, item: UserRating) => {
        return currentSum + (item.score || 0);
      }, 0)
    };
  }),

  on(UserAssessmentActionTypes.setCurrentQuestion, (state, { question }) => ({
    ...state,
    currenQuestion: question
  }))
);

export const selectFramework = (state: UserAssessmentState) => state.selectedFramework;
export const { selectAll, selectIds, selectEntities, selectTotal } = adapter.getSelectors();
