import { Directive, OnInit, ElementRef  } from '@angular/core';

@Directive({
  selector: '[appTextBox]'
})
export class TextBoxDirective {

  constructor(private elRef: ElementRef) { }

  ngOnInit(): void {
    this.elRef.nativeElement.focus()
  }

}
