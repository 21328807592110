import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() currentpage:number=1;
  @Input() links:number=5;
  @Input() pagePage:number=0;
  @Input() totalrecords:number=20;
  pagelinks: number[] = [];

  constructor() { 
    let prlinks = Math.ceil(this.totalrecords/this.pagePage);

    console.log("Page Link: "+prlinks);
  }

  ngOnInit(): void {
  }

}
