import { createAction, props } from '@ngrx/store';
import { loggedUser } from 'src/app/model/user';

export const init = createAction('[Session/API] Init');
export const clear = createAction('[Session/API] Clear Session Data');

export const SessionActionTypes = {
  init,
  clear
};
// export const loadSessionSuccess = createAction('[Session/API] Load Successful');
/*export const logIn = createAction('[Session/API] Log In', props<{ username: string; password: string }>());
export const logOut = createAction('[Session/API] Log Out', props<{ options?: LogOutOptions }>());
export const logOutFailure = createAction('[Session/API] Logout: Failure', props<{ error: string }>());

export const clear = createAction('[Session/API] Clear Session Data', props<{ options?: LogOutOptions }>());
export const loadSessionFailure = createAction('[Session/API] General: Load Failed', props<{ error: string }>());

export const brokerageAccountInit = createAction('[Session/API] Brokerage Account: Init');
export const brokerageAccountLoadFromServer = createAction('[Session/API] Brokerage Account: Load From Server');
export const brokerageAccountSet = createAction(
  '[Session/API] Brokerage Account: Loaded From Server',
  props<{ brokerageAccountInfo: AccountEntity }>()
);
export const brokerageAccountLoadFailure = createAction(
  '[Session/API] Brokerage Account: Load Failed',
  props<{ error: string }>()
);
export const brokerageAccountAlreadyLoaded = createAction('[Session/API] Brokerage Account: Loaded From State');
export const brokerageAccountInactive = createAction('[Session/API] Brokerage Account: Inactive');

export const profileInit = createAction('[Session/API] Profile: Init');
export const profileLoadFromServer = createAction('[Session/API] Profile: Load From Server');
export const profileSet = createAction(
  '[Session/API] Profile: Loaded From Server',
  props<{ profile: ProfileEntity }>()
);
export const profileLoadFailure = createAction('[Session/API] Profile: Load Failed', props<{ error: string }>());
export const profileAlreadyLoaded = createAction('[Session/API] Profile: Loaded From State');

export const setAuthToken = createAction('[Session/API] Auth Token: Set', props<{ authToken: string }>());
export const setImpersonation = createAction('[Session/API] Impersonation: Set', props<{ impersonation: boolean }>());

export const profileUpdate = createAction(`[Session/API] Profile: Update`, props<{ profile: ProfileEntity }>());
export const profileUpdateSuccess = createAction(
  `[Session/API] Profile: Update Success`,
  props<{ profile: ProfileEntity }>()
);
export const profileUpdateError = createAction(`[Session/API] Profile: Update Error`, props<{ error: string }>());
export const resetProfileFlags = createAction(`[Session] Profile: Reset Flags`);

export const updateProfilePhoto = createAction(`[Session/API] Update Profile Photo`, props<{ data: File }>());
export const updateProfilePhotoSuccess = createAction(
  `[Session/API] Update Profile Photo: Success`,
  props<{ profile: ProfileEntity }>()
);
export const updateProfilePhotoError = createAction(
  `[Session/API] Update Profile Photo: Error`,
  props<{ error: string }>()
);

export const getAuthenticationMethod = createAction(
  `[Session/API] Get Authentication Method`,
  props<{ brokerageFirmId: string; email: string }>()
);
export const getAuthenticationMethodSuccess = createAction(
  `[Session/API] Get Authentication Method: Success`,
  props<{ authenticationMethod: AuthenticationMethods }>()
);
export const getAuthenticationMethodError = createAction(
  `[Session/API] Get Authentication Method: Error`,
  props<{ error: string }>()
);

export const requestNewMagicLink = createAction(
  `[FeatureMagicLinks/API] Request New Token`,
  props<{ email: string; brokerageFirmId: string }>()
);
export const requestNewMagicLinkSuccess = createAction(`[FeatureMagicLinks/API] Request New Token: Success`);
export const requestNewMagicLinkError = createAction(
  `[FeatureMagicLinks/API] Request New Token: Error`,
  props<{ error: string }>()
);*/
