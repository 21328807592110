import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../app/login-component/login.service';
import { resetpassword } from '../core/models/login';
import { Router,ActivatedRoute } from '@angular/router';
import { appRoutesNames } from './../app.routes.names';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email:string="";
  token:string="";

  constructor(private ls: LoginService,private router: Router,private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['q'];
    });
   }

  ngOnInit(): void {
  }

  onSubmit() {
    let urlParams = new URLSearchParams(window.location.search);
    
    let password = $("#password").val();
    let confirmpassword = $("#confirmpassword").val();

    if(password!="" && password == confirmpassword){      
      const objresetpassword: resetpassword = {
        email: this.email,
        token: this.token,
        password: ""+password,
        confirmpassword:""+confirmpassword
      }
      
      this.ls.ResetPassword(objresetpassword).subscribe((result: any) => {
        $("#password").val("");
        $("#confirmpassword").val();
        alert("Your password has been changed successfully");
        setTimeout(() => {
          window.location.href="/";
        }, 2000);  //2s
        
      });
      
      return false;
    }else{
      alert("Both password does not match");
      return false;
    }
  }

}
