<div class="calendar">
  <!--<div *ngFor='let weekDay of weekDaysName' class="day">
    {{ weekDay }}
  </div>
  <div class="seperator"></div>-->
  <div class="calendar-controls">
    <button (click)="onPreviousMonth()"><span class="icon icon-angle-left"></span> <span class="text">Previous</span></button>
    <div class="month-year">
      <span>{{ year }} </span>
      <span class="month-value"> {{ calendarCreator.getMonthName(monthNumber) }}</span>
    </div>
    <button (click)="onNextMonth()"><span class="text">Next</span> <span class="icon icon-angle-right"></span></button>
  </div>
  <div class="day-number-holder">
    <div
      *ngFor="let day of monthDays"
      class="day-number"
      [ngClass]="{ 'day-current': day.isToday, 'day-has-multiple': day.event && day.event.length > 1 }"
    >
      <span class="day-number-text">{{ day.number }}</span>
      <div *ngFor="let event of day.event" class="day-event day-event-{{ event.type }}"></div>
      <div class="tool" *ngFor="let event of day.event" class="day-event-{{ event.name }}" >
        <span *ngIf="event.name[0]">{{dataEvent[0].name}}</span>
        <!-- <span *ngIf="event.name[1]">{{event.name[1]}}</span>
        <span *ngIf="event.name[2]">{{event.name[2]}}</span> -->
      </div>
    </div>
  </div>
</div>
