<!-- 
<button class="btn btn-primary" type="button" (click)="isItAll(true)" [disabled]="checking">ALL</button>
<button class="btn btn-primary" type="button" (click)="isItCurated(true)">Curated</button> -->
<!-- <button *ngFor="let buttons of allButtons" class="btn btn-primary" type="button" (click)="switchClick(buttons.click)">{{buttons.Name}}</button> -->



<div class="row sort-by-genre-wrapper">
    <div class="sort-by-header-text">
        Sort By <span style="font-weight: bold;">Genre</span> 
    </div>
</div>
<div class="sort-row">
    <div class="sort-by-buttons-wrapper">
        <div *ngFor="let buttonData of sort1">
            <sort-button [sortButtonData]="buttonData" (clickFilter)="pushingData($event)"></sort-button>
        </div>
    </div>
</div>
<div class="row sort-by-resource-wrapper">
    <div class="sort-by-header-text">
        <span style="font-size: 0.9em;text-decoration: underline;letter-spacing: 2px;">and</span> Sort By <span style="font-weight: bold;">Resource</span> 
    </div>
    <div class="sort-row">
        <div class="sort-by-buttons-wrapper">
            <div *ngFor="let buttonData of resource">
                <sort-button [sortButtonData]="buttonData" (clickFilter)="pushingData($event)" ></sort-button>
            </div>
        </div>
    </div>
</div>
