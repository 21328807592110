import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AddSchool } from 'src/app/model/school';
import { addDistrict,editDistrict } from '../models/district.model';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {

  baseUrl = `${environment.api.remoteServiceBaseUrl}`;
  constructor(private httpClient: HttpClient) {
    
  }

  getDistrict(start:number,perpage:number) {
    const url = `${environment.api.remoteServiceBaseUrl2}/District/all?pageNo=`+start+`&pageSize=`+perpage;
    return this.httpClient.get(url);
  }

  addDistrict(body: addDistrict){
    const url = `${environment.api.remoteServiceBaseUrl2}/District`;
    return this.httpClient.post(url, body);
  }
  
  editDistrict(body: editDistrict){
    const url = `${environment.api.remoteServiceBaseUrl2}/District`;
    return this.httpClient.post(url, body);
  }

  deleteDistrict(id:number){
    const url = `${environment.api.remoteServiceBaseUrl2}/District/`+id;
    return this.httpClient.delete(url);
  }
}
