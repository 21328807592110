import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { ActionType } from 'src/app/model/action-type';
import { ApiResponse } from '../models/api-response.model';
import { HttpCachingService } from './http-caching.service';
import { environment } from '../../../environments/environment';
import { BaseApiService } from './base-service.service';
import { ActionTypeApiSerializer } from '../serializers/action-type-api.serializer';

@Injectable({
  providedIn: 'root'
})

export class ActionTypeApiService extends BaseApiService<ActionType[]> {
  constructor(httpClient: HttpClient, apiSerializer: ActionTypeApiSerializer, httpCachingService: HttpCachingService) {
    super(httpClient, apiSerializer, httpCachingService, 'ActionType');
  }
}

