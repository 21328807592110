import { Component, OnInit } from '@angular/core';
import { CoachService } from '../core/services/coach.service';
import * as XLSX from 'xlsx'
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-twilliomeeting',
  templateUrl: './twilliomeeting.component.html',
  styleUrls: ['../allmeetings/allmeetings.component.scss']
})
export class TwilliomeetingComponent implements OnInit {

  loading = true;
  perpage=10;
  pgTwillioMeeting=1;
  twilliomeetings: Array<any> = [];
  alltwilliomeetings: Array<any> = [];
  fileName: string = "TwillioMeeting";
  twillioname:string= "";

  // Generate Links
  twilliomeetingLinks: number[] = [];
  currentTwillioMeeting: number = 1;

  constructor(private cs: CoachService) { }

  ngOnInit(): void {
    this.cs.getAllTwillioMeetings(this.pgTwillioMeeting,this.perpage,this.twillioname).subscribe((result: any) => {
      this.twilliomeetings = result.data;
      console.log(this.twilliomeetings,"All Connection");
      for (let i = 1; i <= Math.ceil(result.filteredRecords/this.perpage); i++) {
        this.twilliomeetingLinks.push(i);
      }
      setTimeout(()=> { 
        this.pgfix(1);
       }, 1);
    });

    this.cs.getAllTwillioMeetings(this.pgTwillioMeeting,20000,this.twillioname).subscribe((result: any) => {
      this.alltwilliomeetings = result.data;
    });
  }

  getMMDDYYYY(date: any) {
    let str = date.replace("T", " ");
    return str;
  }

  pgfix(current:number){
    $(".links li").each(function(index){
      if(index>current+2 || index<current-3){
        $(".links li").eq(index).attr("style","display:none");        
      }else{
        $(".links li").eq(index).removeAttr("style");
      }
    });
  }

  loadTwillioMeeting(current:number){
    this.currentTwillioMeeting = current;
    this.pgfix(current);
    this.cs.getAllTwillioMeetings(current,this.perpage,this.twillioname).subscribe((result: any) => {
      this.twilliomeetings = result.data;
    }); 
  }

  refreshlist(){
    this.cs.pullRequest().subscribe((result: any) => {
      location.reload();
    }); 
  }

  converttimezone(givenTime:string){
    const localTime = moment.utc(givenTime).local();
    return localTime.format('YYYY-MM-DD HH:mm');
  }

  getTimeZonelabel(){    
    var currentTimezone = moment.tz(moment.tz.guess()).format('z');
    return "("+currentTimezone+")";
  }

  convertTimeToHourandSecond(totalSeconds:number){
    if(totalSeconds<60 && totalSeconds!=0){
      return "00:01";
    }
    const minutes = Math.ceil(totalSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return hours.toString().padStart(2, '0') + ":" + remainingMinutes.toString().padStart(2, '0');
  }

  toHoursAndMinutes(totalMinutes:number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');
  }
  
  exportexcel(): void {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName+".xlsx");
  }

  exportjsontoexcel(filename:string,data:any){
    /* table id is passed over here */   
    let element = document.getElementById('excel-table'); 
    const ws = XLSX.utils.json_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName+".xlsx", { compression: true });
  }

  twilliosearch(){
    let name = "";
    if($(".name").val()!=""){
      this.twillioname = $(".name").val()+"";
    }
    if(this.twillioname!=""){
      this.ngOnInit();
    }
  }

  twillioreset(){
    this.twillioname = "";
    this.ngOnInit();
    $(".name").val("");
  }
}
