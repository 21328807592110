import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionType } from 'src/app/model/action-type';
import { ConfirmationType } from 'src/app/model/confirmation-type';
import { StandardType } from 'src/app/model/standard-type';
import { ActionTypeApiSerializer } from '../serializers/action-type-api.serializer';
import { ConformationTypeApiSerializer } from '../serializers/confirmation-type-api.serializer';
import { StandardTypeApiSerializer } from '../serializers/standard-type-api.serializer';
import { BaseApiService } from './base-service.service';
import { HttpCachingService } from './http-caching.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationTypeApiService extends BaseApiService<ConfirmationType[]> {
  constructor(httpClient: HttpClient, apiSerializer: ConformationTypeApiSerializer, httpCachingService: HttpCachingService) {
    super(httpClient, apiSerializer, httpCachingService, 'confirmationtype');
  }
}
