import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as queryString from 'query-string';
import { ApiResponse } from '../models/api-response.model';
import { BaseApiSerializer } from '../serializers/base-api-serializer';
import { HttpCachingService } from './http-caching.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseApiService<T> {
  protected get baseUrl(): string {
    return `${environment.api.remoteServiceBaseUrl}/${this.endpoint}`;
  }

  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly apiSerializer: BaseApiSerializer<T>,
    protected readonly httpCachingService: HttpCachingService,
    @Inject(String) private readonly endpoint: string
  ) { }

  private getQueryString(queryParams: any): string {
    return queryParams ? `?${queryString.stringify(queryParams)}` : '';
  }

  //#region CONVERT
  protected convertData(response: ApiResponse): T {
    return this.apiSerializer.fromJson(response.result) as T;
  }

  protected convertDataList(response: ApiResponse): Array<T> {
    return response.result.map((result: any) => this.apiSerializer.fromJson(result) as T);
  }
  //#endregion

  //#region CREATE
  public add(item: T): Observable<T> {
    return this.httpClient.post(this.baseUrl, this.apiSerializer.toJson(item)).pipe(map((data: any) => this.convertData(data)));
  }

  //#endregion

  //#region READ
  public get(id: number | string, isInCache = false): Observable<T> {
    const url = `${this.baseUrl}/${id}`;

    if (isInCache) this.httpCachingService.addCachingUrl(url);

    return this.httpClient.get<T>(url).pipe(map((data: any) => this.convertData(data)));
  }

  // Récupère une ressources en fonction des paramètres passés
  public getWithParams(queryParams: any, isInCache = false): Observable<T> {
    const url = `${this.baseUrl}${this.getQueryString(queryParams)}`;

    if (isInCache) this.httpCachingService.addCachingUrl(url);

    return this.httpClient.get<T>(url).pipe(map((data: any) => this.convertData(data)));
  }

  // Récupère une ressources en fonction des paramètres passés
  public getWithPath(queryPath: string, isInCache = false): Observable<T> {
    const url = `${this.baseUrl}/${queryPath}`;

    if (isInCache) this.httpCachingService.addCachingUrl(url);

    return this.httpClient.get<T>(url); // pipe(map((data: any) => this.convertData(data)));
  }

  // Récupère une ou plusieurs ressources en fonction des paramètres passés
  public getManyWithParams(queryParams: any, isInCache = false): Observable<Array<T>> {
    const url = `${this.baseUrl}${this.getQueryString(queryParams)}`;

    if (isInCache) this.httpCachingService.addCachingUrl(url);

    return this.httpClient.get<T[]>(url).pipe(map((data: any) => this.convertDataList(data)));
  }

  public getAll(isInCache = false): Observable<Array<T>> {
    if (isInCache) this.httpCachingService.addCachingUrl(this.baseUrl);

    return this.httpClient.get<T[]>(this.baseUrl); // .pipe(map((data: any) => this.convertDataList(data)));
  }

  //#endregion

  //#region UPDATE
  public update(id: number | string, item: T): Observable<T> {
    return this.httpClient.put(`${this.baseUrl}/${id}`, this.apiSerializer.toJson(item)).pipe(map((data: any) => this.convertData(data)));
  }
  //#endregion

  //#region DELETE
  public delete(id: number | string): Observable<T> {
    return this.httpClient.delete(`${this.baseUrl}/${id}`) as Observable<T>;
  }
  //#endregion
}
