import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

export enum strokeLineCap {
  ROUND = 'round',
  SQUARE = 'square',
  BUTT = 'butt'
}

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss']
})
export class CircularProgressComponent implements OnInit, OnChanges {
  @Input() percentage = 0;
  @Input() width = 200;
  @Input() strokeWidth = 5;
  @Input() strokeLineCap: strokeLineCap = strokeLineCap.ROUND;
  @Input() fontSize = 10;
  @Input() fontColor = 'inherit';
  @Input() fontFamily = 'inherit';
  @Input() primaryColor: Array<string> = ['#00BBFF', '#92d7f1'];
  @Input() secondaryColor = 'transparent';
  @Input() fill = 'transparent';
  @Input() hidePercentageText = true;

  readonly PI = 3.14;
  R = 0;
  circumference = 0;
  offset = 0;
  gradientId = '';
  gradientUrl = '';

  ngOnInit(): void {
    this.R = this.width / 2 - this.strokeWidth * 2;
    this.circumference = 2 * this.PI * this.R;
    this.offset = this.circumference - (this.percentage / 100) * this.circumference;
    this.gradientId = `${this.primaryColor[0]}${this.primaryColor[1]}`.replace(/#/g, '');
    this.gradientUrl = `url(#${this.gradientId})`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && !changes.firstChange && changes.width) {
      this.width = parseInt(changes.width.currentValue, 10);
      this.R = this.width / 2 - this.strokeWidth * 2;
      this.circumference = 2 * this.PI * this.R;
      this.offset = this.circumference - (this.percentage / 100) * this.circumference;
      this.gradientId = `${this.primaryColor[0]}${this.primaryColor[1]}`.replace(/#/g, '');
      this.gradientUrl = `url(#${this.gradientId})`;
    }
  }
}
