import { Component, OnInit } from '@angular/core';
import { CoachService } from '../core/services/coach.service';
import { AllUser } from '../core/models/coach.model';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {UpdateProfile} from 'src/app/core/models/user-profile-response.model';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { UserAttributeService } from 'src/app/core/services/user-attribute.service';
import * as XLSX from 'xlsx'
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-registered-user',
  templateUrl: './registered-user.component.html',
  styleUrls: ['./registered-user.component.scss']
})
export class RegisteredUserComponent implements OnInit {
  loading = true;
  opacity:number=1;
  coach: Array<AllUser> = [];
  Allcoach: Array<AllUser> = [];
  teacher: Array<AllUser> = [];
  Allteacher: Array<AllUser> = [];
  teachersection:boolean = false;
  coachsection:boolean = true;
  coachtab:string = "active";
  teachertab:string = "";
  isEdit=false;
  pgcoach=1;
  pgteacher=1;
  nonewcoach = false;
  nonewteacher = false;
  perpage=10;
  allActiveuser: Array<any> = [];
  aallActiveuser: Array<any> = [];
  coachsearchText: string="";
  teachersearchText:string="";

  // Generate Links
  coachLinks: number[] = [];
  currentCoach: number = 1;

  teacherLinks: number[] = [];
  currentTeacher: number = 1;

  activeUserLinks: number[] = [];
  currentUser: number = 1;

  supports:any=[];

  personalInfoForm = this.fb.group({
    id:'',
    firstName: '',
    lastName: '',
    email: '',
    preferredName: '',
    yearsExperience: '',
    yearsInEducation: '',
    primaryPhone: '',
    availableMinutes: '',
    isActive:'',
    trueColors:'',
    supports:''
  });

  coachsearchForm = this.fb.group({
    coachsearch: ''
  });

  teachersearchForm = this.fb.group({
    teachersearch: ''
  });

  modalCoachName:string = "";
  modalCoachImage:string = "";
  modalyearsOfExperience:string = "";
  modalgrade:string="";
  modalyearsInEducation:string="";
  modalsubjectsTaught:string="";
  modalgradeLevelsTaught:string="";
  modaltypeOfSchool:string="";
  modalprogramsExperience:string="";
  modalavailability:string="";
  is_coach_notfound = false;
  is_teacher_notfound = false;
  is_teacher = false;
  fileName: string = "";

  hidemodal:boolean=true;

  constructor(private fb: UntypedFormBuilder,private cs: CoachService,private toastr: ToastrService, private ups: UserProfileService, private uas: UserAttributeService) { }
  

  ngOnInit(): void {
    this.init();
  }

  open(section:string){
    this.isEdit = false;
    if(section == 'coach'){
      this.teachersection = false;
      this.coachsection = true;
      this.coachtab = "active";
      this.teachertab = "";
    }else{
      this.coachsection = false;
      this.teachersection = true;
      this.teachertab = "active";
      this.coachtab = "";
    }
  }
  onEdit(id:any,firstName:any,lastName:any,email:any,preferredName:any,yearsExperience:any,yearsInEducation:any,primaryPhone:any,availableMinutes:any,role:any,isActive:any,trueColors:any,supports:any){
    this.isEdit = true;
    if(role == 3){
      this.is_teacher = true;
    }else{
      this.is_teacher = false;
    }
    console.log(supports,"SupportsSelected");
    this.personalInfoForm.patchValue({
      id:id,
      firstName: firstName,
      lastName: lastName,
      email:email,
      preferredName: preferredName,
      yearsExperience: yearsExperience,
      yearsInEducation: yearsInEducation,
      primaryPhone: primaryPhone,
      availableMinutes:availableMinutes,
      isActive: isActive,
      trueColors: trueColors,
      supports:supports
    });
  }

  updateRecord(){
    console.log(this.personalInfoForm.get('supports')?.value);
    const ProfileDetails: UpdateProfile = {
      id:this.personalInfoForm.get('id')?.value,
      email:this.personalInfoForm.get('email')?.value,
      firstName:this.personalInfoForm.get('firstName')?.value,
      lastName:this.personalInfoForm.get('lastName')?.value,
      preferredName: this.personalInfoForm.get('preferredName')?.value,
      yearsOfExperience: this.personalInfoForm.get('yearsExperience')?.value,
      yearsInEducation: this.personalInfoForm.get('yearsInEducation')?.value,      
      isActive:this.personalInfoForm.get('isActive')?.value,
      primaryPhone: this.personalInfoForm.get('primaryPhone')?.value,
      availableminutes:this.personalInfoForm.get('availableMinutes')?.value,
      userRole:(this.is_teacher)?3:1,
      timezoneId:'',
      trueColors:this.personalInfoForm.get('trueColors')?.value,
      supports:this.personalInfoForm.get('supports')?.value
    };
    console.log(ProfileDetails,"UpdateSupport");
    this.ups.updateUserProfile(ProfileDetails).subscribe((res)=>{
      setTimeout(()=>{
        window.location.reload();
      }, 1000);
      this.toastr.success(`User Info updated!`, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 2000
      });
    },
    (error) => {
      this.opacity=1;
      this.toastr.error(`User not added`, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      this.loading = false;
    });
  }

  closeForm(){
    this.isEdit = false;
  }

  loadCoach(current:number){
    this.currentCoach = current;
    this.pgfixcoach(current);
    this.cs.getAllUser(current,this.perpage,"coach",this.coachsearchText).subscribe((result: any) => {
      //this.coach = [...this.coach,result.users];
      //this.coach = this.coach.concat(result.data);
      this.coach = result.data;
      
      console.log(this.coach,"CoachData");
      // if(result.loadMore){
      //   this.pgcoach+=1;
      // }else{
      //   this.nonewcoach = true;
      // }
    });

    
  }
  loadTeacher(current:number){
    this.currentTeacher = current;
    this.pgfixteacher(current);
    this.cs.getAllUser(current,this.perpage,"teacher",this.teachersearchText).subscribe((result: any) => {
      this.teacher = result.data;      
    });    
  }

  init(){
    this.cs.getAllUser(this.pgcoach,this.perpage,"coach",this.coachsearchText).subscribe((result: any) => {
      this.coach = result.data;
      console.log(result,"Coach");
      if(this.coachsearchText!=""){
        result.totalRecords = result.filteredRecords;
      }
      this.coachLinks = [];
      for (let i = 1; i <= Math.ceil(result.totalRecords/this.perpage); i++) {
        this.coachLinks.push(i);
      }
      setTimeout(()=> { 
        this.pgfixcoach(1);
       }, 1);
    });
    this.cs.getAllUser(this.pgteacher,this.perpage,"teacher",this.teachersearchText).subscribe((result: any) => {
      this.teacher = result.data;
      console.log(result,"Teachers");
      if(this.teachersearchText!=""){
        result.totalRecords = result.filteredRecords;
      }
      this.teacherLinks = [];
      for (let i = 1; i <= Math.ceil(result.totalRecords/this.perpage); i++) {
        this.teacherLinks.push(i);
      }
      setTimeout(()=> { 
        this.pgfixteacher(1);
       }, 1);
    }); 

    this.cs.getActiveUser(1,10).subscribe((result: any) => {
      this.allActiveuser = result.data;
      console.log(result,"Active User");
      this.activeUserLinks = [];
      for (let i = 1; i <= Math.ceil(result.totalRecords/this.perpage); i++) {
        this.activeUserLinks.push(i);
      }
      setTimeout(()=> { 
        this.pgfixactiveuser(1);
       }, 1);
    }); 

    this.cs.getAllUser(1,20000,"coach",this.coachsearchText).subscribe((result: any) => {
      this.Allcoach = result.data;      
      console.log(this.coach,"CoachData");      
    });

    this.cs.getAllUser(1,20000,"teacher",this.teachersearchText).subscribe((result: any) => {
      this.Allteacher = result.data;      
    });

    this.cs.getActiveUser(1,20000).subscribe((result: any) => {
      this.aallActiveuser = result.data;
    }); 

    this.uas.getSupport().subscribe((result: any) => {
      this.loading = false;
      this.supports = result;
      console.log(this.supports,"Supports")
    });
  }

  pgfixcoach(current:number){
    $(".coachsection .links li").each(function(index){
      if(index>current+2 || index<current-3){
        $(".coachsection .links li").eq(index).attr("style","display:none");        
      }else{
        $(".coachsection .links li").eq(index).removeAttr("style");
      }
    });
  }

  pgfixteacher(current:number){
    $(".teachersection .links li").each(function(index){
      if(index>current+2 || index<current-3){
        $(".teachersection .links li").eq(index).attr("style","display:none");        
      }else{
        $(".teachersection .links li").eq(index).removeAttr("style");
      }
    });
  }

  pgfixactiveuser(current:number){
    $(".activeusersection .links li").each(function(index){
      if(index>current+2 || index<current-3){
        $(".activeusersection .links li").eq(index).attr("style","display:none");        
      }else{
        $(".activeusersection .links li").eq(index).removeAttr("style");
      }
    });
  }

  loadActiveUser(current:number){
    this.currentUser = current;
    this.pgfixactiveuser(current);
    this.cs.getActiveUser(current,this.perpage).subscribe((result: any) => {
      //this.teacher = this.teacher.concat(result.data);
      this.allActiveuser = result.data;
      // if(result.loadMore){
      //   this.pgteacher+=1;
      // }else{
      //   this.nonewteacher = true;
      // }
    });     
  }
  getMMDDYYYY(date: any) {
    let str = date.replace("T", " ");
    const dateTime = new Date(str);
    dateTime.setSeconds(0);
    const updatedDateTimeString = dateTime.toISOString().slice(0, 16).replace('T', ' ');
    return updatedDateTimeString;
  }
  coachsearch(){
    console.log("HelloUser","search")
    this.coachsearchText = this.coachsearchForm.get('coachsearch')?.value;
    console.log(this.coachsearchForm.get('coachsearch')?.value+"==","seach");
    this.init();
  }
  teachersearch(){
    this.teachersearchText = this.teachersearchForm.get('teachersearch')?.value;
    console.log(this.teachersearchText+"==","seach");
    this.init();
  }

  showprofile(index:number,part:number){
    let id = "";
    if(part == 1){
      id = ""+this.coach[index].id;
    }else{
      id = ""+this.teacher[index].id;
    }

    this.hidemodal=false;
    this.loading = true;
    this.modalCoachName = "";
    this.modalCoachImage = "";
    this.modalyearsOfExperience = "";
    this.modalgrade="";
    this.modalyearsInEducation="";
    this.modalsubjectsTaught="";
    this.modalgradeLevelsTaught="";
    this.modaltypeOfSchool="";
    this.modalprogramsExperience="";
    this.modalavailability="";
    this.cs.getFullProfile(id).subscribe((result: any) => {
      this.loading = false;
      this.modalCoachName = result.firstName+" "+result.lastName;
      this.modalCoachImage = result.userImages[0].imageUrl;
      this.modalyearsOfExperience = (result.yearsOfExperience!=null)?result.yearsOfExperience:"";
      this.modalgrade=(result.grades!=null)?result.grades.toString():"";
      this.modalyearsInEducation=(result.yearsInEducation!=null)?result.yearsInEducation.toString():"";
      this.modalsubjectsTaught=(result.subjectsTaught!=null)?result.subjectsTaught.toString():"";
      this.modalgradeLevelsTaught=(result.gradeLevelsTaught!=null)?result.gradeLevelsTaught.toString():"";
      this.modaltypeOfSchool=(result.typeOfSchool!=null)?result.typeOfSchool.toString():"";
      this.modalprogramsExperience=(result.programsExperience!=null)?result.programsExperience.toString():"";
      this.modalavailability=(result.availability!=null)?result.availability.toString():"";
      this.hidemodal=true;
    })
  }

  converttimezone(givenTime:string){
    const localTime = moment.utc(givenTime).local();
    return localTime.format('YYYY-MM-DD HH:mm');
  }

  getTimeZonelabel(){    
    var currentTimezone = moment.tz(moment.tz.guess()).format('z');
    return "("+currentTimezone+")";
  }

  exportjsontoexcel(filename:string,data:any){
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, filename+".xlsx", { compression: true });
  }

  exportexcel(filename:string,id:string): void {
    /* table id is passed over here */   
    let element = document.getElementById(id); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, filename+".xlsx");
  }
}