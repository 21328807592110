import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UserTask } from 'src/app/model/user-task';

@Component({
  selector: 'app-user-task',
  templateUrl: './user-task.component.html',
  styleUrls: ['./user-task.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserTaskComponent implements OnInit {

  @Input() userTasks: Array<UserTask> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
