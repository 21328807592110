import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation,OnChanges } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { format } from 'date-fns';
import { InteractionStatus } from '@azure/msal-browser';
import { UserProfileService } from '../core/services/user-profile.service';
import { ProfileImage,User } from 'src/app/core/models/user-profile-response.model';
import { CoachService } from '../core/services/coach.service';
import { GetMeetingInfo } from '../core/models/coach.model';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy,OnChanges {
  accountName = '';
  fName = '';
  userProfile!: User;
  userId: string = '';
  profileImages!: ProfileImage[];
  today = format(new Date(), 'MMM d');


  NotificationData: Array<GetMeetingInfo> = [];
  NotificationCount: number = 0;
  notificationshow: boolean = false;

  isMentor: boolean = false;
  
  @Input() loggedIn: boolean = false;
  @Output() loginClicked = new EventEmitter<void>();
  @Output() logoutClicked = new EventEmitter<void>();

  constructor(private ups: UserProfileService, private cs:CoachService) {}

  logout() {
    this.logoutClicked.emit();
  }
  
  ngOnChanges():void{
    if(this.loggedIn){
      this.ups.getUserProfile().subscribe((result: any) => {
        this.userProfile = result.userProfile;
        this.userId = result.userId;
        this.profileImages = result.profileImaages.sort((a: ProfileImage, b: ProfileImage) => a.imageLocation - b.imageLocation);
        
        this.cs.getMeetingsForNotification().subscribe((all: any) => {
          this.NotificationData = all
          this.NotificationData.forEach((item,index)=>{
            if(item.inviteAccepted == true){
              this.NotificationCount++;
            }
          });
          console.log(this.NotificationCount, 'notification');
        });
      });
    }
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {}

  onLogin() {
    this.loginClicked.emit();
  }

  showNotification(){
    console.log("CLicked");
    this.notificationshow = !this.notificationshow;
    return false;
  }

  getMMDDYYYY(date: string) {
    let str = date.replace("T", " ");
    return str.substring(0,str.length-3);
  }

  converttimezone(givenTime:any){
    const localTime = moment.utc(givenTime).local();
    console.log(givenTime+" "+localTime.format('YYYY-MM-DD HH:mm'))
    return localTime.format('YYYY-MM-DD HH:mm');
  }

  getTimeZonelabel(){    
    var currentTimezone = moment.tz(moment.tz.guess()).format('z');
    return "("+currentTimezone+")";
  }

  acceptRequest(meetingid:string){
    
  }
}
