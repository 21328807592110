<div class="container w-100">
    <div class="card w-100" style="padding:20px">
        <h2>All Connections</h2>
        <div style="text-align: right;padding-bottom: 10px;">
            <button class="btn btn-success" (click)="exportjsontoexcel('AllConnections',allconnection)" style="margin-left:10px;background-color: #198754;width:auto;"><i class="fa fa-download"></i>&nbsp; Export To Excel</button>
        </div>
        <div style="margin-bottom: 10px;display:flex;width:500px;justify-content: end;">
            <input type="text" placeholder="Enter Coach/Teacher Name" class="name form-control" />
            <button class="btn btn-primary" style="background-color: #aa97d8; border-color: #aa97d8; padding: 5px 20px;margin-left: 10px;" (click)="search()">Search</button>
            <button class="btn btn-primary" style="background-color: #d89797; border-color: #d89797; padding: 5px 20px;margin-left: 10px;" (click)="reset()">Reset</button>
        </div>
        <table class="table table-bordered table-striped" id="connection-table">
            <thead>
                <tr>
                    <th>Coach</th>
                    <th>Teacher</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Created On</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of connection; let i = index">
                    <td>{{ item.mentor }}</td>
                    <td>{{ item.mentee }}</td>
                    <td>{{ item.inviteStatus }}</td>
                    <td>{{ item.reason }}</td>
                    <td>{{ converttimezone(item.createdOn)+" "+ getTimeZonelabel() }}</td>
                </tr>
            </tbody>
        </table>
        <div class="links">
            <ul>
                <li *ngFor="let no of connectionLinks" (click)="loadConnection(no)" class="{{ (currentConnection==no)?'active':'' }}">
                    {{no}}
                </li>
            </ul>
        </div>
    </div>
</div>