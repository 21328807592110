import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Standard } from '../models/standard.model';
import { StandardApiSerializer } from '../serializers/standard-api.serializer';
import { BaseApiService } from './base-service.service';
import { HttpCachingService } from './http-caching.service';

@Injectable({
    providedIn: 'root'
})
export class StandardsApiService extends BaseApiService<Standard[]> {
    constructor(httpClient: HttpClient, apiSerializer: StandardApiSerializer, httpCachingService: HttpCachingService) {
        super(httpClient, apiSerializer, httpCachingService, 'standards');
    }
}