import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { environment } from '../../../environments/environment';
import { initialUserState, userReducer, UserState } from './user.reducer';
import { initialLayoutState, layoutReducer, LayoutState } from './layout.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { storeLogger } from 'ngrx-store-logger';
import { SessionActionTypes } from '../../core/facades/session.actions'
import { initialGoalState } from 'src/app/team/store/reducers/goals.reducer';
import { initialAssessmentState } from 'src/app/team/store/reducers/assessments.reducer';
import { initialGoalUserState } from 'src/app/team/store/reducers/user-assessments.reducer';

export interface AppState {
  user: UserState;
  layout: LayoutState;
  router: RouterReducerState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: userReducer,
  router: routerReducer,
  layout: layoutReducer
};

export const storageSyncReducer = localStorageSync({
  keys: ['user', 'layout', 'workroom'],
  rehydrate: true
});

export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return storeLogger()(reducer);
}

export function clearState(reducer: any) {
  return function (state: any, action: any) {
    if (action.type === SessionActionTypes.clear.type) {
      const newState = {
        ...state,
        user: initialUserState,
        layout: initialLayoutState,
        workroom: {
          ...state.workroom,
          goals: initialGoalState,
          assessments: initialAssessmentState,
          userAssessments: initialGoalUserState
        }
      };
      return reducer(newState, action);
    } else {
      return reducer(state, action);
    }
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [storeFreeze, storageMetaReducer, clearState] : [clearState];
metaReducers.push(logger);
