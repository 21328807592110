<div class="super-progressbar__base" [ngStyle]="{ height: width + 'px', width: width + 'px' }">
  <div *ngIf="!hidePercentageText" class="super-progressbar__percentage-container">
    <span class="super-progressbars__percentage" [ngStyle]="{ 'font-size': fontSize + 'px', 'font-family': fontFamily, color: fontColor }"
      >{{ percentage }}%</span
    >
  </div>
  <svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient [attr.id]="gradientId" [attr.x1]="'0%'" [attr.y1]="'0%'" [attr.x2]="'100%'" [attr.y2]="'0'">
        <stop [attr.offset]="'0%'" [attr.stop-color]="primaryColor[0]" [attr.stop-opacity]="1" />
        <stop [attr.offset]="'100%'" [attr.stop-color]="primaryColor[0]" [attr.stop-opacity]="1" />
      </linearGradient>
    </defs>
    <circle
      [attr.stroke-width]="strokeWidth"
      [attr.fill]="'transparent'"
      [attr.r]="R"
      [attr.cx]="width / 2"
      [attr.cy]="width / 2"
      [attr.stroke]="secondaryColor"
      [attr.stroke-dasharray]="circumference + ', ' + circumference"
    />
    <circle
      class="styled"
      [attr.stroke-width]="strokeWidth"
      [attr.fill]="fill"
      [attr.r]="R"
      [attr.cx]="width / 2"
      [attr.cy]="width / 2"
      [attr.stroke]="gradientUrl"
      [attr.stroke-linecap]="strokeLineCap"
      [attr.stroke-dasharray]="circumference + ', ' + circumference"
      [attr.stroke-dashoffset]="offset"
    />
  </svg>
</div>
