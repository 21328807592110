import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SessionFacade } from "../facades/session.facade";
import { map, switchMap, catchError, mergeMap, take } from 'rxjs/operators';
import * as SessionActions from '../facades/session.actions';
import { UserActionTypes } from "src/app/store/actions/user.action";

@Injectable()
export class SessionEffects {
  /*init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.init),
      take(1),
      switchMap((userInfo) => [UserActionTypes.setUserID(userInfo)])
    )
  );*/

  constructor(
    private actions$: Actions,
    private session: SessionFacade,
  ) {}
}