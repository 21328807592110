export enum EventType {
  TASK,
  GOAL,
  TODO
}

export interface Event {
  name: string;
  type: EventType;
  date: Date;
}
