<div class="doughnut-chart-wrapper">
    <!-- IF Chart type = 1, Display this -->
    <div class="doughnut-chart-canvas-wrapper">
        <canvas [attr.id]="chartID" width="150" height="150"></canvas>
    </div>
    <div class="doughnut-chart-legend">
        <div class="chart-legend-wrapper">
            <div class="chart-one-legend" style="color:{{  item.color  }}"
                *ngFor="let item of mainData.chartDisplayData">
                {{item.title}} {{ item.value/numberOfFiles | percent }}
            </div>
        </div>
    </div>
    <!-- END IF -->
</div>