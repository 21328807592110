import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {
  ContactMethod,
  ContentArea,
  DegreeType,
  Grade,
  MultiInfo,
  Pronoun,
  ResearchTopic,
  User,
  Programsexperiences,
  TypeOfSchool,
  Subjectstaughts,
  Gradeleveltaughts,
  Availabilities,
  AddnewUser,
  School
} from 'src/app/core/models/user-profile-response.model';
import { UserAttributeService } from 'src/app/core/services/user-attribute.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';

@Component({
  selector: 'app-add-coach',
  templateUrl: './add-coach.component.html',
  styleUrls: ['./add-coach.component.scss']
})
export class AddCoachComponent implements OnInit, OnChanges {
  @Input() userProfile!: User;
  loading = true;

  degreeTypes: DegreeType[] = [];
  researchTopics: ResearchTopic[] = [];
  contactMethods: ContactMethod[] = [];
  contentAreas: ContentArea[] = [];
  availibilities: Availabilities[] = [];
  pronounTypes: Pronoun[] = [];
  grades: Grade[] = [];
  
  Programsexperiences:Programsexperiences[]=[];
  specializations: any = [];
  TypeOfSchool:TypeOfSchool[]=[];
  Subjectstaughts:Subjectstaughts[]=[];
  Gradeleveltaughts:Gradeleveltaughts[]=[];
  
  selectedContactMethods: ContactMethod[] = [];
  selectedContentAreas: ContentArea[] = [];
  selectedResearchTopics: ResearchTopic[] = [];
  selectedAvailabilities: Availabilities[] = [];
  selectedGrades: Grade[] = [];
  selectedPronoun: any;
  selectedDegreeType: any;
  selectedSubjectsTaughtIds: any;
  selectedGradeLevelsTaughtIds: any;
  selectedTypeOfSchoolIds: any;
  selectedSchoolIds: any;
  selectedProgramExperienceIds: any;

  allState: any=[];
  School: School[] = [];

  supports: any = [];

  opacity:number=1;

  userRole: any = [
    { 'id':1,'role':'Coach'},
    { 'id':2,'role':'Admin'},
    { 'id':3,'role':'Teacher'}
  ];

  personalInfoForm = this.fb.group({
    email: [''],
    password: [''],
    firstName: [''],
    lastName: [''],
    State:[null],
    School:[null],
    userrole: [null],
    yearsExperience: [''],
    yearsineducation: [''],
    grades: [[]],
    availabilities: [[]],
    SubjectsTaught: [[]],
    GradeLevelsTaught: [[]],
    TypeOfSchoolIds: [[]],
    ProgramExperienceIds: [[]],
    supports:[[]]
  });

  constructor(private fb: UntypedFormBuilder, private uas: UserAttributeService, private ups: UserProfileService, private toastr: ToastrService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.patchUserProfile();
  }
  ngOnInit(): void {
    this.uas
      .getGrades()
      .toPromise()
      .then((result) => {
        this.grades = result;
        this.patchUserProfile();
      });
    
    this.uas
      .getAvailavilities()
      .toPromise()
      .then((result) => {
        this.availibilities = result;
        this.patchUserProfile();
      });  
    
    this.uas
      .getSubjectstaughts()
      .toPromise()
      .then((result) => {
        this.Subjectstaughts = result;
        this.patchUserProfile();
      });

    this.uas
      .getGradeleveltaughts()
      .toPromise()
      .then((result) => {
        this.Gradeleveltaughts = result;
        this.patchUserProfile();
      });

    this.uas
      .getTypeOfSchool()
      .toPromise()
      .then((result) => {
        this.TypeOfSchool = result;
        console.log(this.TypeOfSchool,"111");
        this.patchUserProfile();
      });

    this.uas
      .getSpecialization()
      .toPromise()
      .then((result) => {
        this.specializations = result;
        this.patchUserProfile();
      });   
      
    /*this.uas
      .getProgramExperiences()
      .toPromise()
      .then((result) => {
        this.Programsexperiences = result;
        this.patchUserProfile();
      });*/

    this.uas.getState().subscribe((result: any) => {
      this.loading = false;
      this.allState = result;
    });

    this.uas.getSupport().subscribe((result: any) => {
      this.loading = false;
      this.supports = result;
    });
   

    this.loading = false;
  }

  patchUserProfile() {
    if (this.userProfile) {
      if (this.grades)
        this.selectedGrades = this.userProfile.userGrades.reduce((acc: any, ug) => {
          return acc.concat(this.grades.find((g) => g.id === ug.gradeId));
        }, []);
      if (this.availibilities)
         this.selectedAvailabilities = this.userProfile.userAvailabilities.reduce((acc: any, uab) => {
            return acc.concat(this.availibilities.find((ab) => ab.availabilityId === uab.availabilityId));
         }, []);
      if (this.Subjectstaughts)
         this.selectedSubjectsTaughtIds = this.userProfile.SubjectsTaughtIds.reduce((acc: any, uab) => {
            return acc.concat(this.Subjectstaughts.find((ab) => ab.subjectsTaughtId === uab.subjectsTaughtId));
         }, []);       
      if (this.Gradeleveltaughts)
         this.selectedGradeLevelsTaughtIds = this.userProfile.GradeLevelsTaughtIds.reduce((acc: any, uab) => {
            return acc.concat(this.Gradeleveltaughts.find((ab) => ab.gradeLevelsTaughtId === uab.gradeLevelsTaughtId));
         }, []);
      if (this.TypeOfSchool)
         this.selectedTypeOfSchoolIds = this.userProfile.TypeOfSchoolIds.reduce((acc: any, uab) => {
            return acc.concat(this.TypeOfSchool.find((ab) => ab.typeOfSchoolId === uab.typeOfSchoolId));
         }, []);
      if (this.School)
         this.selectedSchoolIds = this.userProfile.userSchools.reduce((acc: any, uab) => {
            return acc.concat(this.School.find((ab) => ab.id === uab.id));
         }, []);   
      if (this.Programsexperiences)
         this.selectedAvailabilities = this.userProfile.ProgramExperienceIds.reduce((acc: any, uab) => {
            return acc.concat(this.Programsexperiences.find((ab) => ab.programsExperienceId === uab.programsExperienceId));
         }, []);

      this.personalInfoForm.patchValue({
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
        preferredName: this.userProfile.preferredName,
        phoneNumber: this.userProfile.primaryPhone,
        preferredPronoun: this.selectedPronoun ?? null,
        grades: this.selectedGrades,
        contentAreas: this.selectedContentAreas,
        yearsExperience: this.userProfile.yearsOfExperience,
        highestDegreeEarned: this.selectedDegreeType ?? null,
        areaOfInterest: this.selectedResearchTopics,
        contactMethods: this.selectedContactMethods,
        availabilities: this.selectedAvailabilities,
        TypeOfSchoolIds:'',
        supports:""
      });
    }
  }

  SaveProfile(){

    this.opacity=0.5;

    const email = this.personalInfoForm.get('email')?.value;
    const password = this.personalInfoForm.get('password')?.value;
    const FirstName = this.personalInfoForm.get('firstName')?.value;
    const LastName = this.personalInfoForm.get('lastName')?.value;
    const RoleId = this.personalInfoForm.get('userrole')?.value;
    const schoolId = this.personalInfoForm.get('School')?.value;
    const YearsOfExperience = this.personalInfoForm.get('yearsExperience')?.value;
    const YearInEducation = this.personalInfoForm.get('yearsineducation')?.value;
    const Grades: MultiInfo = {
      ids: this.personalInfoForm.get('grades')?.value.map((g: Grade) => g.id)
    };
    const Availabilities: MultiInfo = {
      ids: this.personalInfoForm.get('availabilities')?.value.map((ab: Availabilities) => ab.availabilityId)
    };
    const SubjectsTaught: MultiInfo = {
      ids: this.personalInfoForm.get('SubjectsTaughtIds')?.value.map((rt: Subjectstaughts) => rt.subjectsTaughtId)
    };
    const GradeLevelsTaught: MultiInfo = {
      ids: this.personalInfoForm.get('GradeLevelsTaught')?.value.map((ca: Gradeleveltaughts) => ca.gradeLevelsTaughtId)
    };
    const TypeOfSchoolIds: MultiInfo = {
      ids: this.personalInfoForm.get('TypeOfSchoolIds')?.value.map((si: TypeOfSchool) => si.typeOfSchoolId)
    };
    const ProgramExperienceIds: MultiInfo = {
      ids: this.personalInfoForm.get('ProgramExperienceIds')?.value.map((ca: Programsexperiences) => ca.programsExperienceId)
    };
    
    let supports = this.personalInfoForm.get('supports')?.value;
    let state = this.personalInfoForm.get('State')?.value;

    const AddnewUser: AddnewUser = {
      email: email,
      Password: password,
      SchoolId: schoolId,
      FirstName: FirstName,
      LastName: LastName,
      RoleId: RoleId,
      YearsOfExperience: YearsOfExperience,
      YearsInEducation: YearInEducation,
      GradeIds: Grades.ids,
      AvailabilityIds: Availabilities.ids,
      SubjectsTaughtIds: SubjectsTaught.ids,
      GradeLevelsTaughtIds: GradeLevelsTaught.ids,
      TypeOfSchoolIds: this.personalInfoForm.get('TypeOfSchoolIds')?.value,
      ProgramExperienceIds: ProgramExperienceIds.ids,
      supportIds:supports,
      stateId:state    
    };
    
    this.ups.addNewUser(AddnewUser).subscribe((res:any)=>{
      console.log(AddnewUser,"1111");
      this.opacity=1;
      console.log(res.success);
      if(res.success){
        this.toastr.success(res.message, undefined, {
          positionClass: 'toast-top-right',
          timeOut: 5000
        });
        setTimeout(()=>{
          window.location.reload();
        }, 1000);
      }else{
        this.toastr.error(res.message, undefined, {
          positionClass: 'toast-top-right',
          timeOut: 5000
        });
      }
      
      this.loading = false;
    },
    (error) => {
      this.opacity=1;
      this.toastr.error(`User not added`, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
      this.loading = false;
    });

    
  }

  onStateChange(){
    var state = this.personalInfoForm.get('State')?.value;
    this.uas.getSchoolByState(state).subscribe((result: any) => {
      this.loading = false;
      this.School = result;
      
    });
  }
}
