import { Component, OnInit } from '@angular/core';
import { ChatService } from '../core/chat.service';
import { ToastrService } from 'ngx-toastr';
import { CoachService } from '../core/services/coach.service';
import { ImageInfo } from 'src/app/core/models/user-profile-response.model';
import { toBase64Encoded } from '../shared/utility';
import { UserProfileService } from '../core/services/user-profile.service';
import * as Ably from 'ably';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-chatdemo',
  templateUrl: './chatdemo.component.html',
  styleUrls: ['./chatdemo.component.scss']
})
export class ChatdemoComponent implements OnInit {

  message:any = [];
  userProfile:any = [];
  userid:string = "";
  channel:any;
  currentId:string = "";
  users:any = [];
  channelId:string = "";
  client:any;
  activeUser:number = -1;
  currentusername:number = 0;
  receiverusername:number = 0;
  chaton = false;
  preview = false;
  messagetext = "";
  senderNumber = (localStorage.getItem("userNumber")!=null)?localStorage.getItem("userNumber"):0;
  unreadmessages:any = [];
  countmessages:any = [];
  currentappointmentid:number = 0;
  currentmenteeId:string = "";
  currentname:string = "";
  isload:boolean = false;

  constructor(private chat: ChatService, private toastr: ToastrService,private ups: UserProfileService,cs: CoachService,private router: ActivatedRoute) {

    cs.getAllAppointment().subscribe((result:any)=>{
      this.users = result;      

      if(this.users.length > 0){
        const chatId = this.router.snapshot.paramMap.get('id');
        if(chatId!=null){
          this.initchat(this.users[chatId].requestUser.userNumber,parseInt(chatId));
        }
        this.preview = true;
        this.messagetext = "To start a chat, please choose a user from the left-hand navigation panel.";
      }else{
        this.preview = false;
        this.messagetext = "Your inbox is empty";
      }

      // Get Unread messages
      chat.getUnreadMessageCount(this.senderNumber+"").subscribe((result:any)=>{
        this.unreadmessages = result;
        console.log(result,"debug");  
        this.users.forEach((item:any)=>{
          this.unreadmessages.forEach((item2:any)=>{
            console.log(item2.userNumber + " == "+item.requestUser.userNumber,"debug");
            if(item2.userNumber == item.requestUser.userNumber){
              this.countmessages.push(item2.unreadMessageCount);
            }else{
              this.countmessages.push(0);
            }
          })
          console.log(this.countmessages,"debug2")
        })      
      })
    })    

    this.ups.getUserProfile().subscribe((result: any) => {
      this.userProfile = result;
      this.currentId = this.userProfile.userId;
      this.currentusername = this.userProfile.userProfile.userNumber;
      this.currentname = this.userProfile.userProfile.firstName+" "+this.userProfile.userProfile.lastName;
      console.log(this.currentname,"nameis");
      //console.log(this.userProfile.userProfile.userNumber,"ably");
    });
  }

  ngOnInit(): void {
    this.client = new Ably.Realtime({key: 'EfXV3A.-hrF8g:9w2-PWcrXAeRQ5R-Wr4oR6T0jhNEccDys435V7ZkGTA'});
    this.client.connection.on('connected', function() {
      console.log("connected","ably");
    });
    this.client.connection.on('failed', function() {
      console.log("failed","ably");
    });
  }

  sendmessage(receiverId:number):void {
    var msg = $("#message").val();
    if(msg!=""){
      this.channel.publish(this.currentusername+"", msg);
      console.log(this.currentusername,"ably");

      this.chat.sendMessage(receiverId,msg+"","","").subscribe((result: any) => {
        if(result.success){
          $("#message").val("");
          $("#message").focus();
        }
      });
    }else{
      alert("Please enter some message");
    }
  }

  getmessage(receiverId:number):any {
    setTimeout(() => {
      //this.chat.unreadMessage();
      // this.chat.unreadMessage2(receiverId+"",this.senderNumber+"").subscribe((result:any)=>{
      //   console.log("Hi","unread2");
      // });
      this.chat.getMessage(receiverId).subscribe((result: any) => {
        this.isload = false;
        this.message = result; 
        setTimeout(() => {
          var resultArea = document.getElementById("chatbox");
          if(resultArea!=null)
            resultArea.scrollTop = resultArea.scrollHeight; 
        },200);
      });
    },900);        
  }

  initchat(receiverId:number,index:number){
    this.isload = true;
    this.message = [];
    this.countmessages = [];
    this.chaton = true;
    this.activeUser = index;
    this.receiverusername = receiverId;
    this.currentappointmentid = this.users[index].appointmentId;
    this.currentmenteeId = this.users[index].menteeId;
    //this.currentname = this.users[index].requestUser.firstName+" "+this.users[index].requestUser.lastName

    this.chat.unreadMessage2(receiverId+"",this.senderNumber+"").subscribe((result:any)=>{
      console.log("Hi","unread2");
    });

    this.channelId = (this.users[index].menteeId).replaceAll("-","")+"_"+(this.users[index].mentorId).replaceAll("-","");
    //this.channelId = "user1_user2";

    console.log(this.channelId,"ably");
    
    this.getmessage(this.receiverusername);
    
    this.channel = this.client.channels.get(this.channelId);
    console.log(this.channelId,"debug");
    this.userid = ""+receiverId;

    this.channel.subscribe((message:any)=> {
      console.log("Subcribe Call","ably");
      console.log(message,"ably");
      if(this.senderNumber!=0 && receiverId!=0){
        this.chat.unreadMessage2(receiverId+"",this.senderNumber+"").subscribe((result:any)=>{
          console.log("Hi","unread2");
        });
      }
      this.getmessage(this.receiverusername)
    });
    //this.getmessage(receiverId);
  }

  async handleFile(element: any, imageLocation: number,receiverId:number) {
    const file: File = element.target.files[0];
    const filesize = (file.size/1024)/1024;
    var idxDot = file.name.lastIndexOf(".") + 1;
    var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
    var flg = true
    if(filesize>=10){
      alert("Please select file less than 10MB");
      flg = false;
    }

    // if(extFile!="jpg" && extFile!="png" && extFile!="svg" && extFile!="jpeg" && extFile!="gif"){
    //   alert("Please select only image file(jpg,png,gif,svg)");
    //   flg = false;
    // }

    if(flg){
      console.log(filesize,"filesize");
      const base64EncodedData: any = await toBase64Encoded(file);
      const ImageInfo: ImageInfo = {
        base64EncodedData,
        fileName: file.name,
        imageLocation
      };
      console.log(base64EncodedData,"file");
      console.log(ImageInfo,"file");

      var msg = $("#message").val();
      this.channel.publish(this.currentusername+"", msg+ImageInfo.fileName);
      this.chat.sendMessage(receiverId,msg+"",ImageInfo.base64EncodedData+"",ImageInfo.fileName+"").subscribe((result: any) => {
        if(result.success){
          $("#message").val("");
          this.getmessage(receiverId);
        }
      });
    }
  }

  checkcount(user:number){
    console.log("CheckCount "+user,"debug");
    this.unreadmessages.forEach((item:any)=>{
      console.log(user +"=="+ item.userNumber,"debug");
      if(user == item.userNumber){
        console.log(item.unreadMessageCount,'debug');
        return item.unreadMessageCount;
      }else{
        return "";
      }
    });
  }

  getMMDDYYYY(date: any) {
    let str = date.replace("T", " ");
    let str2 = str.split(".");
    return str2[0];
  }

  InstantRequestMeeting(){
    const localtime = moment.utc().format().toString().replace("Z","");
    const currentTimezone = moment.tz(moment.tz.guess()).format('z');
    
    const data = {
      "menteeId":this.currentmenteeId,
      "appointmentId":this.currentappointmentid,
      "dateTime":localtime,
      "TimeZoneId":currentTimezone
    };
    
    try{
      this.chat.instantRequestMeeting(data).subscribe((result:any)=>{
        if(result.success){
          this.currentappointmentid = result.data.requestMeetingId;
          console.log(this.currentappointmentid,"id");
          if(result.message!=""){
            this.toastr.success(result.message, undefined, {
              positionClass: 'toast-top-right',
              timeOut: 5000
            });
          }else{
            $("#message").val("https://ribbitmeetings.azurewebsites.net/room/"+this.currentappointmentid+"/name/"+this.currentusername);
            $("#sendbtn").click();
            this.toastr.success("Instant Meeting Created", undefined, {
              positionClass: 'toast-top-right',
              timeOut: 5000
            });
          }
        }      
      });
    }catch(error){
      this.toastr.error(`Meeting Not Created`, undefined, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });
    }  
  }

  replacestring(message:string){
    if(message.search("https://ribbitmeetings.azurewebsites.net/room/")!=-1){
      let array = message.split("/");
      let url = message.replace(array[array.length-1],"")+this.currentname;
      return "<a href='"+url+"' target='_blank'>"+url+"</h1>"; 
    }else{
      return message;
    }
  }
}