import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { forgotpassword, login, resetpassword } from '../core/models/login';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) { 
    
  }
  
  checklogin(body: login) {
    const url = `${environment.api.remoteServiceBaseUrl2}/token`;
    return this.httpClient.post(url,body);
  }

  sendLink(body: any) {
    const url = `${environment.api.remoteServiceBaseUrl2}/SendResetPasswordEmail`;
    return this.httpClient.post(url,body,{ responseType: 'text' });
  }

  ResetPassword(body: resetpassword) {
    const url = `${environment.api.remoteServiceBaseUrl2}/ResetPassword`;
    return this.httpClient.post(url,body,{ responseType: 'text' });
  }
}
