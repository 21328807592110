import { createAction, props } from '@ngrx/store';
import { LevelTwoStandard } from 'src/app/core/models/leveltwo-standard.model';
import { Standard } from 'src/app/core/models/standard.model';
import { SubIndicator } from 'src/app/core/models/sub-indicator.model';
import { Framework } from 'src/app/model/framework';
import { UserRating } from 'src/app/model/user-rating';

export const noAction = createAction('[User Assessments] No Action');
export const setSelectedFramework = createAction('[User Assessments] Set Selected Framework', props<{ selectedFramework: Framework }>());
export const setAreaOfFocus = createAction('[User Assessments] Set Area Of Focus', props<{ areaOfFocus: Array<Standard | null> }>());
export const setRating = createAction('[User Assessments] Set Rating', props<{ score: UserRating }>());
export const setAreaOfImprovements = createAction(
  '[User Assessments] Set Area Of Improvement',
  props<{ areaOfImprovement: Array<LevelTwoStandard | null> }>()
);
export const setCurrentQuestion = createAction('[User Assessments] Set Current Question', props<{ question: number }>());
export const resetUserScore = createAction('[User Assessments] Reset User Score');

export const UserAssessmentActionTypes = {
  noAction,
  setSelectedFramework,
  setAreaOfFocus,
  setRating,
  setAreaOfImprovements,
  setCurrentQuestion,
  resetUserScore
};
