/**
 * Converts file to base64Encoded string and return a promise
 * @param file
 */
export const toBase64Encoded = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString().split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
