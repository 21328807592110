import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { TitleStrategy } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PlansService {



  // the property of "plans" is used in the file-folder/components/list-drag Component
  plans: any = [];
  kPlans: any = [];
  publicPlans: any = [];
  postId: any;
  errorMessage: any;

  sortButtonData: any = {
    chartDisplayType: 0,
    id: "",
    data: [
      {
        title: "All",
        value: "95",
        color: "black",
        color2: "blue",
        displayTitle: "",
        css: "normal-button",
        background: "linear-gradient(to right,#c19007,#005da0, #6b1da5)",
        isActive: true,
        click: 'All',
        filterType: 'genre'
      },
      {
        title: "Latest",
        value: "4",
        color: "#999999",
        background: "#999999",
        displayTitle: "",
        css: "normal-button",
        isActive: false,
        click: 'Latest',
        filterType: 'genre'
      },
      {
        title: "Curated",
        value: '8',
        color: "#c19007",
        background: "#c19007",
        displayTitle: "",
        css: "gold-dot",
        isActive: false,
        click: 'Curated',
        filterType: 'genre'
      },
      {
        title: "Interests",
        value: '6',
        color: "#005da0",
        background: "#005da0",
        displayTitle: "",
        css: "blue-dot",
        isActive: false,
        click: 'Interests',
        filterType: 'genre'
      },
      {
        title: "Posted",
        value: "25",
        selectedColor: "#ffffff",
        background: "#6b1da5",
        displayTitle: "",
        css: "lightPurple-dot",
        isActive: false,
        click: 'Posted',
        filterType: 'genre'
      },
      {
        title: "All2",
        value: "95",
        color: "#444444",
        background: "linear-gradient(to right,#c19007,#005da0, #6b1da5)",
        displayTitle: "",
        css: "normal-button",
        isActive: true,
        click: 'All2',
        filterType: 'resource'
      },
      {
        title: "Videos",
        value: "25",
        color: "#2eabe0",
        background: "#2eabe0",
        displayTitle: "",
        css: "lightBlue-dot",
        isActive: false,
        click: 'Videos',
        filterType: 'resource'
      },
      {
        title: "Documents",
        value: "25",
        color: "#855cfa",
        background: "#855cfa",
        displayTitle: "",
        css: "purple-dot",
        isActive: false,
        click: 'Documents',
        filterType: 'resource'
      },
      {
        title: "Writings",
        value: "25",
        color: "#ff3479",
        background: "#ff3479",
        displayTitle: "",
        css: "red-dot",
        isActive: false,
        click: 'Writings',
        filterType: 'resource'
      },
      {
        title: "Templates",
        value: "25",
        color: "#22d100",
        background: "#22d100",
        displayTitle: "",
        css: "green-dot",
        isActive: false,
        click: 'Templates',
        filterType: 'resource'
      }
    ]
  }

  supportButton: any = [
    {
      buttonText: "TEACHERS"
    },
    {
      buttonText: "COACHES"
    },
    {
      buttonText: "ADMINISTRATORS"
    },
  ]

  private _url: string = `${this.baseUrl}/FileCabinet/user/directory/active`
  private _urlPut: string = `${this.baseUrl}/FileCabinet/update/changepath`
  private _urlDelete: string = `${this.baseUrl}/FileCabinet/delete`

  // private _url2: string = this.httpClient.post(`${this.baseUrl}/FileCabinet/user/file/new`, incomingData, { responseType: 'text' });


  constructor(private httpClient: HttpClient) { }

  protected get baseUrl(): string {
    return `${environment.api.remoteServiceBaseUrl}`;
  }



  // testHttp(): any {
  //   this.httpClient.get(`${this.baseUrl}/FileCabinet/user/directory/active`).subscribe((data) => {
  //     this.plans = data;
  //     console.log('data for http planService', this.plans);
  //     // return this.plans

  //   });

  //   console.log('test http', this.plans)
  // }

  addPlan(subject: any) {
    // console.log('add plan')
    // var new2: any = [];
    // this.getAllPlans().subscribe((data: any) =>
    // {

    //   console.log("data for addPlan",data)
    //   // new2.push(data);
    //   console.log("new 2 2 2",new2)
    // })

    // console.log('new two', new2)

    // this.plans.push(new2)
    // console.log('sec call  new2',this.plans)

    // var obj = {
    //   virtualPath: subject,
    //   isCollapsed: true,
    //   backgroundColor: '#30ccae',
    //   documents: []
    // }
    // this.plans.push(obj)


    var obj = {
      fileName: "",
      fileUploadTypeId: 0,
      fileUrl: "",
      isActive: true,
      isCurated: false,
      isPublic: false,
      mimeType: "",
      previewImageUrl: "",
      rowId: "",
      storageFileName: "",
      tags: [{
        name: ""
      }
      ],
      virtualFolderPath: [
        { level: 0, name: "" },
      ],
      virtualPath: ""
    }
    console.log('sec call  new2', obj)
  }

  // addPlan(subject: any) {
  // var obj = {
  //   virtualPath: subject,
  //   isCollapsed: true,
  //   backgroundColor: '#30ccae',
  //   documents: []
  // }
  // this.plans.push(obj)
  // }

  getEmptyPlan(): any {
    var emptyData = {
      // id: 0,
      // tabTitle: '',
      // isCollapsed: true,
      // backgroundColor: '',
      // documents: []
      virtualPath: ' ',
      IsCurated: false,
      fileName: " ",
      fileUploadTypeId: 0,
      fileUrl: " ",
      isActive: true,
      isCurated: true,
      isPublic: false,
      mimeType: " ",
      previewImageUrl: " ",
      rowId: " ",
      storageFileName: " ",
      tags: [''],
      virtualFolderPath: [{ level: 0, name: '' }],
    }
    return emptyData;
  }


  getAllPlans(): any {

    return this.httpClient.get<any[]>(this._url);

    // var testeroo = this.httpClient.get<any[]>(this._url);
    // return testeroo
  }

  getButtons() {
    console.log('this.filterButtonsData', this.sortButtonData);
    return this.sortButtonData;
  }

  filterAllPlans(): any {
    var filterBy = this.httpClient.get<any[]>(this._url);
    console.log("filterBy in plan service", this._url)
    _.chain(filterBy)
      .groupBy("virtualPath")
      .map((value, key) => ({ virtualPath: key, virtualFolderPath: value }))
      .value()
    console.log(' k plans', filterBy)
  }

  getPlanById(id: number) {
  }

  getPlanByTabTitle(title: any): any {
    const foundPlan = this.plans.find((plan: any) => {
      console.log("what is this", this.plans)
      return plan.virtualPath === title
    });
    var planReturn = foundPlan ? foundPlan : 'not Found';
    return foundPlan ? foundPlan : 'not Found';
  }

  addDocumentsToPlan(title: string, files: any): any {
    var thePlan = this.getPlanByTabTitle(title);
    for (let i = 0; i < files.length; i++) {
      this.addDocumentToPlan(title, files[i]);
    }
    return thePlan;
  }

  addDocumentToPlan(title: string, file: any) {
    var thePlan = this.getPlanByTabTitle(title);
    var theObj: any = { title: file.name, image: '' };
    thePlan.documents.push(theObj);
  }

  getDocumentsByPlan(planName: any): any {
    const foundPlan = this.plans.find((plan: any) => {
      console.log("plan plan plan", plan)
      // var test = plan.tabTitle === planName;
      return plan.documents === planName
    });
  }

  testOut(plan: any) {
    var vFP = plan.virtualFolderPath[0].rowId
    var testRow = {
      rowId: vFP,
      virtualFolderPath: [
        {
          level: 0,
          name: plan.virtualFolderPath[0].name
          // name: 'pe'
        }
      ]

    }

    this.httpClient.put(`${this._urlPut}`, testRow).subscribe({
      next: data => {
        this.postId = data;
        console.log("data for put", data)
      },
      error: error => {
        this.errorMessage = error.message;
        console.error('There was an error!', error);
      }
    });
  }

  deleteFile(documentToDelete: any) {
    this.httpClient.request('delete', `${this._urlDelete}`, { body: documentToDelete }).subscribe(
      {
        next: data => {
          console.log("You just deleted.....self-destruct in 5, 4, 3, 2, 1...", data)
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('There was an error!', error);
        }
      }
    )
  }

  getSupportData() {
    return this.supportButton
  }

  getFiles(){
    const url = `${environment.api.remoteServiceBaseUrl2}/FileCabinet/files`;
    return this.httpClient.get(url);
  }
}