<div class="container w-100 tabs2">
    <div class="links d-flex justify-content-between" style="background-color: #fff;padding:10px;border-radius: 10px;margin-bottom: 10px;">
        <button class="btn {{ coachtab }}" (click)="open('coach')">Coach</button>
        <button class="btn {{ teachertab }}" (click)="open('teacher')">Teacher</button>
    </div>
    <div class="card w-100" style="padding:20px" *ngIf="isEdit">
        <form [formGroup]="personalInfoForm">
            <table id="editrecord" align="center" style="width:450px;margin:auto;" [style.opacity]="opacity">
                <tr>
                    <th width="200">First Name</th>
                    <td width="200">
                        <input type="text" formControlName="firstName" />
                    </td>
                </tr>
                <tr>
                    <th>Last Name</th>
                    <td>
                        <input type="text" formControlName="lastName" />
                    </td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>
                        <input type="text" formControlName="email" />
                    </td>
                </tr>
                <tr>
                    <th>Preferred Name</th>
                    <td>
                        <input type="text" formControlName="preferredName" />
                    </td>
                </tr>
                <tr>
                    <th>Years Of Experience</th>
                    <td>
                        <input type="text" formControlName="yearsExperience" />
                    </td>
                </tr>
                <tr>
                    <th>Years In Education</th>
                    <td>
                        <input type="text" formControlName="yearsInEducation" />
                    </td>
                </tr>
                <tr>
                    <th>Primary Phone</th>
                    <td>
                        <input type="text" formControlName="primaryPhone" />
                    </td>
                </tr>
                <tr *ngIf="(is_teacher)">
                    <th>Available Coaching Minutes</th>
                    <td>
                        <input type="text" formControlName="availableMinutes" />
                    </td>
                </tr>
                <tr>
                    <th>True Color</th>
                    <td>
                        <select class="form-select" multiple aria-label="Interest multi selection" formControlName="trueColors">
                            <option value="Blue">Blue</option>
                            <option value="Gold">Gold</option>
                            <option value="Green">Green</option>
                            <option value="Orange">Orange</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th>Supports</th>
                    <td>
                        <select class="form-select" multiple formControlName="supports" style="margin-top:10px;">
                            <option *ngFor="let objsupports of supports" [ngValue]="objsupports.supportId">
                                {{objsupports.name}}
                            </option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th>Is Active</th>
                    <td>
                        <select formControlName="isActive" style="width:199px;">
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th></th>
                    <td>
                        <input type="hidden" formControlName="id" />
                        <input type="button" value="Update" (click)="updateRecord()" class="btn" style="background-color: #34A8DF;color:#fff;margin-bottom:10px;" />
                        <input type="button" value="Cancel" (click)="closeForm()" class="btn" style="background-color: #6B89D8;color:#fff;margin-bottom: 10px;margin-left: 10px" />
                    </td>
                </tr>
            </table>
        </form>
    </div>
    <div class="card w-100 mt-3 coachsection" style="padding:20px;" *ngIf="coachsection">
        <div class="d-flex justify-content-end">
            <form [formGroup]="coachsearchForm">
                <input type="text" formControlName="coachsearch" style="height:43px;border:1px solid #ccc;border-radius:0px;padding:0px 10px;" />
                <input type="button" value="Search" (click)="coachsearch()" style="background-color: #34A8DF;color:#fff;margin-bottom:10px;height:43px;border-width:0px;margin-left: 3px;padding:0px 10px;" />
            </form>
        </div>
        <div style="text-align: right;padding-bottom: 10px;">
            <button class="btn btn-success" (click)="exportjsontoexcel('CoachReport',Allcoach)" style="margin-left:10px;background-color: #198754;width:auto;"><i class="fa fa-download"></i>&nbsp; Export To Excel</button>
        </div>
        <table class="table table-bordered table-striped" id="coach-table">
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Preferred Name</th>
                    <th>Years Of Experience</th>
                    <th>Years In Education</th>
                    <th>Is Active?</th>
                    <th>Role</th>
                    <th>True Color</th>
                    <th style="display:none;">Supports</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of coach; let i = index">
                    <td>{{ item.firstName }}</td>
                    <td>{{ item.lastName }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.preferredName }}</td>
                    <td>{{ item.yearsOfExperience }}</td>
                    <td>{{ item.yearsInEducation }}</td>
                    <td>{{ item.isActive }}</td>
                    <td>{{ item.role[0].name }}</td>
                    <td>{{ (item.trueColors)?item.trueColors.join(","):"" }}</td>
                    <td style="display:none;">{{ (item.supports)?item.supports.join(","):"" }}</td>
                    <td>
                        <button class="btn-edit" (click)="onEdit(item.id,item.firstName,item.lastName,item.email,item.preferredName,item.yearsOfExperience,item.yearsInEducation,item.primaryPhone,item.availableMinutes,item.role[0].id,item.isActive,item.trueColors,item.supports)"><i class="fa fa-edit"></i></button>
                        <button class="btn-edit" data-bs-toggle="modal" data-bs-target="#fullprofile" (click)="showprofile(i,1)"><i class="fa fa-eye"></i></button>
                    </td>
                </tr>
                <tr *ngIf="(coach.length==0)">
                    <td colspan="10" align="center">
                        Record Not Found
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="links">
            <ul>
                <li *ngFor="let no of coachLinks" (click)="loadCoach(no)" class="{{ (currentCoach==no)?'active':'' }}">
                    {{no}}
                </li>
            </ul>
        </div>

        <button class="btn active" style="margin:auto;display:none;" (click)="loadCoach(pgcoach)" *ngIf="!nonewcoach">Load More</button>
    </div>

    <div class="card w-100 mt-3 teachersection" style="padding:20px;" *ngIf="teachersection">
        <div class="d-flex justify-content-end">
            <form [formGroup]="teachersearchForm">
                <input type="text" formControlName="teachersearch" style="height:43px;border:1px solid #ccc;border-radius:0px;padding:0px 10px;" />
                <input type="button" value="Search" (click)="teachersearch()" style="background-color: #34A8DF;color:#fff;margin-bottom:10px;height:43px;border-width:0px;margin-left: 3px;padding:0px 10px;" />
            </form>
        </div>
        <div style="text-align: right;padding-bottom: 10px;">
            <button class="btn btn-success" (click)="exportjsontoexcel('TeacherReport',Allteacher)" style="margin-left:10px;background-color: #198754;width:auto;"><i class="fa fa-download"></i>&nbsp; Export To Excel</button>
        </div>
        <table class="table table-bordered table-striped" id="teacher-table">
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Preferred Name</th>
                    <th>Years Of Experience</th>
                    <th>Years In Education</th>
                    <th>Is Active?</th>
                    <th>Role</th>
                    <th>True Color</th>
                    <th style="display:none;">Supports</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of teacher; let i = index">
                    <td>{{ item.firstName }}</td>
                    <td>{{ item.lastName }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.preferredName }}</td>
                    <td>{{ item.yearsOfExperience }}</td>
                    <td>{{ item.yearsInEducation }}</td>
                    <td>{{ item.isActive }}</td>
                    <td>{{ item.role[0].name }}</td>
                    <td>{{ (item.trueColors)?item.trueColors.join(","):"" }}</td>
                    <td style="display:none;">{{ (item.supports)?item.supports.join(","):"" }}</td>
                    <td>
                        <button class="btn-edit" (click)="onEdit(item.id,item.firstName,item.lastName,item.email,item.preferredName,item.yearsOfExperience,item.yearsInEducation,item.primaryPhone,item.availableMinutes,item.role[0].id,item.isActive,item.trueColors,item.supports)"><i class="fa fa-edit"></i></button>
                        <button class="btn-edit" data-bs-toggle="modal" data-bs-target="#fullprofile" (click)="showprofile(i,2)"><i class="fa fa-eye"></i></button>
                    </td>
                </tr>
                <tr *ngIf="(teacher.length==0)">
                    <td colspan="10" align="center">
                        Record Not Found
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="links">
            <ul>
                <li *ngFor="let no of teacherLinks" (click)="loadTeacher(no)" class="{{ (currentTeacher==no)?'active':'' }}">
                    {{no}}
                </li>
            </ul>
        </div>
        <button class="btn active" style="margin:auto;display: none;" (click)="loadTeacher(pgteacher)" *ngIf="!nonewteacher">Load More</button>
    </div>

    <div class="card w-100 mt-3 activeusersection" style="padding:20px;">
        <h2>Active Users</h2>
        <div style="text-align: right;padding-bottom: 10px;">
            <button class="btn btn-success" (click)="exportjsontoexcel('ActiveUserReport',aallActiveuser)" style="margin-left:10px;background-color: #198754;width:auto;"><i class="fa fa-download"></i>&nbsp; Export To Excel</button>
        </div>
        <table class="table table-bordered table-striped" id="activeuser-table">
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Preferred Name</th>
                    <th>Last Login</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of allActiveuser; let i = index">
                    <td>{{ item.firstName }}</td>
                    <td>{{ item.lastName }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.preferredName }}</td>
                    <td>{{ converttimezone(item.lastLoggedInDatetime) +" "+getTimeZonelabel() }}</td>
                </tr>
                <tr *ngIf="(allActiveuser.length==0)">
                    <td colspan="10" align="center">
                        Record Not Found
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="links">
            <ul>
                <li *ngFor="let no of activeUserLinks" (click)="loadActiveUser(no)" class="{{ (currentUser==no)?'active':'' }}">
                    {{no}}
                </li>
            </ul>
        </div>
    </div>
    <div class="modal fade" id="fullprofile" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="loader" *ngIf="!hidemodal">Loading...</div>
        <div class="modal-dialog" role="document" *ngIf="hidemodal">
          <div class="modal-content">
            <div class="modal-header" style="display:block;text-align: center;">
              <h5 class="modal-title" id="coach_name" style="color: rgb(71, 70, 70);font-weight:bold;font-size:28px;">{{modalCoachName}}</h5>
            </div>
            <div class="modal-body">
              <div class="img-div"><img src="{{modalCoachImage}}" alt="Coach Picture" class="coach-img"></div>
              <div class="profileinfo">
                <p *ngIf="modalyearsOfExperience!=null"><b>Years of Experience:</b> {{ modalyearsOfExperience }}</p>
                <p *ngIf="modalgrade!=null"><b>Grade:</b> {{ modalgrade }}</p>
                <p *ngIf="modalsubjectsTaught.length>0"><b>Subjects Taught:</b> {{ modalsubjectsTaught }}</p>
                <p *ngIf="modalgradeLevelsTaught.length>0"><b>Grade Level(s) Taught:</b> {{ modalgradeLevelsTaught }}</p>
                <p *ngIf="modaltypeOfSchool.length>0"><b>Type of School:</b> {{ modaltypeOfSchool }}</p>
                <p *ngIf="modalprogramsExperience.length>0"><b>Program Experience:</b> {{ modalprogramsExperience }}</p>
                <p *ngIf="modalavailability.length>0"><b>Availability:</b> {{ modalavailability }}</p>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-close1" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
</div>
