import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'list-drag',
  templateUrl: './list-drag.component.html',
  styleUrls: ['./list-drag.component.scss']
})
export class ListDragComponent implements OnInit {

  @Input() thePlan2: any;
  @Input() currentPlan: any;

  drop(event: CdkDragDrop<string[]>) {
    alert("You just dropped an item")
    moveItemInArray(this.currentPlan.files, event.previousIndex, event.currentIndex);
  }

  constructor() { }

  ngOnInit(): void { }

}
